import { useState, useEffect, useCallback } from 'react';
import { useAxios } from './useAxios';
import { generateUrl } from 'utils/generateUrl';
import { ErrorType, Pagination, PaginationType } from 'models/types';
import { EmployeeDocument } from 'models/EmployeeDocument';

type PaginatedTableParams = {
  limit: number;
  page: number;
  filter: { year: number };
  sort: { startDate?: number; createdAt?: number };
};

const usePaginatedData = <T>(
  initialParams: Partial<PaginatedTableParams>,
  endpoint: string,
  shouldFetchFirstYear?: boolean,
) => {
  const [data, setData] = useState<T[]>([]);
  const [firstYear, setFirstYear] = useState<number | undefined>(undefined);
  const [pagination, setPagination] = useState<Pagination>();
  const [params, setParams] = useState<Partial<PaginatedTableParams>>(initialParams);

  const { request: fetchData, loading: loadingData } = useAxios<PaginationType<T>, ErrorType>({
    url: generateUrl(endpoint),

    method: 'GET',
    initLoading: true,
    onResponse: (response) => {
      const {
        data: { items, ...rest },
      } = response;

      setData(items);

      setPagination(rest.totalItems > 0 ? (rest as Pagination) : undefined);
    },
  });

  const getData = (params: Partial<PaginatedTableParams>) => {
    fetchData({ queryParams: params });
  };

  const paramsFirstYear = {
    sort: { startDate: 1 },
    limit: 1,
  };

  const { request: fetchFirstYear } = useAxios<PaginationType<T>, ErrorType>({
    url: generateUrl(endpoint, paramsFirstYear),
    params,
    method: 'GET',
    initLoading: true,
    onResponse: (response) => {
      const {
        data: { items, ...rest },
      } = response;

      if (response.data.items.length === 0) {
        // the employee doesn't have any documents yet
        setFirstYear(new Date().getFullYear());
      } else if ('type' in (response.data.items as EmployeeDocument[])[0]) {
        const firstDate = new Date((response.data.items as EmployeeDocument[])[0].startDate);
        setFirstYear(firstDate.getFullYear());
      }
    },
  });

  useEffect(() => {
    if (shouldFetchFirstYear) fetchFirstYear({});
  }, [shouldFetchFirstYear]);

  return {
    data,
    loading: loadingData,
    pagination,
    params,
    setParams,
    firstYear,
    getData,
  };
};

export default usePaginatedData;
