import classNames from 'classnames';
import React, { FC } from 'react';
import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from 'react-router-dom';

import { LinkVariant } from 'models/types';

import './Link.styles.scss';

type LinkProps = {
  variant: LinkVariant;
  to: string;
  text: string;
  className?: string;
} & ReactRouterLinkProps;

const Link: FC<LinkProps> = ({ to, text, variant = LinkVariant.Text, className, ...rest }) => {
  const classes = classNames(`link--${variant}`, className);

  return (
    <ReactRouterLink to={to} className={classes} {...rest}>
      {text}
    </ReactRouterLink>
  );
};

export default Link;
