import { DeleteIcon, ResendIcon, ThreeDotsIcon } from 'assets';
import { Button, Dropdown, Loader } from 'components';
import { snackbar } from 'modules';
import useAdminsStore from 'pages/RoleManagementPage/store/useAdminsStore';
import { FC, useCallback, useState } from 'react';
import { User } from 'models/User';
import { UserRole } from 'enums';
import { useAuth } from 'context';
import { useModalState } from 'hooks';
import { RemoveInvitationModal } from 'pages/RoleManagementPage/components/RemoveInvitationModal';
import './PendingAdminActions.styles.scss';

type PendingAdminActionsProps = {
  admin: User;
};

const PendingAdminActions: FC<PendingAdminActionsProps> = ({ admin }) => {
  const { user } = useAuth();
  const { id } = admin;

  const [isPendingDropdownOpen, setIsPendingDropdownOpen] = useState<boolean>(false);
  const [adminInvitationLoading, setAdminInvitationLoading] = useState<boolean>(false);
  const [deleteAdminInvitationLoading, setDeleteAdminInvitationLoading] = useState<boolean>(false);

  const { inviteAdmin, deleteAdminInvitation } = useAdminsStore();

  const {
    isOpen: isRemoveInvitationModalOpen,
    openModal: openRemoveInvitationModal,
    closeModal: closeRemoveInvitationModal,
  } = useModalState();

  const handleAdminInvitationUpdateSuccess = useCallback(
    (message: string) => {
      snackbar.show({ message: message, type: 'success' });
      setIsPendingDropdownOpen(false);
    },
    [setIsPendingDropdownOpen],
  );

  const handleAdminInvitationSentError = useCallback((errorMessage: string) => {
    snackbar.show({ message: errorMessage, type: 'error' });
  }, []);

  const handleSendInvitationButtonClicked = useCallback(async () => {
    setAdminInvitationLoading(true);
    await inviteAdmin(
      id,
      () => handleAdminInvitationUpdateSuccess('Email sent'),
      handleAdminInvitationSentError,
    );
    setAdminInvitationLoading(false);
  }, [
    setAdminInvitationLoading,
    inviteAdmin,
    handleAdminInvitationSentError,
    handleAdminInvitationSentError,
  ]);
  const handleDeleteInvitationButtonClicked = useCallback(async () => {
    setDeleteAdminInvitationLoading(true);
    await deleteAdminInvitation(
      id,
      () => handleAdminInvitationUpdateSuccess('The invitation is successfully deleted.'),
      handleAdminInvitationSentError,
    );
    setDeleteAdminInvitationLoading(false);
    closeRemoveInvitationModal();
  }, [setDeleteAdminInvitationLoading, deleteAdminInvitation, handleAdminInvitationSentError]);

  return (
    <div className='pending-admin-actions'>
      Pending
      {user?.role === UserRole.SUPER_ADMIN && (
        <Dropdown
          renderInParent
          isOpen={isPendingDropdownOpen}
          trigger={
            <Button type='button' variant='icon' className='pending-admin-actions__pending-trigger'>
              <ThreeDotsIcon />
            </Button>
          }
          toggleOpenDropdown={() => setIsPendingDropdownOpen((prev) => !prev)}
          className='pending-admin-actions__actions-dropdown'
        >
          <ul>
            <li>
              {adminInvitationLoading ? (
                <Loader />
              ) : (
                <Button
                  type='button'
                  variant='text'
                  onClick={handleSendInvitationButtonClicked}
                  icon={<ResendIcon />}
                >
                  Resend invite
                </Button>
              )}
            </li>
            <li>
              <Button
                type='button'
                variant='text'
                onClick={openRemoveInvitationModal}
                icon={<DeleteIcon />}
              >
                Delete invite
              </Button>
            </li>
          </ul>
          <RemoveInvitationModal
            isOpen={isRemoveInvitationModalOpen}
            onRequestClose={closeRemoveInvitationModal}
            onRemove={handleDeleteInvitationButtonClicked}
            isRemoveInvitationLoading={deleteAdminInvitationLoading}
          />
        </Dropdown>
      )}
    </div>
  );
};

export default PendingAdminActions;
