import { Avatar } from 'components';
import { TableColumnItem } from 'components/Table/Table.component';
import { UserRole } from 'enums';
import { User } from 'models/User';
import { AdminActions } from '../../AdminActions';

export default function useAdminsTableColumns(currentAdminId: string) {
  return [
    {
      columnLabel: 'Name',
      prepareCell: (data) => (
        <div className='employees__table-container__name'>
          <div className='employees__table-container__avatar'>
            <Avatar
              imageUrl={data.profileImageUrl || 'images/userDefault.png'}
              alt={'Profile Image'}
              size='md'
            />
          </div>
          <p>{`${data.firstName} ${data.lastName}${data.id === currentAdminId ? ' (you)' : ''}`}</p>
        </div>
      ),
    },
    {
      columnLabel: 'Email',
      prepareCell: (data) => data?.email,
    },
    {
      columnLabel: 'Role',
      prepareCell: (data) => (data.role === UserRole.SUPER_ADMIN ? 'Super admin' : 'Admin'),
    },
    {
      columnLabel: 'Actions',
      columnName: 'admin-actions',
      prepareCell: (data) => <AdminActions admin={data} />,
    },
  ] as TableColumnItem<User>[];
}
