import React from 'react';

import './PageLayout.styles.scss';
import classNames from 'classnames';

type PageLayoutProps = {
  children: React.ReactNode;
  className?: string;
};

const PageLayout: React.FC<PageLayoutProps> = ({ children, className }) => {
  const classes = classNames('ne-page', className);

  return <main className={classes}>{children}</main>;
};

export default PageLayout;
