import { yupResolver } from '@hookform/resolvers/yup';
import { AuthContainer, Button, FieldWrapper, Input, Link } from 'components';
import { InputProps } from 'components/Input/Input.component';
import { APP_URI } from 'config';
import { useAuth } from 'context';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { LinkVariant } from 'models/types';
import * as Yup from 'yup';

import {
  LETTERS_REGEX,
  LOWERCASE_REGEX,
  MSG_EMAIL_INVALID,
  MSG_LETTERS_ONLY,
  MSG_REQUIRED,
  NUMBER_REGEX,
  UPPERCASE_REGEX,
} from 'validation';

import './Register.styles.scss';

interface RegistrationUser {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

const validation = Yup.object().shape({
  firstName: Yup.string().matches(LETTERS_REGEX, MSG_LETTERS_ONLY).max(40).required(MSG_REQUIRED),
  lastName: Yup.string().matches(LETTERS_REGEX, MSG_LETTERS_ONLY).max(40).required(MSG_REQUIRED),
  email: Yup.string().email(MSG_EMAIL_INVALID).required(MSG_REQUIRED),
  password: Yup.string()
    .required(MSG_REQUIRED)
    .min(8, 'Password must be at least 8 characters')
    .matches(LOWERCASE_REGEX, 'Password must contain at least one lowercase letter')
    .matches(UPPERCASE_REGEX, 'Password must contain at least one uppercase letter')
    .matches(NUMBER_REGEX, 'Password must contain at least one number'),
});

const Register: FC = () => {
  const navigate = useNavigate();
  const methods = useForm<RegistrationUser>({
    resolver: yupResolver(validation),
    mode: 'onTouched',
  });

  const { setToken } = useAuth();

  const handleSubmit = () => {
    setTimeout(() => {
      setToken('Test token');
      navigate(APP_URI.base, { replace: true });
    }, 2000);
  };

  return (
    <AuthContainer title='Registration'>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <FieldWrapper<InputProps>
            fieldComponent={Input}
            label='First name'
            name='firstName'
            className='input-container--fade-in--faster'
          />
          <FieldWrapper<InputProps>
            fieldComponent={Input}
            name='lastName'
            label='Last name'
            className='input-container--fade-in--faster'
          />
          <FieldWrapper<InputProps>
            fieldComponent={Input}
            name='email'
            label='Email'
            type='email'
            className='input-container--fade-in--faster'
          />
          <FieldWrapper<InputProps>
            fieldComponent={Input}
            name='password'
            label='Password'
            type='password'
            className='input-container--fade-in--faster'
          />

          <div className='register-container__buttons-container'>
            <Link
              to={APP_URI.login}
              text='Log in'
              variant={LinkVariant.Button}
              className='link--button--register--default'
            />
            <Button type='submit' className='button--register button--register--submit'>
              <span>Register</span>
            </Button>
          </div>
        </form>
      </FormProvider>
    </AuthContainer>
  );
};

export default Register;
