import { Button, DocumentDropzone } from 'components';
import { DocumentDropzoneProps } from 'components/DocumentDropzone/DocumentDropzone.component';
import { UploadFile } from 'components/Dropzone';
import { FieldWrapper } from 'components/FieldWrapper';
import { VacationRequestData } from 'models/VacationRequest';
import React, { FC, useState } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import DateService from 'services/Date.service';
import { CheckIcon, DeniedIcon, DoubleCheckIcon, DownloadIcon } from 'assets';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import './VacationRequestForm.styles.scss';

type VacationRequestValue = {
  file: UploadFile[];
};

type VacationRequestFormProps = {
  request: VacationRequestData;
  methods: UseFormReturn<VacationRequestValue, any, undefined>;
  onSubmit: (formValue: VacationRequestValue) => void;
  approveVacationRequest?: () => void;
  rejectVacationRequest?: () => void;
  isLoading: boolean;
  status: string;
  title: string;
};
const VacationRequestForm: FC<VacationRequestFormProps> = (props) => {
  const [isApprovedOrRejected, setIsApprovedOrRejected] = useState(false);

  const getModalIcon = () => {
    switch (status) {
      case 'Fulfilled':
        return (
          <div className='fulfilled-icon'>
            <DoubleCheckIcon />
          </div>
        );
      case 'Approved':
        return (
          <div className='approved-icon'>
            <CheckIcon />
          </div>
        );
      case 'Denied':
        return (
          <div className='denied-icon'>
            <DeniedIcon />
          </div>
        );
    }
  };
  const {
    methods,
    request,
    title,
    onSubmit,
    approveVacationRequest,
    rejectVacationRequest,
    isLoading,
    status,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const handleApprove = () => {
    if (approveVacationRequest) {
      approveVacationRequest();
      const pathName = location.pathname.split('/');
      navigate(
        `/${pathName[1]}${pathName[1] === 'employees' ? `/${pathName[2]}/${pathName[3]}` : ''}${location.search}`,
      );
      setIsApprovedOrRejected(true);
    }
  };

  const handleReject = () => {
    if (rejectVacationRequest) {
      rejectVacationRequest();
      const pathName = location.pathname.split('/');
      navigate(
        `/${pathName[1]}${pathName[1] === 'employees' ? `/${pathName[2]}/${pathName[3]}` : ''}${location.search}`,
      );
      setIsApprovedOrRejected(true);
    }
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className='vacation-request'>
            <h1>
              {getModalIcon()} {title}
            </h1>
            <div className='vacation-request__content'>
              <div className='vacation-request__subsection'>
                <p>Employee</p>
                <p>
                  <b>
                    {request.user.firstName} {request.user.lastName}
                  </b>
                </p>
              </div>

              <div className='vacation-request__dates'>
                <div className='vacation-request__subsection'>
                  <p>From</p>
                  <p>
                    <b>{DateService.formatDate(request.startDate)}</b>
                  </p>
                </div>
                <span>-</span>
                <div className='vacation-request__subsection'>
                  <p>To</p>
                  <p>
                    <b>{DateService.formatDate(request.endDate)}</b>
                  </p>
                </div>
              </div>

              <div className='vacation-request__subsection'>
                <p>Request</p>
                <p className='description'>{request.description}</p>
              </div>

              {status && status === 'Fulfilled' && (
                <div className='vacation-request__subsection'>
                  <p>Attached File</p>
                  <Link to={request.fileUrl}>
                    Download
                    <DownloadIcon />
                  </Link>
                </div>
              )}
            </div>

            {status && status === 'Approved' && (
              <div className='vacation-request__upload'>
                <FieldWrapper<DocumentDropzoneProps>
                  name='file'
                  fieldComponent={DocumentDropzone}
                />
                {methods.formState.errors.root && (
                  <small className='vacation-request__error-msg'>
                    {methods.formState.errors.root?.message}
                  </small>
                )}

                <div>
                  <Button
                    disabled={!methods.watch('file') || methods.watch('file').length === 0}
                    loading={isLoading}
                    type='submit'
                  >
                    Save
                  </Button>
                </div>
              </div>
            )}
          </div>
        </form>
      </FormProvider>
      {status && status === 'Submitted' && (
        <div className='vacation-request__buttons'>
          <Button
            onClick={handleReject}
            type='button'
            disabled={isApprovedOrRejected}
            className={
              isApprovedOrRejected
                ? 'vacation-request__buttons--disabled'
                : 'vacation-request__buttons--reject'
            }
          >
            Reject
          </Button>
          <Button
            onClick={handleApprove}
            type='button'
            disabled={isApprovedOrRejected}
            className={
              isApprovedOrRejected
                ? 'vacation-request__buttons--disabled'
                : 'vacation-request__buttons--approve'
            }
          >
            Approve
          </Button>
        </div>
      )}
    </div>
  );
};

export default VacationRequestForm;
