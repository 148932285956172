import { Avatar, Button, FieldWrapper, Modal } from 'components';
import React, { FC } from 'react';
import { useModalState } from 'hooks';
import * as Yup from 'yup';
import Input, { InputProps } from 'components/Input/Input.component';
import { MSG_EMAIL_INVALID, MSG_REQUIRED } from 'validation';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import './RemoveEmployeeModal.styles.scss';

type RemoveEmployeeModalProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  firstName: string;
  lastName: string;
  email: string;
  profileImageUrl: string;
  loadingRemoveRequest: boolean;
  removeEmployee: () => void;
};

type RemoveEmployeeValue = {
  email: string;
};

const validation = (email: string) =>
  Yup.object().shape({
    email: Yup.string()
      .email(MSG_EMAIL_INVALID)
      .required(MSG_REQUIRED)
      .test('isEmailValid', 'Email must be email of user you want to remove.', function (value) {
        return value === email;
      }),
  });

const RemoveEmployeeModal: FC<RemoveEmployeeModalProps> = ({
  isModalOpen,
  closeModal,
  firstName,
  lastName,
  profileImageUrl,
  loadingRemoveRequest,
  removeEmployee,
  email,
}) => {
  const {
    isOpen: isConfirmModalOpen,
    openModal: handleOpenConfirmModal,
    closeModal: handleCloseConfirmModal,
  } = useModalState();

  const methods = useForm<RemoveEmployeeValue>({
    mode: 'onChange',
    resolver: yupResolver(validation(email)),
  });

  return (
    <>
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} hasCloseIcon>
        <div className='ne-personal-information__remove-modal'>
          <h2>Remove user</h2>
          <p>
            Are you sure you want to remove the user? By removing the account the user won’t be able
            to access the personal account and you won’t be able to see it on the dashboard. All
            personal data and documents will be permanently removed.
          </p>
          <div className='ne-personal-information__remove-modal__employee-info'>
            <Avatar imageUrl={profileImageUrl} alt='employee-photo' /> {firstName} {lastName}
          </div>
          <div className='ne-personal-information__remove-modal__actions-buttons'>
            <Button onClick={closeModal} variant='outline' type='button'>
              Cancel
            </Button>
            <Button onClick={() => handleOpenConfirmModal()} type='button'>
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
      <Modal isOpen={isConfirmModalOpen} onRequestClose={handleCloseConfirmModal} hasCloseIcon>
        <div className='ne-personal-information__confirm-modal'>
          <h2>Are you sure you want to continue with this action?</h2>
          <p>
            Removing user is permanent and you won&apos;t be able to access any of his data anymore.
            In order to confirm this action, please enter employee&apos;s email:
          </p>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit((_values) => removeEmployee())}
              className='ne-personal-information__form'
            >
              <FieldWrapper<InputProps> fieldComponent={Input} name='email' label='Email' />
              <div className='ne-personal-information__confirm-modal__actions-buttons'>
                <Button onClick={handleCloseConfirmModal} variant='outline' type='button'>
                  Cancel
                </Button>
                <Button
                  loading={loadingRemoveRequest}
                  type='submit'
                  disabled={!methods.formState.isValid}
                >
                  Confirm
                </Button>
              </div>
            </form>
          </FormProvider>
        </div>
      </Modal>
    </>
  );
};

export default RemoveEmployeeModal;
