import api from 'api';
import { CurrencySettings } from 'models/Currency';
import parseAxiosError from 'utils/parseAxiosError';
import { create } from 'zustand';

interface CurrenciesState {
  isLoading: boolean;
  isUpdateInProgress: boolean;
  error?: string;
  currencies?: CurrencySettings;
  getCurrencies: () => void;
  updateCurrencies: (
    currencies: CurrencySettings,
    onSuccess?: (currencies: CurrencySettings) => void,
    onError?: (errorMessage: string) => void,
  ) => void;
}

const useCurrenciesStore = create<CurrenciesState>()((set, get) => ({
  isLoading: false,
  isUpdateInProgress: false,
  getCurrencies: async () => {
    try {
      set({ isLoading: true });
      const { data } = await api.admin.currencies.getCurrencies();

      set({ isLoading: false, currencies: data });
    } catch (err) {
      set({ isLoading: false });
    }
  },
  updateCurrencies: async (
    currencies: CurrencySettings,
    onSuccess?: (currencies: CurrencySettings) => void,
    onError?: (errorMessage: string) => void,
  ) => {
    try {
      set({ isUpdateInProgress: true });
      const { data } = await api.admin.currencies.updateCurrencies(currencies);
      set({
        currencies: data,
      });

      set({ isUpdateInProgress: false });
      onSuccess?.(currencies);
    } catch (err) {
      const errrorMessage = parseAxiosError(err as any);
      set({ isUpdateInProgress: false });
      onError?.(errrorMessage);
    }
  },
}));

export default useCurrenciesStore;
