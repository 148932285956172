import classNames from 'classnames';
import React, { FC } from 'react';

import './DropzoneActiveContent.styles.scss';

type DropzoneActiveContentProps = {
  className?: string;
};

const DropzoneActiveContent: FC<DropzoneActiveContentProps> = (props) => {
  const { className } = props;
  const classes = classNames('dropzone-active-content', className);

  return (
    <div className={classes}>
      <label>
        <span>Drop here </span>
      </label>
    </div>
  );
};

export default DropzoneActiveContent;
