import Table from 'components/Table';
import { User } from 'models/User';
import React, { FC, useEffect, useState } from 'react';
import useEmployeesTableColumns from '../../hooks/useEmployeesTableColumns';
import { toggleSortingOrder } from 'utils/toggleSortingOrder';
import { SortingOrder } from 'models/types';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'components';

import './EmployeesTable.styles.scss';

type EmployeesProps = {
  employees: User[];
  onSortChange: (prop: number) => void;
  loading?: boolean;
  initialSort: SortingOrder;
};

const EmployeesTable: FC<EmployeesProps> = ({ employees, onSortChange, loading, initialSort }) => {
  const [sortByName, setSortByName] = useState(initialSort);
  const navigate = useNavigate();

  const handleSortByName = () => {
    setSortByName(toggleSortingOrder(sortByName));
  };

  const employeesTableColumns = useEmployeesTableColumns(sortByName, handleSortByName);

  useEffect(() => {
    onSortChange(sortByName);
  }, [sortByName]);

  return (
    <div className='employees__table-container'>
      <Table
        data={employees.map((employee) => ({
          ...employee,
          className: employee.isDeleted ? 'employees-table__deleted-user' : '',
        }))}
        prepareData={employeesTableColumns}
        noDataMessage="Here, you'll find a list of your employees."
        onTableRowClick={(user) =>
          navigate({
            pathname: `/employees/manage-employees/${user.id}`,
          })
        }
      />
      <div className='employees-table__loader'>{loading && <Loader />}</div>
    </div>
  );
};

export default EmployeesTable;
