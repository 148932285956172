export const employeeContractOptions = [
  { label: 'Employment contract', value: 'Employment contract' },
  {
    label: 'Fixed-term employment contract',
    value: 'Fixed-term employment contract',
  },
  { label: 'Annex', value: 'Annex' },
  { label: 'Internship contract (agency)', value: 'Internship contract' },
];

export const currencyOptions = [
  { label: 'RSD', value: 'RSD' },
  { label: 'EUR', value: 'EUR' },
  { label: 'USD', value: 'USD' },
];
