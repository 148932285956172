import { User } from 'models/User';
import { createContext, Dispatch, SetStateAction, useContext } from 'react';

export type Token = string | null;

export type IAuthProvider = {
  token: string | null;
  user: User | null;
  setToken: (token: string | null) => void;
  setUser: Dispatch<SetStateAction<User | null>>;
  triggerRefetch: () => Promise<void>;
};

const DEFAULT_VALUES: IAuthProvider = {
  token: '',
  user: null,
  setToken: () => {},
  setUser: () => {},
  triggerRefetch: async () => {},
};

const AuthContext = createContext(DEFAULT_VALUES);

const useAuth = () => useContext(AuthContext);

export { AuthContext, useAuth };
