import { Button } from 'components/Button';
import Modal, { ModalProps } from 'components/Modal/Modal.component';
import React, { FC } from 'react';
import './RemoveVacationModal.styles.scss';

type RemoveVacationModalProps = {
  isRemoveRequestLoading: boolean;
  onRemove: () => void;
} & Pick<ModalProps, 'isOpen' | 'onRequestClose'>;

const RemoveVacationModal: FC<RemoveVacationModalProps> = (props) => {
  const { isOpen, onRequestClose, isRemoveRequestLoading, onRemove } = props;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className='remove-vacation-modal'
      hasCloseIcon
    >
      <div className='remove-vacation-modal__container'>
        <h3>Are you sure you want to delete vacation?</h3>
        <div className='remove-vacation-modal__btn-container'>
          <Button
            onClick={onRequestClose}
            type='button'
            className='remove-vacation-modal__cancel-btn'
          >
            Cancel
          </Button>
          <Button
            className='remove-vacation-modal__delete-btn'
            onClick={onRemove}
            loading={isRemoveRequestLoading}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveVacationModal;
