// TabsHeader component

import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';

import './Tabs.styles.scss';

type Tab = {
  label: React.ReactNode;
  content: React.ReactNode;
  id: string;
};

type TabsProps = {
  tabs: Tab[];
  className?: string;
};

const Tabs = (props: TabsProps) => {
  const { tabs, className } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const classes = classNames('tabs', className);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleTabChanged = useCallback((tab: Tab, index: number) => {
    setActiveIndex(index);
    searchParams.set('tab', tab.id);
    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    const tabId = searchParams.get('tab');
    const selectedTabIndex = tabs.findIndex((tab) => tab.id === tabId);
    selectedTabIndex === -1 ? setActiveIndex(0) : setActiveIndex(selectedTabIndex);
  }, [searchParams]);

  return (
    <div className={classes}>
      <div className='tabs__header'>
        {tabs.map((tab, index) => (
          <div
            className={classNames('tabs__header__tab', {
              'tabs__header__tab--active': index === activeIndex,
            })}
            key={index}
            onClick={() => handleTabChanged(tab, index)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className='tabs__tab-content'>{tabs[activeIndex].content}</div>
    </div>
  );
};

export default Tabs;
