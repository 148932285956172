import classNames from 'classnames';
import { FC, useCallback, useState } from 'react';
import { DropzoneOptions, FileRejection } from 'react-dropzone';

import { CloseIcon } from 'assets';
import { Button, Dropzone, LazyImage, Modal } from 'components';
import { useFileUpload } from 'hooks';

import noImagePlaceholder from 'assets/No-Image-Placeholder.svg.png';
import { UploadFile } from 'components/Dropzone/Dropzone.component';

import './ImageUpload.styles.scss';

export type ImageUploadProps = {
  value: UploadFile[];
  onChange: (files: UploadFile[]) => void;
  message?: string;
  hasError?: boolean;
  helperText?: string;
  dropzoneOptions?: DropzoneOptions;
  className?: string;
  isProfilePicture?: boolean;
  isDisabled?: boolean;
  onError?: () => void;
  onAccept?: () => void;
  isDirty?: boolean;
  isEdit?: boolean;
};

const ImageUpload: FC<ImageUploadProps> = (props) => {
  const {
    value = [],
    onChange,
    onError,
    onAccept,
    helperText,
    dropzoneOptions,
    className,
    isProfilePicture,
    isDisabled,
    isDirty,
    isEdit,
  } = props;
  const [modalIsOpen, setIsOpen] = useState(false);
  const { handleAccept, handleReject, handleRemove, errorMessages } = useFileUpload({
    multiple: false,
    onChange,
    value,
    isProfilePicture,
    onError,
    onAccept,
  });

  const imageUploadClassnames = classNames('image-upload-container', className);
  const dropzoneClassnames = classNames(
    `image-upload-container__dropzone${value?.length ? '--image-selected' : '--image-selected'}`,
  );

  const handleOpenModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleImageRejection = useCallback(
    (rejectedFiles: FileRejection[]) => {
      handleReject(rejectedFiles);
    },
    [onError, handleReject],
  );

  const url = props?.value?.[0]?.blobURL || noImagePlaceholder;

  return (
    <>
      <div className={imageUploadClassnames}>
        {isEdit && (
          <Dropzone
            onAccept={handleAccept}
            dropzoneOptions={{ ...dropzoneOptions, onDropRejected: handleImageRejection }}
            className={dropzoneClassnames}
            value={value}
            isDisabled={isDisabled}
          />
        )}
        {value.length ? (
          value?.map(({ blobURL }: { blobURL: string }) => (
            <div key={blobURL} className='image-upload-container__image-wrapper'>
              <LazyImage src={blobURL || url} alt={''} />{' '}
              {isEdit && !isDirty && blobURL !== noImagePlaceholder && isProfilePicture && (
                <Button
                  onClick={() => handleOpenModal()}
                  className='image-upload-container__button image-upload-container__remove-button'
                  type='button'
                  aria-label='Remove profile image'
                  disabled={isDisabled}
                >
                  <CloseIcon />
                </Button>
              )}
              {modalIsOpen && isProfilePicture && (
                <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} hasCloseIcon>
                  <h2 className='image-upload-container__modal__title'>
                    Are you sure you want to remove profile image?
                  </h2>
                  <div className='image-upload-container__modal__buttons'>
                    <Button
                      onClick={() => {
                        handleCloseModal();
                      }}
                      className='image-upload-container__modal__buttons__button image-upload-container__modal__buttons__button--cancel'
                    >
                      <span>Cancel</span>
                    </Button>
                    <Button
                      onClick={() => {
                        handleRemove(blobURL);
                        handleCloseModal();
                      }}
                      className='image-upload-container__modal__buttons__button'
                    >
                      <span>Confirm</span>
                    </Button>
                  </div>
                </Modal>
              )}
            </div>
          ))
        ) : (
          <div key={noImagePlaceholder} className='image-upload-container__image-wrapper'>
            <LazyImage src={noImagePlaceholder} alt={'no-image'} />
            <div
              className={classNames(errorMessages.length > 0 && 'image-upload-container__errors')}
            >
              {errorMessages.length > 0 ? (
                errorMessages.map((errorMessage, index) => (
                  <small key={index}>
                    {errorMessage.code === 'file-too-large'
                      ? 'Image is larger than 5MB'
                      : errorMessage.message}
                  </small>
                ))
              ) : (
                <small>{helperText || '‎ '}</small>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ImageUpload;
