import { ArrowLeftIcon } from 'assets';
import classNames from 'classnames';
import { Button } from 'components/Button';
import { FC, useCallback } from 'react';

import './YearPicker.styles.scss';

type YearPickerProps = {
  year: number;
  onYearChange: (year: number) => void;
  minYear?: number;
  maxYear: number;
  className?: string;
};
const YearPicker: FC<YearPickerProps> = ({
  year,
  onYearChange,
  minYear = 0,
  maxYear,
  className,
}) => {
  const yearPickerClasses = classNames('year-picker', className);

  const increaseYear = useCallback(() => {
    onYearChange(Math.min(year + 1, maxYear));
  }, [maxYear, onYearChange, year]);

  const decreaseYear = useCallback(() => {
    onYearChange(Math.max(year - 1, minYear));
  }, [minYear, onYearChange, year]);

  const leftArrowDisabled = year <= minYear || minYear === 0;
  const rightArrowDisabled = year >= maxYear;

  return (
    <div className={yearPickerClasses}>
      <Button
        onClick={decreaseYear}
        disabled={leftArrowDisabled}
        className={leftArrowDisabled ? 'arrow-left--disabled' : 'arrow-left'}
        aria-label='Decrease year'
      >
        <ArrowLeftIcon />
      </Button>
      <b>{year}</b>
      <Button
        onClick={increaseYear}
        disabled={rightArrowDisabled}
        className={rightArrowDisabled ? 'arrow-right--disabled' : 'arrow-right'}
        aria-label='Increase year'
      >
        <ArrowLeftIcon />
      </Button>
    </div>
  );
};

export default YearPicker;
