import classNames from 'classnames';
import React, { FC, InputHTMLAttributes } from 'react';

import './Input.styles.scss';

type DefaultInputProps = InputHTMLAttributes<HTMLInputElement>;

export type InputProps = {
  errorMessage?: string;
  helperText?: string;
  label?: string;
  classNamesInput?: string;
  hasError?: boolean;
  prefixNode?: React.ReactNode;
  suffixNode?: React.ReactNode;
  asterix?: boolean;
  variant?: 'filled' | 'underlined';
} & DefaultInputProps;

const Input: FC<InputProps> = (props) => {
  const {
    label,
    errorMessage,
    className,
    classNamesInput,
    hasError,
    prefixNode: prefix,
    suffixNode: suffix,
    disabled,
    asterix,
    variant = 'underlined',
    helperText,
    ...rest
  } = props;
  return (
    <div
      className={classNames([
        'ne-input',
        {
          'ne-input--has-prefix': !!prefix,
          'ne-input--disabled': disabled,
          'ne-input--has-suffix': !!suffix,
        },
        className,
      ])}
    >
      {label && (
        <label htmlFor={props.id || props.name}>
          {label}
          {asterix && '*'}
        </label>
      )}
      <div className={classNames('ne-input__container', `ne-input__container--${variant}`)}>
        {prefix ? <div className='ne-input__prefix'>{prefix}</div> : undefined}
        <input
          className={classNames(classNamesInput)}
          {...rest}
          disabled={disabled}
          aria-label={label}
          id={props.id || props.name}
        />
        {suffix ? <div className='ne-input__suffix'>{suffix}</div> : undefined}
      </div>
      <small className={classNames('ncoded-small', hasError && 'ncoded-small--invalid-input')}>
        {errorMessage}
      </small>
      {helperText ? <small className={classNames('ncoded-small')}>{helperText}</small> : undefined}
    </div>
  );
};

export default Input;
