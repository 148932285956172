import React from 'react';
import { useEmployeeNumber } from './hooks/useEmployeeNumber';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/Button';
import { APP_URI } from 'config';
import { AddIcon, EmployeesIcon } from 'assets';
import { PageLayout } from 'components/layout/PageLayout';
import { PageHeader } from 'components/layout/PageHeader';
import { useModalState } from 'hooks';
import NewEmployeeModal from 'pages/EmployeesPage/components/NewEmployeeModal/NewEmployeeModal.component';
import TimeOffsTable from 'pages/TimeOffsPage/components/TimeOffsTable/TimeOffsTable.component';

import './DashboardPage.styles.scss';

const DashboardPage = () => {
  const numbersEmployee = useEmployeeNumber();
  const navigate = useNavigate();
  const { isOpen: isPopupOpen, closeModal: closePopup, openModal: openPopup } = useModalState();

  return (
    <PageLayout className='dashboard'>
      <PageHeader
        title='Dashboard'
        asideFromTitle={
          <Button
            icon={<AddIcon />}
            onClick={openPopup}
            className='dashboard__add-employee-button'
            hoverText='Create new employee'
          >
            Add employee
          </Button>
        }
      />
      <NewEmployeeModal onCloseModal={closePopup} isModalOpen={isPopupOpen} />
      <div
        className='dashboard__number-of-employees'
        onClick={() => navigate(`${APP_URI.employees}`)}
      >
        <div className='dashboard__elements'>
          <div className='dashboard__icon'>
            <EmployeesIcon />
          </div>
          <p className='dashboard__text'>Number of employees</p>
        </div>
        <div className='dashboard__employee-all'>
          <p className='dashboard__number-employee'>{numbersEmployee}</p>
          <p className='dashboard__view'>View all</p>
        </div>
      </div>
      <div className='dashboard__timeoffs'>
        <h1>Pending vacation requests</h1>
        <TimeOffsTable
          isSearchVisible={true}
          areTabsVisible={false}
          initialStatus='Submitted'
          urlParamNames={{
            pageParamName: 'page',
            searchParamName: 'search',
            sortByRequestParamName: 'sortByRequest',
            sortByStartDateParamName: 'sortByDate',
            statusParamName: 'status',
          }}
        />
      </div>
    </PageLayout>
  );
};

export default DashboardPage;
