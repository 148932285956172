import { TableColumnItem } from 'components/Table/Table.component';
import { TMultiplierTableRow } from 'pages/SettingsPage/types/TMultiplierTableRow';
import MultipliersActions from '../components/MultipliersActions';
import React from 'react';

export default function useMultipliersTableColumns() {
  return [
    {
      columnLabel: 'Name',
      prepareCell: (data) => data?.name,
    },
    {
      columnLabel: 'Value',
      prepareCell: (data) => data?.value,
    },
    {
      columnLabel: 'Employees under multiplier',
      prepareCell: (data) => data?.users?.length || 0,
    },
    {
      columnLabel: 'Actions',
      columnName: 'actions',
      prepareCell: (data) => <MultipliersActions multiplier={data} />,
    },
  ] as TableColumnItem<TMultiplierTableRow>[];
}
