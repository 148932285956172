import api from 'api';
import { Multiplier } from 'models/Multiplier';
import { create } from 'zustand';

interface EmploymentState {
  isLoading: boolean;
  multipliers: Multiplier[];
  error?: string;
  getMultipliers: () => void;
  addMultiplier: (mulitplier: Multiplier) => void;
  removeMultiplier: (mulitplier: Multiplier) => void;
  updateMultiplier: (mulitplier: Multiplier) => void;
}

const useGlobalMiltipliersStore = create<EmploymentState>()((set, get) => ({
  isLoading: false,
  multipliers: [],
  getMultipliers: async () => {
    try {
      set({ isLoading: true });
      const { data } = await api.admin.multipliers.getMulitpliers({
        limit: 100,
        page: 1,
      });
      const { items } = data;
      set({ isLoading: false, multipliers: items });
    } catch (err) {
      set({ isLoading: false });
    }
  },
  addMultiplier: (mulitplier: Multiplier) => {
    const state = get();
    set({ multipliers: [mulitplier, ...state.multipliers] });
  },
  removeMultiplier: (mulitplier: Multiplier) => {
    const state = get();
    set({ multipliers: state.multipliers.filter((mul) => mul.id !== mulitplier.id) });
  },
  updateMultiplier: (mulitplier: Multiplier) => {
    const state = get();
    set({
      multipliers: state.multipliers.map((mul) => (mul.id === mulitplier.id ? mulitplier : mul)),
    });
  },
}));

export default useGlobalMiltipliersStore;
