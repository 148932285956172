import { SearchIcon } from 'assets';
import { Input } from 'components';
import { SectionBlock } from 'components/layout/SectionBlock';
import Table from 'components/Table';
import TablePagination from 'components/TablePagination';
import { getYear } from 'date-fns';
import useDebounce from 'hooks/useDebounce';
import { SortingOrder } from 'models/types';
import useEmployeeVacationsStore from 'pages/NonWorkingDaysPage/stores/useEmployeeVacationsStore';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useWorkingDaysTableColumns from './hooks/useWorkingDaysTableColumns';
import { mapUrlParamsToRequestParams } from './utils/utils';

import './NonWorkingDaysTable.styles.scss';

export type NonWorkingDaysUrlParamNames = {
  pageParamName: string;
  searchParamName: string;
  sortByEmployeeParamName: string;
  sortByStartDateParamName: string;
};

type NonWorkingDaysTableProps = {
  urlParamNames: NonWorkingDaysUrlParamNames;
};

const initParams = {
  limit: 15,
  page: 1,
  populate: ['firstContract', 'lastContract'],
  search: '',
  sort: {
    firstName: SortingOrder.ASCENDING,
    lastName: SortingOrder.ASCENDING,
    'firstContract.startDate': SortingOrder.UNSORTED,
  },
};

const NonWorkingDaysTable: FC<NonWorkingDaysTableProps> = ({ urlParamNames }) => {
  const [searchString, setSearchString] = useState('');
  const { vacations, getVacations, isLoading, pagination } = useEmployeeVacationsStore();

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const params = useMemo(
    () => ({ ...initParams, ...mapUrlParamsToRequestParams(urlParamNames, searchParams) }),
    [urlParamNames, searchParams],
  );
  const sortByFirstName = +(params.sort.firstName || '');
  const sortByDate = +(params.sort['firstContract.startDate'] || '');

  const handleSortByName = () => {
    searchParams.set(
      urlParamNames.sortByEmployeeParamName,
      !sortByFirstName ? '-1' : (-sortByFirstName).toString(),
    );
    searchParams.delete(urlParamNames.sortByStartDateParamName);
    setSearchParams(searchParams);
  };

  const handleSortByStartDate = () => {
    searchParams.set(
      urlParamNames.sortByStartDateParamName,
      !sortByDate ? '-1' : (-sortByDate).toString(),
    );
    searchParams.delete(urlParamNames.sortByEmployeeParamName);
    setSearchParams(searchParams);
  };

  const onPageChange = (page: number) => {
    searchParams.set(urlParamNames.pageParamName, page.toString());
    setSearchParams(searchParams);
  };

  useEffect(() => {
    getVacations(params);
  }, [params]);

  const columns = useWorkingDaysTableColumns(
    handleSortByName,
    handleSortByStartDate,
    sortByFirstName,
    sortByDate,
    (userId: string) => navigate(`/employees/manage-employees/${userId}`),
  );
  const debouncedSearchString = useDebounce(searchString, 500);

  useEffect(() => {
    if (!urlParamNames.searchParamName || debouncedSearchString === params.search) return;

    if (!debouncedSearchString) searchParams.delete(urlParamNames.searchParamName);
    else searchParams.set(urlParamNames.searchParamName, debouncedSearchString);

    searchParams.delete(urlParamNames.pageParamName);

    setSearchParams(searchParams);
  }, [debouncedSearchString]);

  const onSearchStringChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  }, []);

  return (
    <SectionBlock
      title={`List of all employees with their vacation days for ${getYear(new Date())}`}
      className='ne-non-working-days'
      loading={isLoading}
    >
      <Input
        prefixNode={<SearchIcon />}
        value={searchString}
        onChange={onSearchStringChange}
        placeholder='Search for an employee...'
        className='search-input'
      />
      <div className='ne-non-working-days__table-container'>
        <Table
          data={vacations}
          prepareData={columns}
          noDataMessage={
            vacations.length === 0 && debouncedSearchString !== '' ? '' : 'There are no employees.'
          }
        />
      </div>

      {pagination?.totalItems > 0 && (
        <TablePagination
          totalPages={pagination?.totalPages}
          currentPage={pagination?.currentPage}
          onPageChange={onPageChange}
          totalItems={pagination?.totalItems}
          pageSize={params.limit}
        />
      )}
    </SectionBlock>
  );
};

export default NonWorkingDaysTable;
