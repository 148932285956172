import { PenIcon, SortIcon, UnsortedIcon } from 'assets';
import { Button } from 'components';
import { TableColumnItem } from 'components/Table/Table.component';
import { TGeneralVacation } from 'models/GeneralVacation';
import DateService from 'services/Date.service';

const useGeneralVacationsTableColumns = (
  handleOpenEditModal: (row: TGeneralVacation) => void,
  handleSortByStartDate: () => void,
  sort: number,
) => {
  const prepareData: TableColumnItem<TGeneralVacation>[] = [
    {
      columnLabel: 'Type',
      columnName: 'type',
      prepareCell: (data) => data.type,
    },
    {
      columnLabel: (
        <p className={`sort-icon-${sort}`}>
          Start
          <Button
            variant='icon'
            onClick={() => handleSortByStartDate()}
            aria-label='Sort by start date'
          >
            {sort === 0 && <UnsortedIcon />}
            {sort !== 0 && <SortIcon />}
          </Button>
        </p>
      ),
      columnName: 'start',
      prepareCell: (data) => DateService.formatDate(data.startDate),
    },
    {
      columnLabel: 'End',
      columnName: 'end',
      prepareCell: (data) => (data.endDate ? DateService.formatDate(data.endDate) : ''),
    },
    {
      columnLabel: 'Days',
      columnName: 'days',
      prepareCell: (data) => data.workingDays,
    },
    {
      columnLabel: 'Description',
      columnName: 'description',
      prepareCell: (data) => data.description,
    },
    {
      columnLabel: 'Edit',
      columnName: 'edit',
      prepareCell: (row) => {
        return (
          <Button variant='icon' onClick={() => handleOpenEditModal(row)} aria-label='Edit'>
            <PenIcon />
          </Button>
        );
      },
    },
  ];

  return prepareData;
};

export default useGeneralVacationsTableColumns;
