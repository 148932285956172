/* eslint-disable no-undef */
import { File } from 'models/File';
import { useCallback, useRef } from 'react';

type DownloadFileProps = {
  readonly onError: () => void;
  readonly getFileName?: () => string;
  document?: File;
};

type DownloadedFileInfo = {
  readonly download: () => void;
  readonly ref: React.MutableRefObject<HTMLAnchorElement | null>;
};

export const useDownloadFile = ({ document }: DownloadFileProps): DownloadedFileInfo => {
  const ref = useRef<HTMLAnchorElement | null>(null);

  const download = useCallback(() => {
    if (document) {
      const fileDataJson = JSON.stringify(document);
      const blob = new Blob([fileDataJson], { type: 'application/json' });

      // Create a Blob from the response data and generate a temporary URL for the file
      const url = URL.createObjectURL(blob);

      const fileName: string = extractFilenameFromAwsKey(
        document.awsKey || document.documentKey || '',
      );

      if (ref.current && fileName) {
        ref.current.href = document.fileUrl;
        ref.current.download = fileName || 'downloaded-file.pdf';
        ref.current.click();
      }

      URL.revokeObjectURL(url);
    }
  }, [document]);

  const extractFilenameFromAwsKey = (awsKey: string) => {
    // Split the awsKey and get the last part as the filename
    const parts = awsKey.split('/');
    return parts[parts.length - 1];
  };

  return { download, ref };
};
