import React, { FC } from 'react';
import { Avatar, Button, Modal } from 'components';
import { User } from 'models/User';
import { useAxios, useModalState } from 'hooks';
import { API_PATH } from 'constant';
import { snackbar } from 'modules';
import useAdminsStore from 'pages/RoleManagementPage/store/useAdminsStore';
import { DeleteIcon } from 'assets';
import { UserRole } from 'enums';
import { useAuth } from 'context';

import './RegisteredAdminActions.styles.scss';

type RegisteredAdminActionsProps = {
  admin: User;
};

const RegisteredAdminActions: FC<RegisteredAdminActionsProps> = ({ admin }) => {
  const { user } = useAuth();
  const { firstName, lastName, profileImageUrl } = admin;
  const { getAdmins } = useAdminsStore();
  const {
    isOpen: isRemoveAdminModalOpen,
    closeModal: closeRemoveAdminModal,
    openModal: openRemoveAdminModal,
  } = useModalState();

  const { loading: loadingRemoveAdmin, request: removeAdmin } = useAxios({
    url: `${API_PATH.REMOVE_USER}/${admin.id}`,
    method: 'DELETE',
    onResponse: () => {
      snackbar.show({ message: 'Admin successfully removed.', type: 'success' });
      closeRemoveAdminModal();
      getAdmins(1);
    },
    onError: (error) => {
      snackbar.show({ message: error.message, type: 'error' });
    },
  });

  return (
    <>
      <Button
        className='remove-admin-btn'
        variant='icon'
        icon={<DeleteIcon />}
        onClick={openRemoveAdminModal}
        type='button'
        disabled={user?.role !== UserRole.SUPER_ADMIN}
      />
      <Modal onRequestClose={closeRemoveAdminModal} isOpen={isRemoveAdminModalOpen} hasCloseIcon>
        <div className='remove-admin-modal'>
          <h2>Remove admin</h2>
          <p>
            Are you sure you want to remove the admin? By removing, they will no longer have access
            to their account and will not be able to manage employee information.
          </p>
          <div className='ne-personal-information__remove-modal__employee-info'>
            <Avatar imageUrl={profileImageUrl} alt='employee-photo' /> {firstName} {lastName}
          </div>
          <div className='remove-admin-modal__actions-buttons'>
            <Button onClick={closeRemoveAdminModal} variant='outline' type='button'>
              Cancel
            </Button>
            <Button type='button' onClick={() => removeAdmin({})} loading={loadingRemoveAdmin}>
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RegisteredAdminActions;
