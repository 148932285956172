import { AxiosError } from 'axios';

export function parseAxiosError(
  error: AxiosError<{ error?: string | string[]; message?: string }>,
) {
  if (!error?.response?.data) return "Couldn't parse error";
  return error.response?.data.message || error.message;
}

export default parseAxiosError;
