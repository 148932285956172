import { yupResolver } from '@hookform/resolvers/yup';
import { Avatar, Button, FieldWrapper } from 'components';
import Input, { InputProps } from 'components/Input/Input.component';
import { TMultiplierTableRow } from 'pages/SettingsPage/types/TMultiplierTableRow';
import React, { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { isDecimalNumberFormat } from 'utils/formatNumber';
import { MSG_REQUIRED_FIELD } from 'validation';
import * as Yup from 'yup';

import './MultiplierForm.styles.scss';

export type MultiplierFormValues = { name: string; value: string };

type MultiplierFormProps = {
  onSubmit: (values: MultiplierFormValues) => void;
  title: string;
  buttonText: string;
  multiplier?: TMultiplierTableRow;
};

export const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const allowedKeys = new Set([
    'Delete',
    'Backspace',
    'Tab',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'ArrowDown',
    'Enter',
  ]);

  if (
    allowedKeys.has(event.key) ||
    event.ctrlKey ||
    (event.metaKey && ['a', 'c', 'v'].includes(event.key))
  )
    return;

  const { value, selectionStart } = event.target as HTMLInputElement;
  const cursorPosition = selectionStart ?? 0;
  const newValue = value.slice(0, cursorPosition) + event.key + value.slice(cursorPosition);

  if (!isDecimalNumberFormat(newValue)) event.preventDefault();
};

const MultiplierForm: React.FC<MultiplierFormProps> = ({
  onSubmit,
  title,
  buttonText,
  multiplier,
}) => {
  const validation = Yup.object().shape({
    name: Yup.string().required(MSG_REQUIRED_FIELD),
    value: Yup.string()
      .required(MSG_REQUIRED_FIELD)
      .test('is-valid-value', 'Value must be between 1 and 2', (value) => {
        const parsedValue = parseFloat(value);
        return parsedValue >= 1 && parsedValue <= 2;
      }),
  });

  const navigate = useNavigate();
  const methods = useForm<MultiplierFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(validation),
    defaultValues: multiplier ? { value: multiplier.value, name: multiplier.name } : { value: '1' },
  });

  const submitDocument = useCallback(
    async (v: MultiplierFormValues) => {
      return onSubmit(v);
    },
    [document, methods],
  );

  const renderMultipliers = useMemo(() => {
    if (!multiplier) return <></>;
    return (
      <>
        <h4>Employees under multiplier ({multiplier.users.length})</h4>
        <ul
          className={`multiplier-users ${multiplier.users.length > 10 && 'multiplier-users-scrollable'}`}
        >
          {multiplier
            ? multiplier.users.map((user) => (
                <li
                  key={user.id}
                  onClick={() => navigate(`/employees/manage-employees/${user.id}`)}
                >
                  <Avatar imageUrl={user.profileImageUrl} alt={'employee-image'} size='sm' />
                  <div>
                    <p>{user.firstName}</p>
                    <p>{user.lastName}</p>
                  </div>
                </li>
              ))
            : undefined}
        </ul>
      </>
    );
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submitDocument)} name='formVacation'>
        <h1>{title}</h1>
        <FieldWrapper<InputProps>
          name='name'
          fieldComponent={Input}
          label='Multiplier name'
          type='text'
          required
          asterix
        />
        <FieldWrapper<InputProps>
          name='value'
          fieldComponent={Input}
          label='Value'
          required
          asterix
          helperText='* Changing the value will automatically be updated for employees that are under this multiplier.'
          onKeyDown={handleKeyDown}
        />
        {renderMultipliers}
        <Button
          disabled={!methods.formState.isDirty}
          loading={methods.formState.isSubmitting}
          type='submit'
        >
          {buttonText}
        </Button>
      </form>
    </FormProvider>
  );
};

export default MultiplierForm;
