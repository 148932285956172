import { NonWorkingDaysUrlParamNames } from '../NonWorkingDaysTable.component';
import { TEmployeeVacationsParams } from 'pages/NonWorkingDaysPage/types/TEmployeeVacationsParams';

export const mapUrlParamsToRequestParams = (
  urlParamsNames: NonWorkingDaysUrlParamNames,
  searchParams: URLSearchParams,
): Partial<TEmployeeVacationsParams> => {
  const { pageParamName, searchParamName, sortByEmployeeParamName, sortByStartDateParamName } =
    urlParamsNames;

  const page = +(searchParams.get(pageParamName) || '');
  const search = searchParams.get(searchParamName);
  const sortByName = +(searchParams.get(sortByEmployeeParamName) || '');
  const sortByStartDate = +(searchParams.get(sortByStartDateParamName) || '');

  return {
    ...(page && { page }),
    ...(search && { search }),
    ...(sortByName && {
      sort: {
        firstName: sortByName,
        lastName: sortByName,
      },
    }),
    ...(sortByStartDate && {
      sort: {
        'firstContract.startDate': sortByStartDate,
      },
    }),
  };
};
