import React, { useMemo, useState } from 'react';
import userDefault from 'assets/userDefault.png';
import classNames from 'classnames';
import { LazyImage } from 'components/LazyImage';

import './Avatar.styles.scss';

type AvatarProps = {
  imageUrl?: string;
  alt: string;
  size?: 'xl' | 'l' | 'md' | 'sm';
};

const Avatar: React.FC<AvatarProps> = ({ imageUrl, alt, size = 'xl' }) => {
  const [isLoading, setLoading] = useState(true);

  const imageSrc = useMemo(() => {
    return imageUrl || userDefault;
  }, [imageUrl]);

  const classes = classNames('ne-avatar', `ne-avatar--${size}`);

  return (
    <div className={classes}>
      <LazyImage src={imageSrc} alt={alt} onLoad={() => setLoading(false)} />
    </div>
  );
};

export default Avatar;
