import classNames from 'classnames';
import { useCallbackRef } from 'hooks';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import './Dropdown.styles.scss';

export type DropdownProps = {
  className?: string;
  trigger?: React.ReactNode;
  renderInParent: boolean;
  children: React.ReactNode;
  isOpen: boolean;
  toggleOpenDropdown?: () => void;
};

export type DropdownRef = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
};

const Dropdown: React.ForwardRefRenderFunction<DropdownRef, DropdownProps> = (props, ref) => {
  const { className, trigger, children, renderInParent, toggleOpenDropdown, isOpen } = props;

  const contentRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const [refContent, dropdownRef] = useCallbackRef(null);

  const [dropUp, setDropUp] = useState(false);
  const [dropLeft, setDropLeft] = useState(false);

  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;

  const dropdownClasses = classNames('nce-dropdown', className);
  const contentClasses = useMemo(
    () =>
      classNames('nce-dropdown__content', {
        'nce-dropdown__content--up': dropUp,
        'nce-dropdown__content--open': isOpen,
        'nce-dropdown__content--left': dropLeft,
      }),
    [dropUp, dropLeft, isOpen],
  );
  const triggerClasses = classNames('nce-dropdown__trigger');

  useEffect(() => {
    const headerHeight = headerRef?.current?.getBoundingClientRect().height || 0;
    const dropHeight = headerRef?.current?.getBoundingClientRect().top || 0;
    const contentHeight = contentRef?.current?.getBoundingClientRect().height || 0;

    const triggerRight = headerRef?.current?.getBoundingClientRect().right || 0;
    const contentWidth = contentRef?.current?.getBoundingClientRect().width || 0;

    setDropUp(windowHeight < dropHeight + headerHeight + contentHeight);
    setDropLeft(windowWidth < triggerRight + contentWidth);
  }, [windowHeight, windowWidth]);

  useEffect(() => {
    if (!isOpen || !toggleOpenDropdown) return;

    const close = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      // Check if the click is outside of the dropdown and not on the trigger
      if (
        headerRef.current &&
        !headerRef.current.contains(target) &&
        contentRef.current &&
        !contentRef.current.contains(target)
      ) {
        toggleOpenDropdown(); // Close dropdown when clicking outside
      }
    };

    document.body.addEventListener('click', close);

    return () => {
      document.body.removeEventListener('click', close);
    };
  }, [isOpen, toggleOpenDropdown]);

  return (
    <div className={dropdownClasses} ref={dropdownRef}>
      <div
        ref={headerRef}
        className={triggerClasses}
        onClick={(event) => {
          if (toggleOpenDropdown) toggleOpenDropdown();
        }}
      >
        {trigger}
      </div>
      {ReactDOM.createPortal(
        <div
          className={contentClasses}
          ref={contentRef}
          onClick={(event) => event.stopPropagation()}
        >
          {children}
        </div>,
        renderInParent && refContent
          ? refContent
          : document.getElementById('root') || document.body,
      )}
    </div>
  );
};

export default forwardRef(Dropdown);
