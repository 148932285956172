import { yupResolver } from '@hookform/resolvers/yup';
import { EmployeeNonWorkingDays } from 'models/NonWorkingDays';
import React, { FC, useCallback, useMemo, JSX, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { MSG_REQUIRED_FIELD } from 'validation';
import * as Yup from 'yup';
import { EditNonWorkingDaysForm } from './components';
import { snackbar } from 'modules';
import useEmployeeVacationsStore from 'pages/NonWorkingDaysPage/stores/useEmployeeVacationsStore';

import './EditNonWorkingDays.styles.scss';

type EditNonWorkingDaysProps = {
  data: EmployeeNonWorkingDays;
  onCancel?: () => void;
  onSuccess?: () => void;
  employee?: string | JSX.Element;
  variant?: 'modal' | 'standard';
  isEditMode?: boolean;
  isContentOpen?: boolean;
};

export type EditNonWorkingDaysValue = {
  previousYear: number;
  currentYear: number;
};

const validation = () =>
  Yup.object().shape({
    previousYear: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(MSG_REQUIRED_FIELD)
      .moreThan(-1, "Num of days for current year can't be negative number"),
    currentYear: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(MSG_REQUIRED_FIELD)
      .moreThan(-1, "Num of days for previous year can't be negative number"),
  });

const EditNonWorkingDays: FC<EditNonWorkingDaysProps> = ({
  data,
  onCancel,
  onSuccess,
  ...rest
}) => {
  const defaultValues = useMemo(() => {
    const { nonWorkingDays } = data || {};
    const { vacationDaysCurrentYear, vacationDaysPreviousYear } = nonWorkingDays || {};

    return {
      previousYear: vacationDaysPreviousYear,
      currentYear: vacationDaysCurrentYear,
    };
  }, [data]);

  const methods = useForm<EditNonWorkingDaysValue>({
    mode: 'onTouched',
    resolver: yupResolver(validation()),
    defaultValues,
  });
  const { updateNonWorkingDays } = useEmployeeVacationsStore();

  const onEditSuccess = useCallback(() => {
    snackbar.show({
      message: 'You have successfully changed vacation days for an employee.',
      type: 'success',
    });
    onSuccess?.();
  }, [onSuccess]);

  const onEditError = useCallback((errorMessage: string) => {
    const finalMessage = errorMessage.replace('vacationDaysCurrentYear', 'No of days');
    snackbar.show({ message: finalMessage, type: 'error' });
  }, []);

  const handleEdit = useCallback(
    (value: EditNonWorkingDaysValue) => {
      return updateNonWorkingDays(data.employeeId, value, onEditSuccess, onEditError);
    },
    [onEditError, onEditSuccess, data],
  );

  const handleCancelButtonClicked = useCallback(() => {
    onCancel?.();
    methods.reset(defaultValues);
  }, []);

  useEffect(() => {
    methods.reset(defaultValues);
  }, [data]);

  return (
    <EditNonWorkingDaysForm
      employee={data.employee}
      nonWorkingDays={data.nonWorkingDays}
      onSubmit={handleEdit}
      isLoading={methods.formState.isSubmitting}
      methods={methods}
      onCancelButtonClicked={handleCancelButtonClicked}
      defaultValues={defaultValues}
      {...rest}
    />
  );
};

export default EditNonWorkingDays;
