import React, { FC, HTMLAttributes } from 'react';

interface LazyImageProps extends HTMLAttributes<HTMLImageElement> {
  src?: string;
  alt?: string;
  width?: number | string;
  height?: number | string;
  referrerPolicy?:
    | 'no-referrer'
    | 'no-referrer-when-downgrade'
    | 'origin'
    | 'origin-when-cross-origin'
    | 'unsafe-url';
  sizes?: string;
  srcSet?: string;
  useMap?: string;
  crossOrigin?: 'anonymous' | 'use-credentials' | '';
  isMap?: boolean;
}

const LazyImage: FC<LazyImageProps> = (props) => {
  return <img loading='lazy' decoding='async' {...props} />;
};

export default LazyImage;
