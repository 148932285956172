/* eslint-disable react/no-deprecated */
import React from 'react';
import { CloseIcon } from 'assets';
import ReactDOM from 'react-dom';
import './Snackbar.styles.scss';

type SnackBarType = {
  message: string;
  type: 'success' | 'error';
  timeout?: number;
  anchorOrigin?: { horizontal: string; vertical: string };
};

const hide = () => {
  const parent = document.body;
  const overlays = Array.from(document.getElementsByClassName('snackbar'));

  overlays.forEach((overlay) => {
    parent.removeChild(overlay);
  });
};
const snackbar = {
  show: function ({
    message,
    type = 'success',
    timeout = 3000,
    anchorOrigin = { horizontal: 'center', vertical: 'top' },
  }: SnackBarType) {
    const parent = document.body;

    const overlay = document.createElement('div');

    overlay.classList.add('snackbar');
    overlay.classList.add(`snackbar--${type}`);
    overlay.classList.add(`snackbar--${anchorOrigin.horizontal}`);
    overlay.classList.add(`snackbar--${anchorOrigin.vertical}`);

    const closeElement = <CloseIcon onClick={hide} />;
    const messageElement = document.createElement('span');

    messageElement.innerHTML = message;

    ReactDOM.render(closeElement, overlay);
    overlay.appendChild(messageElement);
    parent.append(overlay);
    setTimeout(hide, timeout);
  },
};

export default snackbar;
