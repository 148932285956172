import { useCallback, useState } from 'react';

const useModalState = (initialValue = false) => {
  const [isOpen, setIsOpen] = useState(initialValue);

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  return { isOpen, openModal, closeModal };
};

export default useModalState;
