import { PenIcon } from 'assets';
import { Button, Modal } from 'components';
import { EditNonWorkingDays } from 'components/EditNonWorkingDays';
import { useModalState } from 'hooks';
import { EmployeeNonWorkingDays } from 'models/NonWorkingDays';
import React from 'react';

type EditWorkingDaysProps = {
  data: EmployeeNonWorkingDays;
};
const EditWorkingDays: React.FC<EditWorkingDaysProps> = ({ data }) => {
  const { isOpen, closeModal, openModal } = useModalState();

  return (
    <div>
      <Button
        onClick={openModal}
        className='edit-button'
        type='button'
        variant='icon'
        aria-label='Edit'
      >
        <PenIcon />
      </Button>
      <Modal isOpen={isOpen} onRequestClose={closeModal} className='edit-nwd-modal' hasCloseIcon>
        <EditNonWorkingDays data={data} onCancel={closeModal} onSuccess={closeModal} />
      </Modal>
    </div>
  );
};

export default EditWorkingDays;
