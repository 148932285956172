import { format } from 'date-fns';

export const DEAULT_FORMAT = 'dd.MM.yyyy';
export const DATE_AND_TIME_FORMAT = 'dd.MM.yyyy, HH:mm';

const formatDate = (date: Date | string, dateFormat = DEAULT_FORMAT) => {
  return format(date, dateFormat);
};

export default {
  formatDate,
};
