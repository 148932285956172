import { SortingOrder } from 'models/types';
import { EmployeesUrlParamNames } from '../Employees.page';

export const mapUrlParamsToRequestParams = (
  urlParamsNames: EmployeesUrlParamNames,
  searchParams: URLSearchParams,
) => {
  const { searchParamName, sortByParamName } = urlParamsNames;

  const search = searchParams.get(searchParamName);
  const sortByName = +(searchParams.get(sortByParamName) || '') || SortingOrder.ASCENDING;

  return {
    searchString: search || '',
    sortByName,
  };
};
