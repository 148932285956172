import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Button, Modal } from 'components';
import { EmployeeDocument } from 'models/EmployeeDocument';
import { formatNumber } from 'utils/formatNumber';
import useGlobalMiltipliersStore from 'store/useGlobalMiltipliersStore';
import { useAxios } from 'hooks';
import { User } from 'models/User';
import { ErrorType } from 'models/types';
import { API_PATH } from 'constant';
import { snackbar } from 'modules';
import parseAxiosError from 'utils/parseAxiosError';
import EmployeeContext from 'pages/EmployeeProfilePage/context/EmploeeContext';

import './UpdateSalaryInfoModal.styles.scss';

type UpdateSalaryInformationPayload = {
  multiplierId: string;
  netSalary: {
    amount: number;
    currency?: string;
  };
};

type UpdateSalaryInfoModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  document?: EmployeeDocument;
  onSuccess?: () => void;
};

const UpdateSalaryInfoModal: FC<UpdateSalaryInfoModalProps> = ({
  isOpen,
  closeModal,
  document,
  onSuccess,
}) => {
  const { netSalary, multiplierId, grossSalary, userId } = document || {};
  const { multipliers } = useGlobalMiltipliersStore();
  const { updateEmployee } = useContext(EmployeeContext);

  const multiplierName = useMemo(
    () => multipliers.find((multiplier) => multiplier.id === multiplierId)?.name,
    [multipliers, document],
  );

  const { request: editUserSalaryInformation, loading } = useAxios<
    User,
    ErrorType,
    UpdateSalaryInformationPayload
  >({
    url: `${API_PATH.USERS}/${userId}`,
    method: 'PATCH',
    onResponse: (response) => {
      const { data: user } = response;
      snackbar.show({
        message: 'Salary information successfully changed.',
        type: 'success',
      });

      updateEmployee(user);

      closeModal();
      onSuccess?.();
    },
    onError: (error) => {
      const errorMessage = parseAxiosError(error) || '';
      snackbar.show({ message: errorMessage, type: 'error' });
    },
  });

  const changeSalaryInformation = useCallback(() => {
    if (!netSalary || !multiplierId) return;

    return editUserSalaryInformation({
      payload: {
        multiplierId: multiplierId,
        netSalary,
      },
    });
  }, [editUserSalaryInformation]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      hasCloseIcon
      className='update-salary-info-modal'
    >
      <div className='update-salary-info-modal__content'>
        <h2>Update Salary Information</h2>
        <p>
          It looks like you haven&apos;t entered information about this employee&apos;s salary yet.
          Would you like to update it to match salary information from document you just added?
        </p>
        <div className='update-salary-info-modal__salary'>
          Salary information from document:
          <div>
            <div className='update-salary-info-modal__salary-row'>
              <b>Multiplier:</b>
              {multiplierName}
            </div>
            <div className='update-salary-info-modal__salary-row'>
              <b>Net salary:</b>
              {formatNumber(netSalary?.amount || 0, 2)}&nbsp;{netSalary?.currency}
            </div>
            <div className='update-salary-info-modal__salary-row'>
              <b>Gross salary:</b>
              {formatNumber(grossSalary?.amount || 0, 2)}&nbsp;{grossSalary?.currency}
            </div>
          </div>
        </div>
        <div className='update-salary-info-modal__btns'>
          <Button onClick={() => closeModal()} type='button' variant='outline'>
            No
          </Button>
          <Button type='button' onClick={changeSalaryInformation} loading={loading}>
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateSalaryInfoModal;
