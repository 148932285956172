import { AxiosError, AxiosResponse, isAxiosError, Method } from 'axios';
import { useCallback, useRef, useState } from 'react';

import { OverlaySpinner } from 'components';
import { httpClient } from 'modules';
import qs from 'qs';
import { parseQueryParams } from 'utils/parseQueryParams';
import { TParams } from 'models/TParams';

export type IAxiosParams<TResponse, TError> = {
  url: string;
  method: Method;
  onResponse?: (responseParam: AxiosResponse<TResponse>) => void;
  onError?: (errorParam: AxiosError<TError>) => void;
  params?: Record<string, any>;
  initLoading?: boolean;
  overlayClass?: string;
  throwOnError?: boolean;
};

type RequstParams<T> = {
  payload?: T;
  queryParams?: Record<string, any>;
};

const useAxios = <TData, TError, TPayload = any>({
  url,
  method,
  onResponse,
  onError,
  overlayClass,
  throwOnError,
  initLoading = false,
}: IAxiosParams<TData, TError>) => {
  const [response, setResponse] = useState<AxiosResponse<TData>>();
  const [error, setError] = useState<AxiosError<TError>>();
  const [loading, setLoading] = useState<boolean>(initLoading);

  const controllerRef = useRef(new AbortController());
  const cancel = () => {
    controllerRef.current.abort();
  };

  const request = useCallback(
    async ({ payload, queryParams }: RequstParams<TPayload>) => {
      setLoading(true);

      try {
        overlayClass && OverlaySpinner.show(`.${overlayClass}`);

        const response = await httpClient.request({
          data: payload,
          params: parseQueryParams({ ...queryParams } as TParams),
          signal: controllerRef.current.signal,
          method,
          url: url.replace('{', '%7B').replace('}', '%7D'),
          paramsSerializer: (params: Record<string, any>) =>
            qs.stringify(params).replace('{', '%7B').replace('}', '%7D'),
        });
        setResponse(response);
        onResponse?.(response);
      } catch (error) {
        if (isAxiosError(error)) {
          setError(error);
          onError?.(error);

          if (throwOnError) throw error;
        }
      } finally {
        setLoading(false);
        overlayClass && OverlaySpinner.hide(`.${overlayClass}`);
      }
    },
    [method, onError, onResponse, overlayClass, throwOnError, url],
  );

  return { cancel, response, request, error, setError, loading };
};

export { useAxios };
