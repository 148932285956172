import { yupResolver } from '@hookform/resolvers/yup';
import { AddIcon } from 'assets';
import { Button, DatePicker, FieldWrapper, ImageUpload, Input } from 'components';
import { InputProps } from 'components/Input/Input.component';
import { MB_TO_BYTE } from 'constant';
import { FormProvider, useForm } from 'react-hook-form';
import {
  EMAIL_REGEX,
  LETTERS_REGEX,
  MSG_EMAIL_INVALID,
  MSG_LETTERS_ONLY,
  MSG_REQUIRED,
  NAME_REGEX,
  PHONE_REGEX_381,
  PHONE_REGEX_NORMAL,
} from 'validation';
import { MSG_NAME_INVALID } from 'validation/messages';
import * as Yup from 'yup';
import { ImageUploadProps } from 'components/ImageUpload/ImageUpload.component';
import { DropzoneOptions } from 'react-dropzone';
import { useCallback } from 'react';
import { getFileBlob } from 'utils/getFileBlob';
import { EmployeeFormValues } from 'pages/EmployeeProfilePage/components/EmploymentInformation/types/EmployeeFormValues';
import DateService from 'services/Date.service';
import { PERSONAL_ID_REGEX } from 'validation/regex';
import './NewEmployeeForm.styles.scss';

type NewEmployeeFormProps = {
  handleAddEmployee: (data: FormData) => void;
};

const validation = Yup.object().shape({
  firstName: Yup.string()
    .required(MSG_REQUIRED)
    .matches(LETTERS_REGEX, MSG_LETTERS_ONLY)
    .matches(NAME_REGEX, MSG_NAME_INVALID)
    .max(30, 'First name too long'),
  lastName: Yup.string()
    .required(MSG_REQUIRED)
    .matches(LETTERS_REGEX, MSG_LETTERS_ONLY)
    .matches(NAME_REGEX, MSG_NAME_INVALID)
    .max(30, 'Last name too long'),
  email: Yup.string()
    .email(MSG_EMAIL_INVALID)
    .required(MSG_REQUIRED)
    .matches(EMAIL_REGEX, MSG_EMAIL_INVALID),
  phoneNumber: Yup.string().test(
    'phoneNumber',
    'Example: +38160123321 or 060123321, no blank spaces',
    (value) => {
      if (!value) return true;
      return PHONE_REGEX_381.test(value) || PHONE_REGEX_NORMAL.test(value);
    },
  ),
  personalId: Yup.string().test(
    'personalId',
    'Example: 0101995123456, no blank spaces, only numbers',
    (value) => {
      if (!value) return true;
      return PERSONAL_ID_REGEX.test(value);
    },
  ),
});

const dropzoneConfig: DropzoneOptions = {
  maxFiles: 1,
  multiple: false,
  maxSize: 5 * MB_TO_BYTE,
  accept: {
    'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
  },
};

const NewEmployeeForm = (props: NewEmployeeFormProps) => {
  const { handleAddEmployee } = props;

  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(validation),
    defaultValues: { file: [] },
  });

  const submitEmployee = useCallback(async (v: EmployeeFormValues) => {
    const formData = new FormData();
    formData.set('firstName', v.firstName);
    formData.set('lastName', v.lastName);
    formData.set('email', v.email);
    v.file?.[0] && formData.set('file', await getFileBlob(v.file[0]));
    v.phoneNumber && formData.set('refferenceNumber', '+381');
    v.phoneNumber &&
      formData.set(
        'phoneNumber',
        v.phoneNumber?.startsWith('+381')
          ? v.phoneNumber?.substring(4, v.phoneNumber.length)
          : v.phoneNumber?.substring(1, v.phoneNumber.length),
      );
    v.address && formData.set('address', v.address);
    v.birthDate && formData.set('birthDate', DateService.formatDate(v.birthDate, 'yyyy-MM-dd'));
    v.personalId && formData.set('personalId', v.personalId);
    return handleAddEmployee(formData);
  }, []);

  return (
    <div className='new-employee-form'>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submitEmployee)}>
          <h1>Create New Employee</h1>
          <h2>Account details</h2>
          <div className='new-employee-form__content'>
            <FieldWrapper<InputProps>
              fieldComponent={Input}
              name='firstName'
              label='First name'
              asterix
            />
            <FieldWrapper<InputProps>
              fieldComponent={Input}
              name='lastName'
              label='Last name'
              asterix
            />
            <FieldWrapper<InputProps> fieldComponent={Input} name='email' label='Email' asterix />
            <div className='new-employee-form__content-image'>
              <div className='label'>
                Profile image
                <p>
                  <span>Tip:</span> Choose an image where face is recognizable - .JPG .PNG (Max 5MB)
                </p>
              </div>
              <FieldWrapper<ImageUploadProps>
                fieldComponent={ImageUpload}
                name='file'
                isProfilePicture={true}
                isEdit={true}
                dropzoneOptions={dropzoneConfig}
                onError={() => {
                  methods.setError('root', {
                    type: 'validate',
                    message: 'Please provide adequate picture.',
                  });
                }}
                onAccept={() => {
                  methods.clearErrors('root');
                }}
                isDirty={methods.getFieldState('file').isDirty}
              />
            </div>
          </div>
          <h2>Personal details</h2>
          <div className='new-employee-form__content'>
            <FieldWrapper<InputProps>
              fieldComponent={Input}
              name='phoneNumber'
              label='Phone number'
            />
            <FieldWrapper<InputProps> fieldComponent={Input} name='address' label='Address' />
            <FieldWrapper<any>
              fieldComponent={DatePicker}
              name='birthDate'
              label='Date of birth'
              minDate={new Date('January 1, 1900 00:00:00')}
              showYear
            />
            <FieldWrapper<InputProps>
              fieldComponent={Input}
              name='personalId'
              label='Personal ID (JMBG)'
            />
          </div>
          <Button type='submit' loading={methods.formState.isSubmitting}>
            <AddIcon />
            Create employee
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};

export default NewEmployeeForm;
