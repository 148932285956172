import React, { useEffect, useState, FC } from 'react';
import './ProgressCircle.styles.scss';

type ProgressCircleProps = {
  totalDays: number;
  daysLeft: number;
};

const ProgressCircle: FC<ProgressCircleProps> = ({ totalDays, daysLeft }) => {
  const radius = 70; // Radius of the circle
  const stroke = 8; // Stroke width of the progress
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;

  // Initial stroke-dashoffset represents an unfilled circle
  const initialOffset = circumference;

  // Final stroke-dashoffset represents the target fill state based on daysLeft
  const finalOffset = ((totalDays - daysLeft) / totalDays) * circumference;

  const [strokeDashoffset, setStrokeDashoffset] = useState(initialOffset);

  useEffect(() => {
    // Trigger the "animation" after the component mounts
    const timer = setTimeout(() => {
      setStrokeDashoffset(finalOffset);
    }, 100); // Small delay to ensure CSS transition is applied

    return () => clearTimeout(timer);
  }, [finalOffset]);

  return (
    <svg height={radius * 2} width={radius * 2} className='progress-circle'>
      <circle
        stroke='lightgray'
        fill='transparent'
        strokeWidth={stroke}
        strokeDasharray={circumference + ' ' + circumference}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <circle
        className='progress-circle__progress'
        stroke='black'
        fill='transparent'
        strokeWidth={stroke}
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <text className='progress-circle__label' x='50%' y='50%' dy='.3em'>
        {daysLeft}/{totalDays}
      </text>
    </svg>
  );
};

export default ProgressCircle;
