import { TParams } from 'models/TParams';
import { httpClient } from 'modules';
import { MultiplierFormValues } from 'pages/SettingsPage/components/MulitpliersSettings/components/MultiplierForm/MultiplierForm.component';
import { parseQueryParams } from 'utils/parseQueryParams';

async function getMulitpliers(params: TParams) {
  return httpClient.get('/multipliers', { params: parseQueryParams(params) });
}

async function createMultplier(data: MultiplierFormValues) {
  return httpClient.post('/multipliers', data);
}

async function removeMultiplier(multiplierId: string) {
  return httpClient.delete(`/multipliers/${multiplierId}`);
}

async function updateMultiplier(multiplierId: string, data: MultiplierFormValues) {
  return httpClient.patch(`/multipliers/${multiplierId}`, data);
}

export default {
  getMulitpliers,
  createMultplier,
  removeMultiplier,
  updateMultiplier,
};
