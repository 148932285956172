import api from 'api';
import { getYear } from 'date-fns';
import { TGeneralVacation } from 'models/GeneralVacation';
import { Pagination, SortingOrder } from 'models/types';
import { create } from 'zustand';
import { TGeneralVacationsParams } from '../types/TGeneralVacationsParams';

interface GeneralVacationsState {
  isLoading: boolean;
  vacations: TGeneralVacation[];
  pagination: Pagination;

  error?: string;
  getVacations: (params: TGeneralVacationsParams) => void;
}

const useGeneralVacationsStore = create<GeneralVacationsState>()((set, get) => ({
  isLoading: false,
  isRemovingDocument: false,
  isUpdatingDocument: false,
  vacations: [],

  pagination: {
    totalItems: 0,
    currentPage: 0,
    totalPages: 0,
    pageSize: 5,
  },
  getVacations: async (params: TGeneralVacationsParams) => {
    try {
      set({ isLoading: true });

      const { data } = await api.admin.generalVacations.getVacations(params);
      const { items, ...pagination } = data;
      set({ isLoading: false, vacations: items, pagination });
    } catch (err) {
      set({ isLoading: false });
    }
  },
}));

export default useGeneralVacationsStore;
