import currencies from './currencies';
import employee from './employee';
import generalVacations from './generalVacations';
import multipliers from './multipliers';
import nonWorkingDays from './nonWorkingDays';
import roles from './roles';

export default {
  nonWorkingDays,
  generalVacations,
  multipliers,
  employee,
  currencies,
  roles,
};
