import React, { useState, useMemo, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { CloseIcon, Loader, SearchIcon } from 'assets';
import useDebounce from 'hooks/useDebounce';
import Checkbox from 'components/Checkbox/Checkbox.component';
import { Button } from 'components/Button';
import { Input } from 'components/Input';

import './MultipleCheckbox.styles.scss';

type CheckboxOptionType = {
  id: string;
  label: string;
};

export type MultipleCheckboxProps = {
  options: CheckboxOptionType[];
  label?: string;
  hasError?: boolean;
  errorMessage?: string;
  asterix?: boolean;
  onChange?: (ids: Array<string>) => void;
  preselectedOptions: CheckboxOptionType[];
  isEditMode?: boolean;
};

const MultipleCheckbox: React.FC<MultipleCheckboxProps> = (props) => {
  const {
    options,
    label,
    hasError,
    errorMessage,
    asterix = false,
    onChange,
    preselectedOptions,
    isEditMode,
  } = props;

  const [selectedOptions, setSelectedOptions] = useState<CheckboxOptionType[]>(preselectedOptions);
  const [searchString, setSearchString] = useState('');

  const prevPreselectedOptionsRef = useRef<CheckboxOptionType[]>();

  useEffect(() => {
    const prevPreselectedOptions = prevPreselectedOptionsRef.current;

    if (
      prevPreselectedOptions === undefined ||
      prevPreselectedOptions.length !== preselectedOptions.length ||
      !prevPreselectedOptions.every((option, index) => option.id === preselectedOptions[index].id)
    ) {
      setSelectedOptions(preselectedOptions);
    }

    prevPreselectedOptionsRef.current = preselectedOptions;
  }, [preselectedOptions]);

  const debouncedSearchString = useDebounce(searchString, 500);

  const selectedOptionsClasses = classNames({
    'ne-multiple-checkbox__content__selected-options': !!selectedOptions.length,
    'ne-multiple-checkbox__content__selected-options--hidden': !selectedOptions.length,
  });

  const handleSelectOption = (option: CheckboxOptionType) => {
    const updatedOptions = [...selectedOptions, option];
    setSelectedOptions(updatedOptions);
    onChange?.(updatedOptions.map((opt) => opt.id));
  };

  const handleDeselectOption = (option: CheckboxOptionType) => {
    const updatedOptions = selectedOptions.filter((opt) => opt.id !== option.id);
    setSelectedOptions(updatedOptions);
    onChange?.(updatedOptions.map((opt) => opt.id));
  };

  const handleSelectAll = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (selectedOptions.length === options.length) {
      setSelectedOptions([]);
      onChange?.([]);
    } else {
      setSelectedOptions(options);
      onChange?.(options.map((opt) => opt.id));
    }
  };

  const remainingOptions = useMemo(
    () =>
      options.filter(
        (option) =>
          !selectedOptions.some((selectedOption) => selectedOption.id === option.id) &&
          option.label.toLowerCase().includes(debouncedSearchString.toLowerCase()),
      ),
    [options, selectedOptions, debouncedSearchString],
  );

  const selectAllButtonText = useMemo(
    () =>
      selectedOptions.length === options.length && options.length !== 0
        ? 'Deselect All'
        : 'Select All',
    [selectedOptions, options],
  );

  return (
    <div className='ne-multiple-checkbox'>
      <label className='ne-multiple-checkbox__label'>
        {label}
        {asterix && '*'}
      </label>
      <div className='ne-multiple-checkbox__content'>
        <Button
          className='ne-multiple-checkbox__content__select-all'
          variant='text'
          onClick={(event) => handleSelectAll(event)}
          disabled={options.length === 0}
        >
          {selectAllButtonText}
        </Button>
        <Input
          className='search-input'
          placeholder='Search for an employee...'
          prefixNode={<SearchIcon />}
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          disabled={remainingOptions.length === 0 && selectedOptions.length === options.length}
        />
        {isEditMode && selectedOptions.length === 0 && (
          <Loader className='ne-multiple-checkbox__content__spinner' />
        )}
        <div className={selectedOptionsClasses}>
          {selectedOptions.map((option) => (
            <label
              className='ne-multiple-checkbox__content__selected-options__option'
              key={option.id}
            >
              {option.label}
              <CloseIcon onClick={() => handleDeselectOption(option)} />
            </label>
          ))}
        </div>
        <div
          className={
            remainingOptions.length < 7
              ? 'ne-multiple-checkbox__content__options'
              : 'ne-multiple-checkbox__content__options__scroll'
          }
        >
          {remainingOptions.map((option) => (
            <Checkbox
              key={option.id}
              label={option.label}
              checked={selectedOptions.some((selectedOption) => selectedOption.id === option.id)}
              onChange={() => handleSelectOption(option)}
              useTimeout
            />
          ))}
        </div>
      </div>
      <small className={classNames('ncoded-small', hasError && 'ncoded-small--invalid-input')}>
        {errorMessage}
      </small>
    </div>
  );
};

export default MultipleCheckbox;
