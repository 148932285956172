import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SectionBlock } from 'components/layout/SectionBlock';
import useGeneralVacationsTableColumns from './hooks/useGeneralVacationsTableColumns';
import Table from 'components/Table';
import { GeneralVacationModal, YearPicker } from 'components';
import TablePagination from 'components/TablePagination';
import useGeneralVacationsStore from 'pages/NonWorkingDaysPage/stores/useGeneralVacationsStore';
import { useModalState } from 'hooks';
import { TGeneralVacation } from 'models/GeneralVacation';
import { getYear } from 'date-fns';

import './GeneralVacationsTable.styles.scss';
import { useSearchParams } from 'react-router-dom';
import { SortingOrder } from 'models/types';
import { mapUrlParamsToRequestParams } from './utils/utils';

type GeneralVacationsTableProps = {
  vacationAddedRefreshKey: number;
  urlParamNames: GeneralVacationsUrlParamNames;
};

export type GeneralVacationsUrlParamNames = {
  pageParamName: string;
  yearParamName: string;
  sortByStartDateParamName: string;
};

const initParams = {
  limit: 5,
  page: 1,
  filter: {
    year: getYear(new Date()),
  },
  search: undefined,
  sort: { startDate: SortingOrder.DESCENDING },
};

const minYear = 2000;
const maxYear = getYear(new Date()) + 1;

const GeneralVacationsTable: FC<GeneralVacationsTableProps> = ({
  vacationAddedRefreshKey,
  urlParamNames,
}) => {
  const { vacations, getVacations, pagination, isLoading } = useGeneralVacationsStore();

  const [selectedVacation, setSelectedVacation] = useState<TGeneralVacation | undefined>(undefined);

  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(
    () => ({ ...initParams, ...mapUrlParamsToRequestParams(urlParamNames, searchParams) }),
    [urlParamNames, searchParams],
  );

  const {
    isOpen: editModalOpen,
    openModal: openEditModal,
    closeModal: closeEditModal,
  } = useModalState();

  const handleOpenEditModal = (row: TGeneralVacation) => {
    setSelectedVacation(row);
    openEditModal();
  };

  const handleSortByStartDate = () => {
    searchParams.set(urlParamNames.sortByStartDateParamName, (-params.sort.startDate).toString());
    setSearchParams(searchParams);
  };

  const columns = useGeneralVacationsTableColumns(
    handleOpenEditModal,
    handleSortByStartDate,
    params.sort.startDate,
  );

  useEffect(() => {
    getVacations(params);
  }, [params, vacationAddedRefreshKey]);

  useEffect(() => {
    if (+params.filter.year < minYear)
      searchParams.set(urlParamNames.yearParamName, minYear.toString());
    if (+params.filter.year > maxYear)
      searchParams.set(urlParamNames.yearParamName, maxYear.toString());
    setSearchParams(searchParams);
  }, [searchParams]);

  const onYearChange = (year: number) => {
    searchParams.set(urlParamNames.yearParamName, year.toString());
    searchParams.delete(urlParamNames.pageParamName);
    setSearchParams(searchParams);
  };

  const onPageChange = (page: number) => {
    searchParams.set(urlParamNames.pageParamName, page.toString());
    setSearchParams(searchParams);
  };

  return (
    <SectionBlock
      title='General vacations'
      className='ne-general-vacations'
      loading={isLoading}
      asideFromTitle={
        <YearPicker
          year={Number(params.filter?.year)}
          onYearChange={onYearChange}
          maxYear={maxYear}
          minYear={minYear}
        />
      }
    >
      <div className='ne-general-vacations__table-container'>
        <Table
          data={vacations}
          prepareData={columns}
          noDataMessage='There are no general vacations added.'
        />
      </div>

      {editModalOpen ? (
        <GeneralVacationModal
          onClose={closeEditModal}
          isOpen={editModalOpen}
          onSuccess={() => {
            getVacations(params);
          }}
          generalVacation={selectedVacation}
          isEditMode
        />
      ) : undefined}

      <TablePagination
        totalPages={pagination?.totalPages}
        currentPage={pagination?.currentPage}
        onPageChange={onPageChange}
        totalItems={pagination?.totalItems}
        pageSize={params.limit}
      />
    </SectionBlock>
  );
};

export default GeneralVacationsTable;
