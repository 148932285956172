import { API_PATH } from 'constant';
import { useAxios } from 'hooks';
import { User } from 'models/User';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { generateUrl } from 'utils/generateUrl';
import EmployeeContext from '.';

type EmployeeContextProviderProps = {
  children: React.ReactNode;
};
const EmployeeContextProvider: FC<EmployeeContextProviderProps> = ({ children }) => {
  const [employee, setEmployee] = useState<User>({} as User);
  const { userId = '' } = useParams();
  /**
   * LOAD USER AXIOS
   */
  const { request: fetchEmployeeData, loading } = useAxios<User, any, any>({
    url: generateUrl(`${API_PATH.USERS}/${userId}`),
    method: 'GET',
    onResponse: (response) => {
      const { data: user } = response;
      setEmployee(user);
    },
  });

  const refreshEmployeeData = useCallback(() => {
    if (userId) {
      fetchEmployeeData({});
    }
  }, [userId, fetchEmployeeData]);

  useEffect(() => {
    if (userId) {
      fetchEmployeeData({});
    }
  }, [userId]);

  const updateEmployee = useCallback((employee: Partial<User>) => {
    setEmployee((prev) => ({ ...(prev || ({} as User)), ...employee }));
  }, []);

  const contextValue = useMemo(() => {
    return { employee, updateEmployee, refreshEmployeeData };
  }, [employee, updateEmployee, refreshEmployeeData]);

  return (
    <EmployeeContext.Provider value={contextValue}>
      {loading ? <p>Loading ....</p> : children}
    </EmployeeContext.Provider>
  );
};

export default EmployeeContextProvider;
