import React from 'react';
import './InvitationError.styles.scss';

const InvitationError = () => {
  return (
    <div className='invitation-error'>
      <label className='invitation-error__404'>...4 0 4</label>
      <label className='invitation-error__description'>
        Oops! It seems like your link is invalid or has expired.
      </label>
      <label className='invitation-error__contact'>
        Please contact the administrator for further assistance.
      </label>
    </div>
  );
};

export default InvitationError;
