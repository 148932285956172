import React from 'react';

import { SectionBlock } from 'components/layout/SectionBlock';
import Currencies from './components/Currencies';

import './CurrencySettings.styles.scss';

const CurrencySettings = () => {
  return (
    <SectionBlock className='currency-settings' title='Currency'>
      <p className='currency-settings__description'>
        By default, you have access to three currencies: US Dollar (USD), Serbian Dinar (RSD), and
        Euro (EUR). Setting the default currency is a permanent action. You have the flexibility to
        set and change the secondary currency, and adjust the exchange rate for secondary currency
        according to your needs. This will allow you efficient conversion within the application,
        ensuring accurate and up-to-date financial calculations.
      </p>
      <Currencies />
    </SectionBlock>
  );
};

export default CurrencySettings;
