import { SortIcon, UnsortedIcon } from 'assets';
import { Button } from 'components';
import { TableColumnItem } from 'components/Table/Table.component';
import { DaysOffType } from 'models/DaysOff';
import { SortingOrder } from 'models/types';
import DateService from 'services/Date.service';

export const useDaysOffTableColumns = (
  handleSortByStartDate: () => void,
  sortByStartDate: number,
) => {
  const items: TableColumnItem<DaysOffType>[] = [
    {
      columnLabel: 'Type',
      columnName: 'type',
      prepareCell: (data) => data.type,
    },
    {
      columnLabel: (
        <p className={`start-date-sort-icon-${sortByStartDate}`}>
          Start date
          <Button
            variant='icon'
            type='button'
            onClick={handleSortByStartDate}
            aria-label='Sort by start date'
          >
            {sortByStartDate == SortingOrder.UNSORTED && <UnsortedIcon />}
            {sortByStartDate != SortingOrder.UNSORTED && <SortIcon />}
          </Button>
        </p>
      ),
      columnName: 'start-date',
      prepareCell: (data) => DateService.formatDate(data.startDate),
    },
    {
      columnLabel: 'End date',
      columnName: 'end-date',
      prepareCell: (data) => DateService.formatDate(data.endDate),
    },
    {
      columnLabel: 'Days',
      columnName: 'days',
      prepareCell: (data) => data.workingDays,
    },
    {
      columnLabel: 'Description',
      columnName: 'description',
      prepareCell: (data) => data.description,
    },
    {
      columnLabel: '',
      columnName: 'hidden-column',
      prepareCell: () => '',
    },
  ];
  return items;
};
