import classNames from 'classnames';
import React, { FC, TextareaHTMLAttributes } from 'react';

import './Textarea.styles.scss';

type DefaulTextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;

export type TextareaProps = {
  label?: string;
  errorMessage?: string;
  hasError?: boolean;
  required?: boolean;
} & DefaulTextareaProps;

const Textarea: FC<TextareaProps> = (props: TextareaProps) => {
  const { errorMessage, label, className, hasError, required = false, ...rest } = props;
  const classes = classNames('textarea-container', className);
  return (
    <div className={classes}>
      {label && (
        <label>
          {label}
          {required && <span className='textarea-container__asterix'>*</span>}
        </label>
      )}
      <textarea {...rest} rows={3} />
      {hasError && (
        <small
          className={classNames(
            'ncoded-small',
            hasError && 'ncoded-small--invalid-input',
            'required-message',
          )}
        >
          &nbsp;{errorMessage}
        </small>
      )}
    </div>
  );
};

export default Textarea;
