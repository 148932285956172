import React, { FC } from 'react';

import classNames from 'classnames';

import { APP_URI } from 'config';
import { NavLink } from 'react-router-dom';

import './MenuItem.styles.scss';

type MenuItemProps = {
  icon: React.ReactNode;
  display: string;
  to: string;
  onClick?: () => void;
};

const MenuItem: FC<MenuItemProps> = ({ icon, display, to, onClick }) => {
  return (
    <div
      className={classNames('menu-item', {
        'menu-item--logout': to === APP_URI.login,
      })}
      onClick={onClick}
    >
      <NavLink
        to={to}
        className={({ isActive }) =>
          classNames(['menu-item__element', { 'menu-item__element--active': isActive }])
        }
      >
        <div className='menu-item__icon'>{icon}</div>
        <div className='menu-item__text'>{display}</div>
      </NavLink>
    </div>
  );
};

export default MenuItem;
