/* eslint-disable no-undef */
import { Loader } from 'assets';
import React from 'react';
import ReactDOM from 'react-dom';
import uniqid from 'uniqid';

import './OverlaySpinner.styles.scss';
import { createRoot } from 'react-dom/client';

export default {
  show: (
    parent: HTMLElement | string,
    position?: 'center' | 'start' | 'end' | 'fixed',
    element: JSX.Element = <Loader className='overlay__loader' />,
  ) => {
    let actualParent;

    if (typeof parent === 'string') {
      actualParent = document.querySelector(parent);
    } else {
      actualParent = parent;
    }

    if (!actualParent) {
      return;
    }

    const overlay = document.createElement('div');
    overlay.classList.add('overlay');
    position && overlay.classList.add(`position-${position}`);

    const overlaySpinnerId = actualParent?.getAttribute('overlay-id') || uniqid();
    overlay.classList.add(overlaySpinnerId);

    if (actualParent) {
      actualParent.setAttribute('overlay-id', overlaySpinnerId);
      actualParent.classList.add('position-relative');
      actualParent.appendChild(overlay);

      // New approach using createRoot
      const root = createRoot(overlay); // Create a root for the overlay.
      root.render(element); // Use the root to render the element.
    }
  },
  hide: (parent: HTMLElement | string) => {
    let actualParent;

    if (typeof parent === 'string') {
      actualParent = document.querySelector(parent);
    } else {
      actualParent = parent;
    }

    if (actualParent) {
      const parentOverlaySpinnerId = actualParent.getAttribute('overlay-id');

      if (parentOverlaySpinnerId) {
        const overlay = document.querySelector(`.${parentOverlaySpinnerId}`);

        actualParent.classList.remove('position-relative');

        overlay && actualParent.removeChild(overlay);
      }
    }
  },
};
