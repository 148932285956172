import classNames from 'classnames';
import React, { FC, useMemo } from 'react';

import { Loader } from 'components';

import './Button.styles.scss';

type DefaultButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export type ButtonProps = {
  loading?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'solid' | 'outline' | 'link' | 'icon' | 'error' | 'success' | 'text';
  icon?: React.ReactNode;
  onClick?: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  hoverText?: string;
} & DefaultButtonProps;

const Button: FC<ButtonProps> = ({
  loading,
  type,
  variant = 'solid',
  className,
  onClick,
  children,
  disabled,
  icon,
  hoverText,
  ...rest
}) => {
  const buttonClassNames = useMemo(
    () =>
      classNames([
        'ncoded-employee-button',
        { 'ncoded-employee-button--disabled': loading || disabled },
        `ncoded-employee-button--${variant}`,
        className,
      ]),
    [className, disabled, loading, variant],
  );

  return (
    <button
      onClick={onClick}
      type={type}
      className={buttonClassNames}
      disabled={disabled || loading}
      title={hoverText}
      {...rest}
    >
      {icon}
      {loading ? <Loader /> : children}
    </button>
  );
};

export default Button;
