import { TimeOffsParams, TimeOffStatus, TimeOffsUrlParamNames } from '../TimeOffsTable.component';

export const mapUrlParamsToRequestParams = (
  urlParamsNames: TimeOffsUrlParamNames,
  searchParams: URLSearchParams,
): Partial<TimeOffsParams> => {
  const {
    pageParamName,
    searchParamName,
    statusParamName,
    sortByRequestParamName,
    sortByStartDateParamName,
  } = urlParamsNames;

  const page = +(searchParams.get(pageParamName) || '');
  const search = searchParamName && searchParams.get(searchParamName);
  const status = statusParamName && searchParams.get(statusParamName);
  const sortByDate = +(searchParams.get(sortByStartDateParamName) || '');
  const sortByRequest = +(searchParams.get(sortByRequestParamName) || '');

  return {
    ...(page && { page }),
    ...(search && { search }),
    ...(status && { filter: { status: status as TimeOffStatus } }),
    ...(status === 'All' && { filter: {} }),
    ...((sortByDate || sortByRequest) && {
      sort: {
        ...(sortByDate && { startDate: sortByDate }),
        ...(sortByRequest && { createdAt: sortByRequest }),
      },
    }),
  };
};
