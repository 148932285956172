import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { Button, FieldWrapper, Input, LazyImage, Link } from 'components';
import { APP_URI } from 'config';
import { API_PATH } from 'constant';
import { useAxios } from 'hooks';
import { snackbar } from 'modules';
import { LinkVariant } from 'models/types';
import { EMAIL_REGEX, MSG_EMAIL_INVALID, MSG_REQUIRED } from 'validation';

import { InputProps } from 'components/Input/Input.component';

import './PasswordRecovery.styles.scss';

const validation = Yup.object().shape({
  email: Yup.string()
    .email(MSG_EMAIL_INVALID)
    .required(MSG_REQUIRED)
    .matches(EMAIL_REGEX, MSG_EMAIL_INVALID),
});

type IPasswordRecovery = {
  email: string;
};

type IResponse = { message: string };
type IError = { message: string; error: string; statusCode: number };

const PasswordRecovery = () => {
  const methods = useForm<IPasswordRecovery>({
    mode: 'onChange',
    resolver: yupResolver(validation),
    defaultValues: { email: '' },
  });
  const { setError, loading, request } = useAxios<IResponse, IError>({
    url: API_PATH.FORGOT_PASSWORD,
    method: 'POST',
    onResponse: () => {
      snackbar.show({
        message: 'Email sent with reset password link',
        type: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
      });
    },
    onError: (responseParam) => {
      if (responseParam.response?.status === 404) {
        snackbar.show({
          message: 'In order to change password, you need to register first',
          type: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        });
      } else {
        methods.setError('email', { message: 'Email not found' });
        setError(undefined);
      }
    },
  });

  const handleSubmit = ({ email }: IPasswordRecovery) => {
    request({ payload: { email } });
  };

  return (
    <div className='password-recovery'>
      <div className='password-recovery__container'>
        <div className='password-recovery__wrapper'>
          <LazyImage
            src='images/nlogo.png'
            alt='ncoded-logo'
            className='password-recovery__header__logo'
          />
          <div className='password-recovery__header'>
            <h1 className='password-recovery__header__title'>Forgot your password?</h1>
          </div>
          <p className='password-recovery__text'>
            Enter the email associated to your account. You will get an email containing the link to
            password recovery.
          </p>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)} className='password-recovery__form'>
              <FieldWrapper<InputProps>
                fieldComponent={Input}
                name='email'
                type='email'
                placeholder='Email...'
                className='password-recovery__email'
              />
              <span className='password-recovery__back-to-login'>
                Remember password? &nbsp;
                <Link
                  text='Login'
                  to={APP_URI.login}
                  variant={LinkVariant.Text}
                  className='password-recovery__back-to-login__link'
                />
              </span>
              <Button
                className='password-recovery__button'
                disabled={!methods.formState.isValid}
                loading={loading}
              >
                <span>Request password reset</span>
              </Button>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default PasswordRecovery;
