import React, { FC } from 'react';
import { Modal } from 'components/Modal';
import { Button } from 'components/Button';

import './LogoutModal.styles.scss';

type LogoutModalProps = {
  onRequestClose: () => void;
  isOpen: boolean;
  onLogout: () => void;
};

const LogoutModal: FC<LogoutModalProps> = ({ onRequestClose, isOpen, onLogout }) => {
  return (
    <Modal onRequestClose={onRequestClose} isOpen={isOpen} hasCloseIcon>
      <div className='logout-modal'>
        <div className='logout-modal__title'>
          <h1>Are you sure you want to log out?</h1>
        </div>
        <div className='logout-modal__body'>
          <p>If you continue, you will be logged out of your account and redirected to Login.</p>
        </div>
        <div className='logout-modal__footer'>
          <Button onClick={onRequestClose} className='logout-modal__footer__button__cancel'>
            Cancel
          </Button>
          <Button onClick={onLogout} className='logout-modal__footer__button'>
            Log out
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default LogoutModal;
