import api from 'api';
import { AddAdminPayload } from 'api/admin/roles';
import { User } from 'models/User';
import { Pagination } from 'models/types';
import parseAxiosError from 'utils/parseAxiosError';
import { create } from 'zustand';

interface AdminsState {
  isLoading: boolean;
  admins: User[];
  error?: string;
  pagination: Pagination;
  getAdmins: (page: number) => void;
  addAdmin: (
    newAdmin: AddAdminPayload,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void,
  ) => void;
  inviteAdmin: (
    adminId: string,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void,
  ) => Promise<void>;
  deleteAdminInvitation: (
    adminId: string,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void,
  ) => Promise<void>;
}

const useAdminsStore = create<AdminsState>()((set, get) => ({
  isLoading: false,
  admins: [],
  pagination: { totalItems: 0, totalPages: 0, currentPage: 1, pageSize: 7 },

  getAdmins: async (page: number) => {
    try {
      set({ isLoading: true });
      const { data } = await api.admin.roles.getAdmins({
        page,
        limit: 7,
        sort: { role: -1, inviteStatus: 1, inviteAcceptedAt: -1, invitedAt: -1 },
      });
      const { items, ...pagination } = data;
      set({ isLoading: false, admins: items, pagination });
    } catch (err) {
      set({ isLoading: false });
    }
  },

  addAdmin: async (
    newAdmin: AddAdminPayload,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void,
  ) => {
    try {
      await api.admin.roles.addAmin(newAdmin);
      get().getAdmins(1);
      onSuccess?.();
    } catch (err: any) {
      const errorMessage =
        err.response.status === 409
          ? 'User with entered email already exists.'
          : parseAxiosError(err);
      onError?.(errorMessage);
    }
  },
  inviteAdmin: async (
    adminId: string,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void,
  ) => {
    try {
      await api.admin.roles.inviteAdmin(adminId);
      onSuccess?.();
    } catch (err: any) {
      const errorMessage = parseAxiosError(err);
      onError?.(errorMessage);
    }
  },
  deleteAdminInvitation: async (
    adminId: string,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void,
  ) => {
    try {
      await api.admin.roles.deleteAdminInvitation(adminId);
      get().getAdmins(1);
      onSuccess?.();
    } catch (err: any) {
      const errorMessage = parseAxiosError(err);
      onError?.(errorMessage);
    }
  },
}));

export default useAdminsStore;
