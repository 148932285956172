import { Button, Modal } from 'components';
import { ModalProps } from 'components/Modal/Modal.component';
import React from 'react';

import './RemoveMultiplierModal.styles.scss';

const RemoveMultiplierModal: React.FC<ModalProps & { onSubmit: () => void; loading: boolean }> = ({
  onSubmit,
  loading,
  ...modalProps
}) => {
  return (
    <Modal {...modalProps} className='ne-remove-document-modal' hasCloseIcon>
      <div className='ne-remove-document-modal__content'>
        <h2>Remove multiplier</h2>
        <p>Are you sure you want to remove this multiplier?</p>
        <Button onClick={onSubmit} loading={loading}>
          Remove
        </Button>
      </div>
    </Modal>
  );
};

export default RemoveMultiplierModal;
