import React from 'react';
import { LazyImage } from 'components';

import './Error404Page.styles.scss';

const Error404Page = () => {
  return (
    <div className='error-page'>
      <LazyImage src='/images/nlogo.png' alt='logo' className='error-page__image' />
      <div className='error-page__description'>
        <h1>404 | Page not found.</h1>
        <h2>This page could not be found or you do not have access to it.</h2>
      </div>
    </div>
  );
};

export default Error404Page;
