import api from 'api';
import { Pagination, SortingOrder } from 'models/types';
import { create } from 'zustand';
import { TEmployeeVacationsParams } from '../types/TEmployeeVacationsParams';
import { EmployeeNonWorkingDays } from 'models/NonWorkingDays';
import { mapUserToNonWorkingDays } from '../utils/utils';
import parseAxiosError from 'utils/parseAxiosError';
import { EditNonWorkingDaysValue } from 'components/EditNonWorkingDays/EditNonWorkingDays.component';

interface GeneralVacationsState {
  isLoading: boolean;
  vacations: EmployeeNonWorkingDays[];
  pagination: Pagination;

  error?: string;
  getVacations: (params: TEmployeeVacationsParams) => void;

  updateNonWorkingDays: (
    employeeId: string,
    nonWorkingDays: EditNonWorkingDaysValue,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void,
  ) => Promise<void>;
}

const useEmployeeVacationsStore = create<GeneralVacationsState>()((set, get) => ({
  isLoading: false,
  isRemovingDocument: false,
  isUpdatingDocument: false,
  vacations: [],

  pagination: {
    totalItems: 0,
    currentPage: 0,
    totalPages: 0,
    pageSize: 5,
  },
  getVacations: async (params: TEmployeeVacationsParams) => {
    try {
      set({ isLoading: true });

      const { data } = await api.admin.generalVacations.getEmployeesVacations(params);
      const { items, ...pagination } = data;
      set({
        isLoading: false,
        vacations: items.map((item) => mapUserToNonWorkingDays(item)),
        pagination,
      });
    } catch (err) {
      set({ isLoading: false });
    }
  },

  updateNonWorkingDays: async (
    employeeId: string,
    nonWorkingDays: EditNonWorkingDaysValue,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void,
  ) => {
    const state = get();
    try {
      const { data: updateRecord } = await api.admin.nonWorkingDays.updateEmployeeNonWorkingDays(
        employeeId,
        nonWorkingDays.currentYear,
        nonWorkingDays.previousYear,
      );
      set({
        vacations: state.vacations.map((item) =>
          item.employeeId === employeeId ? mapUserToNonWorkingDays(updateRecord) : item,
        ),
      });
      onSuccess?.();
    } catch (err) {
      const errorMessage = parseAxiosError(err as any);
      onError?.(errorMessage);
    }
  },
}));

export default useEmployeeVacationsStore;
