import React from 'react';
import { PageLayout } from 'components/layout/PageLayout';
import { PageHeader } from 'components/layout/PageHeader';
import MulitpliersSettings from './components/MulitpliersSettings';
import { Tabs } from 'components';
import CurrencySettings from './components/CurrencySettings';

import './SettingsPage.styles.scss';

const tabsData = [
  { label: <div>Multipliers</div>, content: <MulitpliersSettings />, id: 'multipliers' },
  {
    label: <div>Currency and Language</div>,
    content: <CurrencySettings />,
    id: 'currency-and-language',
  },
];

const SettingsPage = () => {
  return (
    <PageLayout>
      <PageHeader title='Settings' />
      <Tabs tabs={tabsData} />
    </PageLayout>
  );
};
export default SettingsPage;
