type IUploadPicture = {
  blobURL: string;
  name: string;
  type: string;
};

const uploadPicture = async (
  { blobURL, name, type }: IUploadPicture,
  sendRequest: (payload: any) => Promise<void>,
) => {
  const blob = await fetch(blobURL).then((res) => res.blob());
  const file = new File([blob], name, {
    type: type,
  });
  const formData = new FormData();
  formData.append('file', file);
  await sendRequest({ payload: formData });
};

export { uploadPicture };
