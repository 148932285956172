import Input, { InputProps } from 'components/Input/Input.component';
import React, { FC } from 'react';
import { formatNumberWithCommas, removeCommas } from 'utils/formatNumber';

export type FormattedInputProps = Omit<InputProps, 'onChange'> & {
  onChange: (value: string) => void;
};

const FormattedNumberInput: FC<FormattedInputProps> = (props) => {
  const { value: rawValue, onChange, ...rest } = props;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    // Remove commas to get the raw numeric value
    const numericValue = removeCommas(inputValue);

    // Check if the numeric value is valid
    if (!/^\d*\.?\d*$/.test(numericValue)) {
      return; // If the input is invalid, don't update the state
    }

    // Update the raw value and the display value with commas
    onChange(numericValue);
  };

  return (
    <Input
      value={formatNumberWithCommas(rawValue?.toString() || '')}
      onChange={handleInputChange}
      {...rest}
    />
  );
};

export default FormattedNumberInput;
