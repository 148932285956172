import { Button } from 'components/Button';

import { FieldWrapper } from 'components/FieldWrapper';
import Input, { InputProps } from 'components/Input/Input.component';
import { NonWorkingDays } from 'models/NonWorkingDays';
import React, { FC, JSX } from 'react';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import ProgressCircle from 'components/ProgressCircle/ProgressCircle.component';
import { EditNonWorkingDaysValue } from 'components/EditNonWorkingDays/EditNonWorkingDays.component';

import './EditNonWorkingDaysForm.styles.scss';
import classNames from 'classnames';

type EditNonWorkingDaysFormProps = {
  employee: string | JSX.Element;
  nonWorkingDays: NonWorkingDays;
  isLoading?: boolean;
  onSubmit: (noOfDays: EditNonWorkingDaysValue) => void;
  onCancelButtonClicked: () => void;
  methods: UseFormReturn<EditNonWorkingDaysValue, any, undefined>;
  variant?: 'modal' | 'standard';
  isEditMode?: boolean;
  isContentOpen?: boolean;
  defaultValues: Partial<EditNonWorkingDaysValue>;
};

const EditNonWorkingDaysForm: FC<EditNonWorkingDaysFormProps> = (props) => {
  const {
    employee,
    nonWorkingDays,
    isLoading,
    onSubmit,
    methods,
    onCancelButtonClicked,
    variant = 'modal',
    isEditMode = variant === 'standard' ? true : false,
    isContentOpen = true,
  } = props;

  const {
    vacationDaysCurrentYear,
    vacationDaysPreviousYear,
    usedVacationDaysCurrentYear,
    usedVacationDaysPreviousYear,
  } = nonWorkingDays;
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} name='formNonWorkingDays'>
        <div className={classNames('edit-nwd-form', `edit-nwd-form--${variant}`)}>
          <h1>{employee}</h1>
          {isContentOpen && (
            <ul>
              <li>
                <p>Vacation days</p>
                <b>{vacationDaysCurrentYear + vacationDaysPreviousYear}</b>
                <div className={`edit-nwd-form__total`}>
                  <div className={`edit-nwd-form__total__year`}>
                    <div className={`edit-nwd-form__total__days`}>
                      <FieldWrapper<InputProps>
                        name='currentYear'
                        fieldComponent={Input}
                        label='Current year:'
                        min={0}
                        disabled={variant === 'standard' && !isEditMode}
                        type='number'
                      />
                    </div>
                  </div>
                  <div className={`edit-nwd-form__total__year`}>
                    <div className={`edit-nwd-form__total__days`}>
                      <FieldWrapper<InputProps>
                        name='previousYear'
                        fieldComponent={Input}
                        label='Previous year:'
                        min={0}
                        disabled={variant === 'standard' && !isEditMode}
                        type='number'
                      />
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <p>Used days</p>
                <b>{usedVacationDaysCurrentYear + usedVacationDaysPreviousYear}</b>
              </li>
              <li>
                <p>Remaining days</p>
                <b>{nonWorkingDays.totalVacationDaysLeft}</b>
                <div className={`edit-nwd-form__remaining`}>
                  <div className={`edit-nwd-form__remaining__year`}>
                    Current year:
                    <div className={`edit-nwd-form__remaining__days`}>
                      {nonWorkingDays.vacationDaysLeftCurrentYear}
                    </div>
                  </div>
                  <div className={`edit-nwd-form__remaining__year`}>
                    Previous year:
                    <div className={`edit-nwd-form__remaining__days`}>
                      {nonWorkingDays.vacationDaysLeftPreviousYear}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          )}

          {isContentOpen && (
            <div className={`edit-nwd-form__progress-circle`}>
              <ProgressCircle
                totalDays={vacationDaysCurrentYear + vacationDaysPreviousYear}
                daysLeft={nonWorkingDays.totalVacationDaysLeft}
              />
            </div>
          )}

          {isContentOpen && (
            <div className={`edit-nwd-form__actions-wrapper`}>
              {(variant === 'modal' || isEditMode) && (
                <Button
                  type='button'
                  onClick={() => {
                    methods.reset();
                    onCancelButtonClicked?.();
                  }}
                  variant='outline'
                  className={`edit-nwd-form--${variant}__actions-wrapper__cancel`}
                >
                  Cancel
                </Button>
              )}
              {(variant === 'modal' || isEditMode) && (
                <Button
                  type='submit'
                  loading={isLoading}
                  disabled={!methods.formState.isDirty}
                  className={`edit-nwd-form--${variant}__actions-wrapper__save`}
                >
                  Save
                </Button>
              )}
            </div>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default EditNonWorkingDaysForm;
