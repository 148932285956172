export function formatNumber(value: number, numOfDecimals?: number) {
  return value
    .toFixed(numOfDecimals)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const isDecimalNumberFormat = (value: string): boolean => {
  if (value === '') return true;
  return /^(([0-9][0-9]*))(\.\d*)?$/.test(value);
};

// Function to format a number string with commas
export const formatNumberWithCommas = (value: string) => {
  const parts = value.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

// Function to remove commas from the formatted number
export const removeCommas = (value: string) => value.replace(/,/g, '');
