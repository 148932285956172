import { yupResolver } from '@hookform/resolvers/yup';
import noImagePlaceholder from 'assets/No-Image-Placeholder.svg.png';
import { Button } from 'components/Button';
import { UploadFile } from 'components/Dropzone';
import { FieldWrapper } from 'components/FieldWrapper';
import ImageUpload, { ImageUploadProps } from 'components/ImageUpload/ImageUpload.component';
import Input, { InputProps } from 'components/Input/Input.component';
import { API_PATH, MB_TO_BYTE } from 'constant';
import { useAuth } from 'context';
import { useAxios } from 'hooks';
import { User } from 'models/User';
import { snackbar } from 'modules';
import { uploadPicture } from 'pages/ProfilePage/utils/uploadProfilePicture';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DropzoneOptions } from 'react-dropzone';
import { FormProvider, useForm } from 'react-hook-form';
import { MSG_REQUIRED, NAME_REGEX, PHONE_REGEX_381, PHONE_REGEX_NORMAL } from 'validation';
import {
  MSG_NAME_INVALID,
  MSG_PERSON_ID,
  MSG_PHONE_NUMBER,
  MSG_PHONE_NUMBER_DUPLICATE,
  MSG_SUCCESSFULLY_UPDATED,
} from 'validation/messages';
import * as Yup from 'yup';
import { DatePicker } from 'components';
import { PERSONAL_ID_REGEX } from 'validation/regex';
import DateService from 'services/Date.service';
import './PersonalInformation.styles.scss';

const dropzoneConfig: DropzoneOptions = {
  maxFiles: 1,
  multiple: false,
  maxSize: 5 * MB_TO_BYTE,
  accept: {
    'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
  },
};

type PersonalInformationForm = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  profilePicture: UploadFile[];
  birthDate?: Date | string;
  address?: string;
  personalId?: string;
};

export const customResolver = (schema: any) => {
  return (values: any, context: any, options: any) => {
    return yupResolver(schema)(values, context, options);
  };
};

const validation = Yup.object().shape({
  firstName: Yup.string().required(MSG_REQUIRED).matches(NAME_REGEX, MSG_NAME_INVALID).max(32),
  lastName: Yup.string().required(MSG_REQUIRED).matches(NAME_REGEX, MSG_NAME_INVALID).max(32),
  email: Yup.string(),
  phoneNumber: Yup.string().test('phoneNumber', MSG_PHONE_NUMBER, (value) => {
    if (!value) return true;
    return PHONE_REGEX_381.test(value) || PHONE_REGEX_NORMAL.test(value);
  }),
  personalId: Yup.string().test('personalId', MSG_PERSON_ID, (value) => {
    if (!value) return true;
    return PERSONAL_ID_REGEX.test(value);
  }),
});

const initialValues = (user: User) => ({
  firstName: user?.firstName || '',
  lastName: user?.lastName || '',
  email: user?.email || '',
  phoneNumber: user?.phoneNumber?.refferenceNumber.concat(user?.phoneNumber.number) || '',
  address: user?.address || '',
  profilePicture: [
    {
      blobURL: user?.profileImageUrl || noImagePlaceholder,
      name: user?.profileImageKey?.originalImage || 'missingImage',
      type: 'image/*',
    },
  ],
  birthDate: user?.birthDate && DateService.formatDate(user?.birthDate, 'yyyy-MM-dd'),
  personalId: user?.personalId,
});

const PersonalInformation = () => {
  const { user, triggerRefetch } = useAuth();
  const methods = useForm<PersonalInformationForm>({
    mode: 'onChange',
    defaultValues: initialValues(user!),
    resolver: customResolver(validation),
  });

  const { request: updateUser, loading: loadingUpdate } = useAxios({
    url: API_PATH.USERS,
    method: 'PATCH',
    onResponse() {
      triggerRefetch();
      snackbar.show({ message: MSG_SUCCESSFULLY_UPDATED, type: 'success' });
    },
    onError: (error) => {
      if ((error.response?.data as any).message.includes('Duplicate')) {
        snackbar.show({ message: MSG_PHONE_NUMBER_DUPLICATE, type: 'error' });
      } else {
        snackbar.show({
          message: error.message,
          type: 'error',
        });
      }
      methods.reset(initialValues(user!));
    },
  });

  const { loading: loadingProfilePicture, request: sendUploadPictureRequest } = useAxios({
    url: API_PATH.UPLOAD_USER_IMAGE,
    method: 'POST',
    onResponse: () => {
      triggerRefetch();
    },
    onError: () => {
      methods.resetField('profilePicture');
    },
  });

  const { loading: loadingRemovePicture, request: sendRemovePictureRequest } = useAxios({
    url: API_PATH.REMOVE_USER_IMAGE,
    method: 'POST',
    onResponse: () => {
      triggerRefetch();
    },
    onError: () => {
      methods.resetField('profilePicture');
    },
  });

  const handleSubmit = useCallback(
    (data: PersonalInformationForm) => {
      if (
        data.profilePicture[0].blobURL !== noImagePlaceholder &&
        data.profilePicture[0].blobURL !== user?.profileImageKey?.originalImage &&
        methods.getFieldState('profilePicture').isDirty
      ) {
        const { blobURL, name, type } = data.profilePicture[0];

        uploadPicture({ blobURL, name, type }, sendUploadPictureRequest);
      }
      if (
        user?.profileImageKey?.originalImage &&
        data.profilePicture[0].blobURL === noImagePlaceholder
      ) {
        sendRemovePictureRequest({});
      }
      if (
        methods.getFieldState('firstName').isDirty ||
        methods.getFieldState('lastName').isDirty ||
        methods.getFieldState('phoneNumber').isDirty ||
        methods.getFieldState('birthDate').isDirty ||
        methods.getFieldState('address').isDirty ||
        methods.getFieldState('birthDate').isDirty ||
        methods.getFieldState('personalId').isDirty
      ) {
        updateUser({
          payload: {
            firstName: data.firstName,
            lastName: data.lastName,
            birthDate: data.birthDate && DateService.formatDate(data.birthDate, 'yyyy-MM-dd'),
            personalId: data.personalId,
            address: data.address ? data.address : null,
            phoneNumber: data.phoneNumber
              ? {
                  refferenceNumber: '+381',
                  number: data.phoneNumber?.startsWith('+381')
                    ? data.phoneNumber.substring(4, data.phoneNumber.length)
                    : data.phoneNumber.substring(1, data.phoneNumber.length),
                }
              : null,
          },
        });
      }
      methods.reset({
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        birthDate: data.birthDate,
        address: data.address,
        personalId: data.personalId,
        profilePicture: [
          {
            blobURL: data.profilePicture[0].blobURL,
            name: data.profilePicture[0].name || 'missingImage',
            type: data.profilePicture[0].type,
          },
        ],
      });
    },
    [
      methods,
      sendRemovePictureRequest,
      sendUploadPictureRequest,
      updateUser,
      user?.profileImageKey,
    ],
  );

  const submitDisabled = useMemo(() => {
    return (
      !methods.formState.isValid || !methods.formState.isDirty || !!methods.formState.errors.root
    );
  }, [methods.formState]);

  useEffect(() => {
    methods.reset(initialValues(user!));
  }, [user]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)} className='personal-info' key={user?.id}>
        <div className='personal-info__image-div'>
          <FieldWrapper<ImageUploadProps>
            fieldComponent={ImageUpload}
            name='profilePicture'
            isProfilePicture={true}
            isEdit={true}
            dropzoneOptions={dropzoneConfig}
            onError={() => {
              methods.setError('root', {
                type: 'validate',
                message: 'Please provide adequate picture.',
              });
            }}
            onAccept={() => {
              methods.clearErrors('root');
            }}
            isDirty={methods.getFieldState('profilePicture').isDirty}
          />
          <p>
            <span>Tip:</span> Choose an image where your face is recognizable - .JPG .PNG (Max 5MB)
          </p>
        </div>
        <div className='personal-info__form'>
          <div className='personal-info__wrapper'>
            <FieldWrapper<InputProps>
              fieldComponent={Input}
              name='firstName'
              label='First name'
              asterix
            />
            <FieldWrapper<InputProps>
              fieldComponent={Input}
              name='lastName'
              label='Last name'
              asterix
            />
            <FieldWrapper<InputProps>
              fieldComponent={Input}
              name='email'
              label='Email'
              disabled
              asterix
            />
            <FieldWrapper<InputProps>
              fieldComponent={Input}
              name='phoneNumber'
              label='Phone number'
            />
            <FieldWrapper<InputProps> fieldComponent={Input} name='address' label='Address' />
            <FieldWrapper<any>
              fieldComponent={DatePicker}
              name='birthDate'
              label='Date of birth'
              minDate={new Date('January 1, 1900 00:00:00')}
              showYear
            />
            <FieldWrapper<InputProps> fieldComponent={Input} name='personalId' label='JMBG' />
          </div>
          <Button
            type='submit'
            loading={loadingUpdate || loadingProfilePicture || loadingRemovePicture}
            disabled={submitDisabled}
          >
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default PersonalInformation;
