import React, { FC, useEffect, useMemo, useState } from 'react';

import { getYear } from 'date-fns';
import { SectionBlock } from 'components/layout/SectionBlock';
import Table from 'components/Table';
import TablePagination from 'components/TablePagination';
import usePaginatedData from 'hooks/usePaginatedData';
import { DaysOffType } from 'models/DaysOff';
import { YearPicker } from 'components';
import { useDaysOffTableColumns } from 'pages/DaysOffPage/hooks/useDaysOffTableColumns';
import { toggleSortingOrder } from 'utils/toggleSortingOrder';
import { DaysOffUrlParamNames } from 'pages/DaysOffPage/types/DaysOffUrlParams';
import { useSearchParams } from 'react-router-dom';
import { mapUrlParamsToRequestParams } from 'pages/DaysOffPage/utils/utils';

import './DaysOffTable.styles.scss';

type DaysOffProps = {
  startYear?: number;
  urlParamNames: DaysOffUrlParamNames;
};

const DaysOffTable: FC<DaysOffProps> = ({ startYear, urlParamNames }) => {
  const {
    data: userDaysOff,
    loading: loadingDaysOff,
    pagination,
    params,
    getData,
  } = usePaginatedData<DaysOffType>(
    { sort: { startDate: -1 }, limit: 10, page: 1, filter: { year: getYear(new Date()) } },
    '/general-vacations',
  );
  const minYear = startYear;
  const maxYear = getYear(new Date()) + 1;

  const [searchParams, setSearchParams] = useSearchParams();

  const urlParams = useMemo(
    () => mapUrlParamsToRequestParams(urlParamNames, searchParams),
    [searchParams],
  );

  useEffect(() => {
    getData({ ...params, ...urlParams });
  }, [urlParams]);

  const handleSortByStartDate = () => {
    searchParams.set(
      urlParamNames.sortByStartDateParamName,
      toggleSortingOrder(urlParams.sort?.startDate || 0).toString(),
    );
    setSearchParams(searchParams);
  };

  const handlePageChanged = (page: number) => {
    searchParams.set(urlParamNames.pageParamName, page.toString());
    setSearchParams(searchParams);
  };

  const handleYearChanged = (year: number) => {
    searchParams.set(urlParamNames.yearParamName, year.toString());
    searchParams.set(urlParamNames.pageParamName, '1');
    setSearchParams(searchParams);
  };

  const daysOffTableColumns = useDaysOffTableColumns(
    handleSortByStartDate,
    urlParams?.sort?.startDate || 0,
  );

  useEffect(() => {
    if (minYear && params.filter && +params?.filter.year < minYear)
      searchParams.set(urlParamNames.yearParamName, minYear.toString());
    if (params.filter && +params.filter.year > maxYear)
      searchParams.set(urlParamNames.yearParamName, maxYear.toString());
    setSearchParams(searchParams);
  }, [searchParams]);

  return (
    <SectionBlock
      title='Days off'
      loading={loadingDaysOff}
      className='days-off-table'
      asideFromTitle={
        <YearPicker
          year={urlParams?.filter?.year || getYear(new Date())}
          onYearChange={handleYearChanged}
          maxYear={maxYear}
          minYear={minYear}
        />
      }
    >
      <div className='days-off-table__table-container'>
        <Table
          data={userDaysOff}
          prepareData={daysOffTableColumns}
          noDataMessage="Here, you'll find general vacations, including holidays and collective breaks, added by your admin."
        />
      </div>

      {pagination && (
        <TablePagination
          totalPages={pagination?.totalPages}
          currentPage={pagination?.currentPage}
          totalItems={pagination?.totalItems}
          pageSize={pagination?.pageSize}
          onPageChange={handlePageChanged}
        />
      )}
    </SectionBlock>
  );
};

export default DaysOffTable;
