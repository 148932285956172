export const generateUrl = (
  url: string,
  params?: Record<string, string | number | string[] | Record<string, string | number>>,
): string => {
  if (!params) return url;

  const formattedQuery = Object.entries(params)
    .map(([queryName, queryValue]) => {
      const formattedQueryValue =
        typeof queryValue === 'string'
          ? queryValue
          : queryValue instanceof Array
            ? queryValue.join('%20')
            : JSON.stringify(queryValue);

      return `${queryName}=${formattedQueryValue}`;
    })
    .join('&')
    .replace('{', '%7B')
    .replace('}', '%7D');

  return `${url}/?${formattedQuery}`;
};
