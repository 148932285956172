import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SUPPORTED_CURRENCIES } from 'pages/SettingsPage/consts/currency';
import SelectV2 from 'components/SelectV2';
import { Button, Loader, Modal } from 'components';
import { PenIcon } from 'assets';
import { useModalState } from 'hooks';
import ExchangeRateForm from './components/ExchangeRateForm';
import useCurrenciesStore from 'store/useCurrenciesStore';
import { Currency } from 'models/Currency';
import { snackbar } from 'modules';
import { ExchangeFormValue } from './components/ExchangeRateForm/ExchangeRateForm.component';

import './Currencies.styles.scss';

const Currencies = () => {
  const { currencies, updateCurrencies, getCurrencies, isUpdateInProgress, isLoading } =
    useCurrenciesStore();
  const { defaultCurrency, secondaryCurrency, rate } = currencies || {};

  const handleSecondaryCurrencySelected = useCallback(
    (secondaryCurrency: Currency) => {
      setIsSecondaryCurrencyUpdating(true);
      updateCurrencies(
        { secondaryCurrency, defaultCurrency, rate: 0 },
        () => {
          setIsSecondaryCurrencyUpdating(false);
          snackbar.show({
            message: 'You have successfully set secondary currency.',
            type: 'success',
          });
        },
        (error: string) => {
          snackbar.show({ message: error, type: 'error' });
          setIsSecondaryCurrencyUpdating(false);
        },
      );
    },
    [defaultCurrency, updateCurrencies],
  );

  const handleExchangeFormSubmitted = useCallback(
    (values: ExchangeFormValue) => {
      const { secondaryCurrency, secondaryCurrencyRate, defaultCurrencyRate } = values;

      updateCurrencies(
        {
          secondaryCurrency: secondaryCurrency as Currency,
          defaultCurrency,
          rate: parseFloat(secondaryCurrencyRate) / parseFloat(defaultCurrencyRate),
        },
        () => {
          snackbar.show({
            message: 'You have successfully changed currency settings.',
            type: 'success',
          });
          closeModal();
        },
        (error: string) => {
          snackbar.show({ message: error, type: 'error' });
        },
      );
    },
    [defaultCurrency, updateCurrencies],
  );

  const secondaryCurrencyOptions = useMemo(() => {
    return SUPPORTED_CURRENCIES.filter((currency) => currency.value !== defaultCurrency);
  }, [defaultCurrency]);

  const defaultCurrencyLabel = useMemo(
    () =>
      SUPPORTED_CURRENCIES.find((currency) => currency.value === defaultCurrency)?.label ||
      'Currency not recognized',
    [defaultCurrency],
  );

  const secondaryCurrencyLabel = useMemo(
    () =>
      SUPPORTED_CURRENCIES.find((currency) => currency.value === secondaryCurrency)?.label ||
      'Currency not recognized',
    [secondaryCurrency],
  );

  const [isSecondaryCurrencyUpdating, setIsSecondaryCurrencyUpdating] = useState(false);

  const { isOpen, closeModal, openModal } = useModalState();

  useEffect(() => {
    if (!currencies) getCurrencies();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div className='currencies'>
      <div className='currencies__overview'>
        <div className='currencies__default-currency'>
          <label>Default currency</label>
          <div>{defaultCurrencyLabel}</div>
        </div>
        <div
          className={
            !secondaryCurrency ? 'currencies__secondary-currency' : 'currencies__default-currency'
          }
        >
          <label>Secondary currency</label>
          {!secondaryCurrency ? (
            isSecondaryCurrencyUpdating ? (
              <div className='currencies__secondary-loading-wrapper'>
                <Loader className='currencies__secondary-loading' />{' '}
              </div>
            ) : (
              <SelectV2
                value={secondaryCurrency || ''}
                onChange={(value) => handleSecondaryCurrencySelected(value.toString() as Currency)}
                options={secondaryCurrencyOptions}
                placeholder='Choose currency'
              />
            )
          ) : (
            <div>{secondaryCurrencyLabel}</div>
          )}
        </div>
      </div>
      {secondaryCurrency && (
        <div className='currencies__exchange-rate'>
          <div className='currencies__exchange-rate-title'>
            <label>Currency/Exchange (middle) rate</label>
            <Button type='button' variant='icon' onClick={openModal} aria-label='edit'>
              <PenIcon />
            </Button>
          </div>
          <table className='currencies__exchange-rate-content'>
            <thead>
              <tr>
                <th>{defaultCurrency}</th>
                <th>{secondaryCurrency}</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>1</td>
                <td>{rate || '/'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <Modal isOpen={isOpen} onRequestClose={closeModal} className='currencies__modal' hasCloseIcon>
        <div className='currencies__modal-title'>Exchange rate</div>
        <div className='currencies__modal-description'>
          Change secondary currency and/or add exchange rate
        </div>
        <ExchangeRateForm
          defaultCurrency={defaultCurrencyLabel}
          defaultValues={{
            defaultCurrencyRate: '1',
            secondaryCurrency: secondaryCurrency || '',
            secondaryCurrencyRate: rate?.toString(),
          }}
          secondaryCurrencyOptions={secondaryCurrencyOptions}
          isSubmitInProgress={isUpdateInProgress}
          onSubmit={handleExchangeFormSubmitted}
          onCancel={closeModal}
        />
      </Modal>
    </div>
  );
};

export default Currencies;
