import { API_PATH } from 'constant';
import { EmployeeDocument } from 'models/EmployeeDocument';
import { TParams } from 'models/TParams';
import { PaginationType } from 'models/types';
import { httpClient } from 'modules';
import { EmployeeFormValues } from 'pages/EmployeeProfilePage/components/EmploymentInformation/types/EmployeeFormValues';
import { parseQueryParams } from 'utils/parseQueryParams';

async function getEmployeeDocuments(employeeId: string, params: TParams) {
  return httpClient.get<PaginationType<EmployeeDocument>>(`/files/user/${employeeId}`, {
    params: parseQueryParams(params),
  });
}

async function addEmployeeDocument(employeeId: string, formData: FormData) {
  return httpClient.post<EmployeeDocument>(`/files/user/${employeeId}`, formData);
}

async function updateEmployeeDocument(documentId: string, formData: FormData) {
  return httpClient.put<EmployeeDocument>(`/files/${documentId}`, formData);
}

async function removeEmployeeDocument(documentId: string) {
  return httpClient.delete<EmployeeDocument>(`/files/${documentId}`);
}

async function changeEmployeeDocumentVisibility(documentId: string, visibility: boolean) {
  return httpClient.patch<EmployeeDocument>(`/files/${documentId}`, { isVisible: visibility });
}
async function addEmployee(formData: FormData) {
  return httpClient.post<EmployeeFormValues>(`${API_PATH.USERS}`, formData);
}

export default {
  getEmployeeDocuments,
  addEmployeeDocument,
  updateEmployeeDocument,
  removeEmployeeDocument,
  changeEmployeeDocumentVisibility,
  addEmployee,
};
