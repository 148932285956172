export enum RequsetMethod {
  POST = 'POST',
  GET = 'GET',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  EMPLOYEE = 'EMPLOYEE',
  SUPER_ADMIN = 'SUPER_ADMIN',
}
