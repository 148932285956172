import React from 'react';
import Routes from './routes/Routes';
import { AuthProvider } from './context';
import 'react-datepicker/dist/react-datepicker.css';
import './App.css';

const App = () => (
  <AuthProvider>
    <Routes />
  </AuthProvider>
);

export default App;
