import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { APP_URI } from 'config';
import env from 'env';
const httpClient = axios.create({
  baseURL: env.SERVER_ENDPOINT,
});
httpClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem('token');
  if (token) config.headers.Authorization = `Bearer ${token}`;
  // Add the ngrok-specific header to skip the warning
  config.headers['ngrok-skip-browser-warning'] = 'true';
  return config;
});
// Response interceptor to handle 401 Unauthorized responses
httpClient.interceptors.response.use(
  (response) => {
    // If the response is successful, simply return the response
    return response;
  },
  (error: AxiosError) => {
    if (error?.response?.status === 401) {
      // Clear the token from localStorage
      localStorage.removeItem('token');
      // Optionally redirect to a login page or show a message
      window.location.href = APP_URI.login; // or any appropriate action
      // Optionally reject the promise with a specific message
      return Promise.reject(new Error('Unauthorized, please log in again.'));
    }
    // If the error is not 401, just reject the promise with the original error
    return Promise.reject(error);
  },
);
export { httpClient };
