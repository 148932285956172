import React, { useState } from 'react';
import { useModalState } from 'hooks';

import { useAuth } from 'context';
import { Message as MessageIcon } from 'assets';
import { Button, DaysOffCard } from 'components';
import { PageLayout } from 'components/layout/PageLayout';
import { PageHeader } from 'components/layout/PageHeader';
import EmployeeRequestVacationModal from 'components/RequestVacationModal';
import { DaysOffTable } from './components/DaysOffTable';
import { VacationsTable } from './components/VacationsTable';
import usePaginatedData from 'hooks/usePaginatedData';
import { EmployeeDocument } from 'models/EmployeeDocument';

import './DaysOffPage.styles.scss';

const DaysOffPage = () => {
  const { user } = useAuth();
  const {
    isOpen: requestVacationModalOpen,
    openModal: openRequestVacationModal,
    closeModal: closeRequestVacationModal,
  } = useModalState();

  const [refreshKey, setRefreshKey] = useState(0);

  const { firstYear } = usePaginatedData<EmployeeDocument>(
    {
      sort: { startDate: 1 },
      limit: 1,
    },
    '/files',
    true,
  );

  const vacationDaysPreviousYear = user?.vacationDaysLeftPreviousYear || 0;
  const vacationDaysTotal = user?.totalVacationDaysLeft || 0;

  return (
    <PageLayout className='ne-daysoff'>
      <PageHeader
        title='Days off'
        asideFromTitle={
          <Button
            icon={<MessageIcon />}
            onClick={openRequestVacationModal}
            className='ne-daysoff__request-vacation-button'
          >
            Request vacation
          </Button>
        }
      />
      <div className='ne-daysoff__cards-container'>
        <DaysOffCard title='Remaining days from previous year' days={vacationDaysPreviousYear} />
        <DaysOffCard title='Available days in total' days={vacationDaysTotal} />
      </div>
      <EmployeeRequestVacationModal
        isOpen={requestVacationModalOpen}
        onRequestClose={closeRequestVacationModal}
        onSucces={() => setRefreshKey((prev) => prev + 1)}
      />
      <DaysOffTable
        startYear={firstYear}
        urlParamNames={{
          yearParamName: 'daysoffYear',
          pageParamName: 'daysoffPage',
          sortByStartDateParamName: 'daysoffSort',
        }}
      />
      <VacationsTable
        startYear={firstYear}
        urlParamNames={{
          yearParamName: 'vacationsYear',
          pageParamName: 'vacationsPage',
          sortByStartDateParamName: 'vacationsSort',
        }}
        refreshKey={refreshKey}
      />
    </PageLayout>
  );
};
export default DaysOffPage;
