import { InfoIcon, SortIcon, UnsortedIcon } from 'assets';
import { Avatar, Button } from 'components';
import { TableColumnItem } from 'components/Table/Table.component';
import { VacationRequestData } from 'models/VacationRequest';
import DateService, { DATE_AND_TIME_FORMAT } from 'services/Date.service';

export const useTimeOffTableColumns = (
  onRowClick: (data: VacationRequestData) => void,
  handleSortByStartDate: () => void,
  handleSortByRequestedOn: () => void,
  sortByStartDate: number,
  sortByRequestedOn: number,
  navigateToUserProfile: (userId: string) => void,
  profileImageVisible?: boolean,
) => {
  const items: TableColumnItem<VacationRequestData>[] = [
    {
      columnLabel: 'Employee',
      columnName: 'employee',
      prepareCell: (data) => {
        return (
          <div className='ne-timeoffs__employee' onClick={() => navigateToUserProfile(data.userId)}>
            {profileImageVisible && (
              <div className='employees__table-container__avatar'>
                <Avatar
                  imageUrl={data.profileImageUrl || 'images/userDefault.png'}
                  alt={'Profile Image'}
                  size='md'
                />
              </div>
            )}
            <p>
              {data.user.firstName} {data.user.lastName}
            </p>
          </div>
        );
      },
    },
    {
      columnLabel: (
        <p className={`sort-icon-${sortByStartDate}`}>
          Start
          <Button
            variant='icon'
            onClick={() => handleSortByStartDate()}
            aria-label='Sort by start date'
          >
            {sortByStartDate === 0 && <UnsortedIcon />}
            {sortByStartDate !== 0 && <SortIcon />}
          </Button>
        </p>
      ),
      columnName: 'start',
      prepareCell: (data) => DateService.formatDate(data.startDate),
    },
    {
      columnLabel: 'End',
      columnName: 'end',
      prepareCell: (data) => DateService.formatDate(data.endDate),
    },
    {
      columnLabel: 'Days',
      columnName: 'days',
      prepareCell: (data) => data?.workingDays,
    },
    {
      columnLabel: (
        <p className={`sort-icon-${sortByRequestedOn}`}>
          Requested on
          <Button
            variant='icon'
            onClick={() => handleSortByRequestedOn()}
            aria-label='Sort by requested date'
          >
            {sortByRequestedOn === 0 && <UnsortedIcon />}
            {sortByRequestedOn !== 0 && <SortIcon />}
          </Button>
        </p>
      ),
      columnName: 'requested_on',
      prepareCell: (data) => DateService.formatDate(data.createdAt, DATE_AND_TIME_FORMAT),
    },
    {
      columnLabel: 'Status',
      columnName: 'status',
      prepareCell: (data) => data?.status,
    },
    {
      columnLabel: 'Actions',
      columnName: 'actions',
      prepareCell: (data) => {
        return (
          <div>
            <Button
              disabled={data.userDeactivated && data.status === 'Submitted'}
              type='button'
              onClick={() => onRowClick(data)}
              aria-label='More information'
            >
              <InfoIcon />
            </Button>
          </div>
        );
      },
    },
  ];
  return items;
};
