export function formatCurrency(currency: 'EUR' | 'RSD' | 'USD') {
  return (): string => {
    let symbol: string;
    switch (currency) {
      case 'EUR':
        symbol = '€';
        break;
      case 'RSD':
        symbol = 'дин.';
        break;
      case 'USD':
        symbol = '$';
        break;
      default:
        throw new Error('Unsupported currency');
    }

    return symbol;
  };
}
