import { StatusIcon } from 'assets';
import { Button } from 'components/Button';
import { PageHeader } from 'components/layout/PageHeader';
import { PageLayout } from 'components/layout/PageLayout';
import { APP_URI } from 'config';
import { useNavigate } from 'react-router-dom';
import TimeOffsTable from './components/TimeOffsTable/TimeOffsTable.component';

import './TimeOffsPage.styles.scss';

type TimeOffStatus = 'Submitted' | 'Approved' | 'Fulfilled' | 'Denied';
export type TimeOffsParams = {
  search: string;
  page: number;
  limit: number;
  filter: { status?: TimeOffStatus };
};

const TimeOffsPage = () => {
  const navigate = useNavigate();

  return (
    <PageLayout className='ne-timeoffs'>
      <PageHeader
        title='Time offs'
        className='ne-timeoffs__page-header'
        asideFromTitle={
          <Button
            onClick={() => navigate(APP_URI.nonworkingdays)}
            className='ne-timeoffs__status-button'
          >
            <div className='ne-timeoffs__icon'>
              <StatusIcon />
            </div>
            Status of vacation days
          </Button>
        }
      ></PageHeader>
      <TimeOffsTable
        initialStatus='Submitted'
        urlParamNames={{
          pageParamName: 'page',
          searchParamName: 'search',
          sortByRequestParamName: 'sortByRequest',
          sortByStartDateParamName: 'sortByDate',
          statusParamName: 'status',
        }}
      />
    </PageLayout>
  );
};

export default TimeOffsPage;
