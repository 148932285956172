import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { createPortal } from 'react-dom';

import './Modal.styles.scss';
import { CloseIcon } from 'assets';

export type ModalProps = {
  isOpen?: boolean;
  onRequestClose?: () => void;
  children?: ReactNode;
  className?: string;
  hasCloseIcon?: boolean;
};

const Modal: React.FC<ModalProps> = ({
  isOpen = false,
  children,
  className,
  onRequestClose = () => {
    //
  },
  hasCloseIcon = false,
}) => {
  const handleOverlayClick = () => {
    onRequestClose();
  };

  const classes = classNames(['modal', className]);

  const content = (
    <div className={classes}>
      <div className='modal__overlay' onClick={handleOverlayClick} />
      <div className='modal__content'>
        {children}
        {hasCloseIcon && <CloseIcon className='modal__content__close' onClick={onRequestClose} />}
      </div>
    </div>
  );

  return isOpen ? createPortal(content, document.body) : <></>;
};

export default Modal;
