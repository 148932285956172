import { EmployeeNonWorkingDays } from 'models/NonWorkingDays';
import { User } from 'models/User';

export const mapUserToNonWorkingDays = (user: User): EmployeeNonWorkingDays => ({
  employee: `${user.firstName} ${user.lastName}`,
  employeeId: user.id || '',
  startDate: user?.firstContract?.startDate || '',
  endDate: user?.lastContract?.endDate,
  nonWorkingDays: {
    vacationDaysCurrentYear: user.vacationDaysCurrentYear || 0,
    vacationDaysPreviousYear: user.vacationDaysPreviousYear || 0,
    vacationDaysLeftCurrentYear: user.vacationDaysLeftCurrentYear || 0,
    vacationDaysLeftPreviousYear: user.vacationDaysLeftPreviousYear || 0,
    usedVacationDaysCurrentYear: user.usedVacationDaysCurrentYear || 0,
    usedVacationDaysPreviousYear: user.usedVacationDaysPreviousYear || 0,
    totalVacationDaysLeft: user.totalVacationDaysLeft || 0,
    vacationDays: user.vacationDays,
  },
});
