import MenuItem from './MenuItem/MenuItem.component';
import { getSidebarMenuItems } from './getSidebarMenuItems';
import { UserRole } from 'enums';
import React from 'react';
import './Menu.scss';

type MenuProps = {
  userRole?: UserRole;
  onMenuItemClick?: () => void;
};

const Menu = ({ userRole, onMenuItemClick }: MenuProps) => {
  const sidebarNavItems = getSidebarMenuItems(userRole);

  return (
    <div className='menu'>
      {sidebarNavItems.map((item) => (
        <MenuItem key={item.to} {...item} icon={item?.icon} onClick={onMenuItemClick} />
      ))}
    </div>
  );
};

export default Menu;
