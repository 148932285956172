import { TGeneralVacationsParams } from 'pages/NonWorkingDaysPage/types/TGeneralVacationsParams';
import { GeneralVacationsUrlParamNames } from '../GeneralVacationsTable.component';

export const mapUrlParamsToRequestParams = (
  urlParamsNames: GeneralVacationsUrlParamNames,
  searchParams: URLSearchParams,
): Partial<TGeneralVacationsParams> => {
  const { pageParamName, yearParamName, sortByStartDateParamName } = urlParamsNames;

  const page = +(searchParams.get(pageParamName) || '');
  const year = +(searchParams.get(yearParamName) || '');
  const sortByDate = +(searchParams.get(sortByStartDateParamName) || '');

  return {
    ...(page && { page }),
    ...(year && { filter: { year } }),
    ...(sortByDate && { sort: { startDate: sortByDate } }),
  };
};
