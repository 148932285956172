import { API_PATH } from 'constant';
import { TParams } from 'models/TParams';
import { User } from 'models/User';
import { PaginationType } from 'models/types';
import { httpClient } from 'modules';
import { parseQueryParams } from 'utils/parseQueryParams';

export type AddAdminPayload = {
  firstName: string;
  lastName: string;
  email: string;
};

const getAdmins = async (params: TParams) => {
  return httpClient.get<PaginationType<User>>(API_PATH.GET_ADMINS, {
    params: parseQueryParams(params),
  });
};

const addAmin = async (newAdmin: AddAdminPayload) => {
  return httpClient.post<User>(API_PATH.ADD_ADMIN, newAdmin);
};

const inviteAdmin = async (adminId: string) => {
  return httpClient.post(API_PATH.INVITE_ADMIN(adminId));
};

const deleteAdminInvitation = async (adminId: string) => {
  return httpClient.delete(API_PATH.DELETE_ADMIN_INVITATION(adminId));
};

export default { getAdmins, addAmin, inviteAdmin, deleteAdminInvitation };
