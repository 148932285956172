import { Button, FieldWrapper, Modal } from 'components';
import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import { AddIcon } from 'assets';
import { useModalState } from 'hooks';
import * as Yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  LETTERS_REGEX,
  MSG_EMAIL_INVALID,
  MSG_LETTERS_ONLY,
  MSG_REQUIRED,
  NAME_REGEX,
} from 'validation';
import Input, { InputProps } from 'components/Input/Input.component';
import useAdminsStore from 'pages/RoleManagementPage/store/useAdminsStore';
import { snackbar } from 'modules';
import { MSG_NAME_INVALID } from 'validation/messages';
import { useAuth } from 'context';
import { UserRole } from 'enums';

import './AddAdmin.styles.scss';

type AddAdminProps = {
  className: string;
};

type AdminFormValue = {
  firstName: string;
  lastName: string;
  email: string;
};

const validation = Yup.object().shape({
  firstName: Yup.string()
    .required(MSG_REQUIRED)
    .matches(LETTERS_REGEX, MSG_LETTERS_ONLY)
    .matches(NAME_REGEX, MSG_NAME_INVALID)
    .max(30, 'First name too long'),
  lastName: Yup.string()
    .required(MSG_REQUIRED)
    .matches(LETTERS_REGEX, MSG_LETTERS_ONLY)
    .matches(NAME_REGEX, MSG_NAME_INVALID)
    .max(30, 'Last name too long'),
  email: Yup.string().email(MSG_EMAIL_INVALID).required(MSG_REQUIRED),
});

const AddAdmin: FC<AddAdminProps> = ({ className }) => {
  const { isOpen, closeModal, openModal } = useModalState();
  const { addAdmin } = useAdminsStore();
  const { user } = useAuth();

  const methods = useForm<AdminFormValue>({
    mode: 'onTouched',
    resolver: yupResolver(validation),
  });

  const closeAddAdminModal = useCallback(() => {
    closeModal();
    methods.reset();
  }, [closeModal, methods]);

  const onAddAdminSuccess = useCallback(() => {
    snackbar.show({
      message: 'You have successfully added new admin.',
      type: 'success',
    });
    closeAddAdminModal();
  }, []);

  const onAddAdminError = useCallback((errorMessage: string) => {
    const finalMessage = errorMessage;
    snackbar.show({ message: finalMessage, type: 'error' });
  }, []);

  const submitAddAdminForm = (values: AdminFormValue) => {
    return addAdmin(values, onAddAdminSuccess, onAddAdminError);
  };

  if (user?.role !== UserRole.SUPER_ADMIN) return <></>;

  return (
    <>
      <Button
        onClick={() => openModal()}
        icon={<AddIcon />}
        className={classNames('add-admin__btn', className)}
        type='button'
      >
        Add an admin
      </Button>
      <Modal
        onRequestClose={closeAddAdminModal}
        isOpen={isOpen}
        hasCloseIcon
        className='new-employee-modal'
      >
        <FormProvider {...methods}>
          <form
            className='add-admin__form'
            onSubmit={methods.handleSubmit(submitAddAdminForm)}
            name='formAddAdmin'
          >
            <h2>Invite admin</h2>
            <FieldWrapper<InputProps>
              name='firstName'
              fieldComponent={Input}
              label='Name'
              type='text'
              required
              asterix
            />
            <FieldWrapper<InputProps>
              name='lastName'
              fieldComponent={Input}
              label='Last name'
              type='text'
              required
              asterix
            />
            <FieldWrapper<InputProps>
              name='email'
              fieldComponent={Input}
              label='Email'
              type='text'
              required
              asterix
            />

            <Button
              disabled={!methods.formState.isValid}
              loading={methods.formState.isSubmitting}
              type='submit'
            >
              Invite
            </Button>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default AddAdmin;
