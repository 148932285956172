import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import React from 'react';
import { Button, FieldWrapper, LazyImage, PasswordField } from 'components';
import { APP_URI } from 'config';
import { API_PATH } from 'constant';
import { useAxios } from 'hooks';
import {
  LOWERCASE_REGEX,
  MSG_REQUIRED,
  NUMBER_REGEX,
  SPECIAL_CHARACTER_REGEX,
  UPPERCASE_REGEX,
} from 'validation';

import { InputProps } from 'components/Input/Input.component';

import { AxiosError } from 'axios';
import { ErrorType } from 'models/types';
import { snackbar } from 'modules';
import './PasswordReset.styles.scss';

const validation = Yup.object().shape({
  newPassword: Yup.string()
    .required(MSG_REQUIRED)
    .min(8, 'Password must be at least 8 characters')
    .matches(LOWERCASE_REGEX, 'Password must contain at least one lowercase letter')
    .matches(UPPERCASE_REGEX, 'Password must contain at least one uppercase letter')
    .matches(SPECIAL_CHARACTER_REGEX, 'Password must contain at least one special character')
    .matches(NUMBER_REGEX, 'Password must contain at least one number'),
  confirmPassword: Yup.string()
    .required(MSG_REQUIRED)
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match')
    .min(8, 'Password must be at least 8 characters')
    .matches(LOWERCASE_REGEX, 'Password must contain at least one lowercase letter')
    .matches(UPPERCASE_REGEX, 'Password must contain at least one uppercase letter')
    .matches(SPECIAL_CHARACTER_REGEX, 'Password must contain at least one special character')
    .matches(NUMBER_REGEX, 'Password must contain at least one number'),
});

type IResponse = { message: string };
type IError = { message: string; error: string; statusCode: number };
type FormData = { newPassword: string; confirmPassword: string };

const PasswordReset = () => {
  const [searchParams] = useSearchParams();
  const hash = decodeURI(searchParams.get('token') || '');
  const navigate = useNavigate();

  const methods = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(validation),
    defaultValues: { newPassword: '', confirmPassword: '' },
  });

  const handleError = (error: AxiosError<ErrorType, any>) => {
    error.message = error.response?.data.message || error.message;
    snackbar.show({ message: error.message, type: 'error' });
  };

  const { loading, request: sendRequest } = useAxios<IResponse, IError>({
    url: API_PATH.RESET_PASSWORD,
    method: 'POST',
    onResponse: () => {
      navigate(APP_URI.login);
    },
    onError: handleError,
  });

  const handleSubmit = (data: FormData) => {
    sendRequest({
      payload: {
        hash,
        newPassword: data.newPassword,
      },
    });
  };

  return (
    <div className='password-reset'>
      <div className='password-reset__container'>
        <div className='password-reset__wrapper'>
          <LazyImage
            src='images/nlogo.png'
            alt='ncoded-logo'
            className='password-reset__header__logo'
          />
          <div className='password-reset__header'>
            <h1 className='password-reset__header__title'>Reset password</h1>
          </div>
          <div className='password-reset__body'>
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(handleSubmit)}
                className='password-reset__body__form'
              >
                <FieldWrapper<InputProps>
                  label='New password'
                  name='newPassword'
                  fieldComponent={PasswordField}
                  className='password-reset__body__form__input'
                />
                <FieldWrapper<InputProps>
                  label='Confirm password'
                  name='confirmPassword'
                  fieldComponent={PasswordField}
                  className='password-reset__body__form__input'
                />
                <Button className='password-reset__body__form__button' loading={loading}>
                  <span>Reset password</span>
                </Button>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
