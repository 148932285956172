import { Button } from 'components/Button';
import './AddMultiplier.styles.scss';
import { AddIcon } from 'assets';
import { Modal } from 'components';
import React, { useCallback } from 'react';
import { useModalState } from 'hooks';
import useMultipliersStore from 'pages/SettingsPage/store/useMultipliersStore';
import MultiplierForm, { MultiplierFormValues } from '../MultiplierForm/MultiplierForm.component';
import { snackbar } from 'modules';

interface AddMultiplierProps {
  onMultiplierAdded: () => void;
}

const AddMultiplier: React.FC<AddMultiplierProps> = ({ onMultiplierAdded }) => {
  const { isOpen, closeModal, openModal } = useModalState();

  const { addMultiplierApi } = useMultipliersStore();

  const onAddSuccess = useCallback(() => {
    snackbar.show({
      message: 'Multiplier successfully added!',
      type: 'success',
    });
    closeModal();
    onMultiplierAdded();
  }, [closeModal]);

  const onAddError = useCallback((errorMessage: string) => {
    snackbar.show({ message: errorMessage, type: 'error' });
  }, []);

  const onAdd = useCallback(
    (values: MultiplierFormValues) => {
      return addMultiplierApi(values, onAddSuccess, onAddError);
    },
    [addMultiplierApi, onAddSuccess, onAddError],
  );

  return (
    <>
      <Button icon={<AddIcon />} onClick={openModal} className='add-multiplier-button'>
        Add Multiplier
      </Button>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className='multiplier-form-modal'
        hasCloseIcon
      >
        <MultiplierForm title='Add new multiplier' onSubmit={onAdd} buttonText={'Add'} />
      </Modal>
    </>
  );
};
export default AddMultiplier;
