import { PageLayout } from 'components/layout/PageLayout';
import React from 'react';
import EmployeePersonalInformation from './components/EmployeePersonalInformation';
import EmploymentInformation from './components/EmploymentInformation/EmploymentInformation.component';
import EmployeeContextProvider from './context/EmploeeContext/EmployeeContext.provider';

const EmployeeProfilePage = () => {
  return (
    <PageLayout>
      <EmployeeContextProvider>
        <EmployeePersonalInformation />
        <EmploymentInformation />
      </EmployeeContextProvider>
    </PageLayout>
  );
};

export default EmployeeProfilePage;
