import React from 'react';
import classNames from 'classnames';

import './PageHeader.styles.scss';

export type PageHeaderProps = {
  title?: React.ReactNode;
  asideFromTitle?: React.ReactNode;
  className?: string;
};

const PageHeader: React.FC<PageHeaderProps> = ({ title, className, asideFromTitle }) => {
  const classes = classNames('ne-page-header', className);

  return (
    <div className={classes}>
      <h1>{title}</h1>
      {asideFromTitle ? <div className='ne-page-header__aside'>{asideFromTitle}</div> : undefined}
    </div>
  );
};

export default PageHeader;
