import Table from 'components/Table';
import TablePagination from 'components/TablePagination';
import useAdminsStore from 'pages/RoleManagementPage/store/useAdminsStore';
import React, { useEffect } from 'react';
import useAdminsTableColumns from './hooks/useAdminsTableColumns';
import { useSearchParams } from 'react-router-dom';
import { SectionBlock } from 'components/layout/SectionBlock';
import { useAuth } from 'context';

import './AdminsTable.styles.scss';

const AdminsTable = () => {
  const { user } = useAuth();
  const { getAdmins, admins, pagination, isLoading } = useAdminsStore();
  const columns = useAdminsTableColumns(user?.id || '');
  const [searchParams, setSearchParams] = useSearchParams();

  const onPageChange = (page: number) => {
    searchParams.set('page', page.toString());
    setSearchParams(searchParams);
  };

  useEffect(() => {
    getAdmins(+(searchParams.get('page') || '') || 1);
  }, [searchParams]);

  return (
    <SectionBlock loading={isLoading} className='admins-table'>
      <Table data={admins} prepareData={columns} noDataMessage='There are no admins added yet.' />
      {pagination?.totalItems > 0 && (
        <TablePagination
          totalPages={pagination?.totalPages}
          currentPage={pagination?.currentPage}
          onPageChange={onPageChange}
          totalItems={pagination?.totalItems}
          pageSize={7}
        />
      )}
    </SectionBlock>
  );
};

export default AdminsTable;
