import { TabItem } from 'components/TabsV2/TabsV2.component';
import { API_PATH } from 'constant';
import { useAxios } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { generateUrl } from 'utils/generateUrl';
import { TimeOffsParams } from '../TimeOffs.page';

type RequestCountsType = {
  total: number;
  Submitted: number;
  Approved: number;
  Fulfilled: number;
  Denied: number;
};

const useStatusFilterItems = (params?: Partial<TimeOffsParams>) => {
  const [counts, setCounts] = useState<RequestCountsType>();

  const { request: getVacationCounts } = useAxios<RequestCountsType, any>({
    url: generateUrl(API_PATH.VACATION_COUNTS, params),
    method: 'GET',
    onResponse: (response) => {
      setCounts(response.data);
    },
  });

  const updateTimeOffsTabs = useCallback(() => {
    getVacationCounts({});
  }, [params]);

  useEffect(() => {
    getVacationCounts({});
  }, []);

  const statusFilterItems = useMemo(() => {
    return [
      {
        label: `Submitted (${counts?.Submitted || 0})`,
        id: 'Submitted',
      },
      {
        label: `Approved (${counts?.Approved || 0})`,
        id: 'Approved',
      },
      {
        label: `Fulfilled (${counts?.Fulfilled || 0})`,
        id: 'Fulfilled',
      },
      {
        label: `Denied (${counts?.Denied || 0})`,
        id: 'Denied',
      },
      {
        label: `All (${counts?.total || 0})`,
        id: 'All',
      },
    ] as TabItem[];
  }, [counts]);

  return { statusFilterItems, updateTimeOffsTabs };
};

export default useStatusFilterItems;
