import classNames from 'classnames';
import { Button } from 'components/Button';
import { TGeneralVacation } from 'models/GeneralVacation';
import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { EmployeeSelectOption, GeneralVacationValue } from '../../GeneralVacationModal.modal';
import SelectV2 from 'components/SelectV2';
import { FieldWrapper } from 'components/FieldWrapper';
import { SelectV2Props } from 'components/SelectV2/SelectV2.component';
import { DatePicker } from 'components/DatePicker';
import { Textarea } from 'components/Textarea';
import { TextareaProps } from 'components/Textarea/Textarea.components';
import useGeneralVacationOptions from 'hooks/options/useGeneralVacationOptions';
import { MultipleCheckbox } from '../MultipleCheckbox';
import { MultipleCheckboxProps } from '../MultipleCheckbox/MultipleCheckbox.component';

import './GeneralVacationForm.styles.scss';

type GeneralVacationFormProps = {
  methods: UseFormReturn<GeneralVacationValue, any, undefined>;
  onSubmit: (generalVacation: GeneralVacationValue) => void;
  onRemoveVacation?: () => void;
  onCancelChanges?: () => void;
  employees: EmployeeSelectOption[];
  employeesLoading?: boolean;
  submitLoading?: boolean;
  generalVacation?: TGeneralVacation;
  isEditMode: boolean;
};

const GeneralVacationForm: FC<GeneralVacationFormProps> = (props) => {
  const {
    methods,
    onSubmit,
    generalVacation,
    onRemoveVacation,
    employees,
    submitLoading,
    isEditMode,
  } = props;

  const formClasses = classNames('ne-general-vacation-form', {
    'ne-general-vacation-form--short': methods.watch('type') !== 'Collective vacation',
  });

  const vacationOptions = useGeneralVacationOptions();

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} className={formClasses}>
      <FieldWrapper<SelectV2Props>
        name='type'
        fieldComponent={SelectV2}
        options={vacationOptions}
        label='Vacantion Type'
        asterix
        disabled={isEditMode}
      />

      <div className='document-form__row document-form__dates'>
        <FieldWrapper<any>
          name='startDate'
          label='Start Date'
          fieldComponent={DatePicker}
          asterix
        />
        <FieldWrapper<any> name='endDate' label='End Date' fieldComponent={DatePicker} asterix />
      </div>

      <FieldWrapper<TextareaProps>
        name='description'
        label='Description'
        placeholder='Enter description...'
        fieldComponent={Textarea}
        required
        className='general-vacation-form__description'
      />

      {!isEditMode && methods.watch('type') === 'Collective vacation' && (
        <FieldWrapper<MultipleCheckboxProps>
          name='userIds'
          label='Employees'
          fieldComponent={MultipleCheckbox}
          options={employees.map((employee) => ({
            id: employee.id,
            label: `${employee.firstName} ${employee.lastName}`,
          }))}
          preselectedOptions={[]}
          asterix
          isEditMode={isEditMode}
        />
      )}

      {isEditMode && methods.watch('type') === 'Collective vacation' && (
        <FieldWrapper<MultipleCheckboxProps>
          name='userIds'
          label='Employees'
          fieldComponent={MultipleCheckbox}
          options={employees.map((employee) => ({
            id: employee.id,
            label: `${employee.firstName} ${employee.lastName}`,
          }))}
          preselectedOptions={employees
            .filter((employee) => generalVacation?.userIds?.includes(employee.id))
            .map((employee) => ({
              id: employee.id,
              label: `${employee.firstName} ${employee.lastName}`,
            }))}
          asterix
          isEditMode={isEditMode}
        />
      )}

      {generalVacation && (
        <small className='general-vacation-form__remove-vacation'>
          Created vacation by mistake? You can
          <Button
            className='general-vacation-form__remove-vacation-btn'
            onClick={onRemoveVacation}
            type='button'
          >
            remove it
          </Button>
          .
        </small>
      )}

      <Button
        className='general-vacation-form__submit-btn'
        type='submit'
        loading={submitLoading}
        disabled={isEditMode ? !methods.formState.isDirty : false}
      >
        {!isEditMode ? 'Add' : 'Edit'}
      </Button>
    </form>
  );
};

export default GeneralVacationForm;
