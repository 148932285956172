import { Button } from 'components/Button';
import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import './TabsV2.styles.scss';
import classNames from 'classnames';
import { ChevronIcon } from 'assets';

export type TabItem =
  | string
  | {
      id: string;
      label: string;
    };

type TabsV2Props = {
  items: TabItem[];
  className?: string;
  onFilterSelect: (item: string) => void;
  selectedItem?: string;
};

const TabsV2: FC<TabsV2Props> = ({ items, onFilterSelect, selectedItem }) => {
  const initialItemIndex = items.findIndex((item) =>
    typeof item === 'string' ? selectedItem === item : item.id === selectedItem,
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    initialItemIndex === -1 ? 0 : initialItemIndex,
  );
  const [tabLineWidth, setTabLineWidth] = useState(0);
  const [tabLineLeft, setTabLineLeft] = useState(0);

  const containerRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    const setTabPosition = () => {
      const buttonsList = containerRef.current?.querySelectorAll('button');
      if (buttonsList && buttonsList?.length > 0) {
        const currentTab = buttonsList[activeTabIndex];
        setTabLineLeft(currentTab?.offsetLeft ?? 0);
        setTabLineWidth(currentTab?.clientWidth ?? 0);
      }
    };

    if (containerRef.current) {
      setTabPosition();
    }

    window.addEventListener('resize', setTabPosition);

    return () => {
      window.removeEventListener('resize', setTabPosition);
    };
  }, [activeTabIndex, items]);

  const getItemLabel = (item: TabItem) => (typeof item === 'string' ? item : item.label);
  const getItemId = (item: TabItem) => (typeof item === 'string' ? item : item.id);

  const [areOptionsVisible, setAreOptionsVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(getItemLabel(items[0]));
  const showOptions = () => {
    setAreOptionsVisible(!areOptionsVisible);
  };

  return (
    <div className='tabs-v2-container'>
      <Button
        type='button'
        variant='outline'
        onClick={showOptions}
        className={classNames(
          'tabs-v2-container__dropdown-button',
          `tabs-v2-container__dropdown-button--${selectedOption.toLowerCase()}`,
        )}
      >
        {getItemLabel(items[activeTabIndex])}
        <ChevronIcon />
      </Button>
      {areOptionsVisible && (
        <div className={`tabs-v2 dropdown`} ref={containerRef}>
          {items.map((item, idx) => (
            <Button
              key={idx}
              className={classNames(
                'tabs-v2__button',
                `tabs-v2__button--${getItemId(item).toLowerCase()}`,
              )}
              onClick={() => {
                setSelectedOption(getItemLabel(item));
                setAreOptionsVisible(false);
                setActiveTabIndex(idx);
                onFilterSelect(getItemId(item));
              }}
            >
              {getItemLabel(item)}
            </Button>
          ))}
          <div
            className='tabs-v2__tab-line'
            style={{ left: tabLineLeft, width: tabLineWidth }}
          ></div>
        </div>
      )}
      <div className={`tabs-v2 non-dropdown`} ref={containerRef}>
        {items.map((item, idx) => (
          <Button
            key={idx}
            className={classNames(
              'tabs-v2__button',
              `tabs-v2__button--${getItemId(item).toLowerCase()}`,
            )}
            onClick={() => {
              setActiveTabIndex(idx);
              onFilterSelect(getItemId(item));
            }}
          >
            {getItemLabel(item)}
          </Button>
        ))}
        <div className='tabs-v2__tab-line' style={{ left: tabLineLeft, width: tabLineWidth }}></div>
      </div>
    </div>
  );
};

export default TabsV2;
