import React from 'react';
import { PageHeaderProps } from '../PageHeader/PageHeader.component';
import classNames from 'classnames';
import { Loader } from 'components/Loader';

import './SectionBlock.styles.scss';

type SectionBlockProps = PageHeaderProps & {
  children: React.ReactNode;
  loading?: boolean;
  displayChildren?: boolean;
};

const SectionBlock: React.FC<SectionBlockProps> = ({
  title,
  children,
  asideFromTitle,
  className,
  loading,
  displayChildren = true,
}) => {
  const classes = classNames('ne-section-block', className);

  const showHeader = !!title || !!asideFromTitle;

  return (
    <section className={classes}>
      {loading ? (
        <div className='ne-section-block__loading'>
          <Loader />
        </div>
      ) : undefined}
      {showHeader ? (
        <div className='ne-section-block__header'>
          {<h2>{title}</h2>}
          {asideFromTitle ? (
            <div className='ne-section-block__aside'>{asideFromTitle}</div>
          ) : undefined}
        </div>
      ) : undefined}
      {displayChildren && children}
    </section>
  );
};

export default SectionBlock;
