import React, { FC, useEffect, useMemo, useState } from 'react';

import { getYear } from 'date-fns';
import { SectionBlock } from 'components/layout/SectionBlock';
import Table from 'components/Table';
import TablePagination from 'components/TablePagination';
import usePaginatedData from 'hooks/usePaginatedData';
import { Vacation } from 'models/Vacation';
import { YearPicker } from 'components';
import { useVacationsTableColumns } from 'pages/DaysOffPage/hooks/useVacationsTableColumns';
import { SortingOrder } from 'models/types';
import { useSearchParams } from 'react-router-dom';
import { DaysOffUrlParamNames } from 'pages/DaysOffPage/types/DaysOffUrlParams';
import { mapUrlParamsToRequestParams } from 'pages/DaysOffPage/utils/utils';
import { toggleSortingOrder } from 'utils/toggleSortingOrder';

import './VacationsTable.styles.scss';

type VacationsTableProps = {
  startYear?: number;
  urlParamNames: DaysOffUrlParamNames;
  refreshKey?: number;
};

const VacationsTable: FC<VacationsTableProps> = ({ startYear, urlParamNames, refreshKey }) => {
  const {
    data: userVacations,
    loading: loadingVacations,
    pagination,
    params,
    setParams,
    getData,
  } = usePaginatedData<Vacation>(
    {
      sort: { startDate: -1 },
      limit: 10,
      page: 1,
      filter: { year: getYear(new Date()) },
    },
    '/vacations',
  );

  const minYear = startYear;
  const maxYear = getYear(new Date()) + 1;

  const [searchParams, setSearchParams] = useSearchParams();

  const urlParams = useMemo(
    () => mapUrlParamsToRequestParams(urlParamNames, searchParams),
    [searchParams],
  );

  useEffect(() => {
    getData({ ...params, ...urlParams });
  }, [urlParams, refreshKey]);

  const handleSortByStartDate = () => {
    searchParams.set(
      urlParamNames.sortByStartDateParamName,
      toggleSortingOrder(urlParams?.sort?.startDate || 0).toString(),
    );
    setSearchParams(searchParams);
  };

  const handlePageChanged = (page: number) => {
    searchParams.set(urlParamNames.pageParamName, page.toString());
    setSearchParams(searchParams);
  };

  const handleYearChanged = (year: number) => {
    searchParams.set(urlParamNames.yearParamName, year.toString());
    searchParams.set(urlParamNames.pageParamName, '1');
    setSearchParams(searchParams);
  };

  const [sortByStartDate, setSortByStartDate] = useState(SortingOrder.DESCENDING);

  useEffect(() => {
    setParams((prev) => ({ ...prev, page: 1, sort: { startDate: sortByStartDate } }));
  }, [sortByStartDate]);

  const vacationsTableColumns = useVacationsTableColumns(
    handleSortByStartDate,
    urlParams?.sort?.startDate || 0,
  );

  useEffect(() => {
    if (minYear && params.filter && +params?.filter.year < minYear)
      searchParams.set(urlParamNames.yearParamName, minYear.toString());
    if (params.filter && +params.filter.year > maxYear)
      searchParams.set(urlParamNames.yearParamName, maxYear.toString());
    setSearchParams(searchParams);
  }, [searchParams]);

  return (
    <SectionBlock
      title='Vacations'
      loading={loadingVacations}
      className='vacations-table'
      asideFromTitle={
        <YearPicker
          year={urlParams?.filter?.year || getYear(new Date())}
          onYearChange={handleYearChanged}
          maxYear={maxYear}
          minYear={minYear}
        />
      }
    >
      <div className='vacations-table__table-container'>
        <Table
          data={userVacations}
          prepareData={vacationsTableColumns}
          noDataMessage="Here, you'll find all your approved vacations and related documents once you submit your first vacation request."
        />
      </div>

      {pagination && (
        <TablePagination
          totalPages={pagination?.totalPages}
          currentPage={pagination?.currentPage}
          totalItems={pagination?.totalItems}
          pageSize={pagination?.pageSize}
          onPageChange={handlePageChanged}
        />
      )}
    </SectionBlock>
  );
};
export default VacationsTable;
