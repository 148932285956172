import { AddIcon } from 'assets';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { Button, Modal } from 'components';
import { API_PATH } from 'constant';
import { useAxios, useModalState } from 'hooks';
import { snackbar } from 'modules';
import EmployeeContext from 'pages/EmployeeProfilePage/context/EmploeeContext';
import React, { useContext } from 'react';
import parseAxiosError from 'utils/parseAxiosError';

import './InviteEmployee.styles.scss';

type InviteEmployeeProps = {
  userId?: string;
  className?: string;
  variant?: 'primary' | 'secondary';
};

const InviteEmployee: React.FC<InviteEmployeeProps> = ({
  userId,
  className,
  variant = 'primary',
}) => {
  const {
    isOpen: inviteModalOpen,
    openModal: openInviteModal,
    closeModal: closeInviteModal,
  } = useModalState();

  const { updateEmployee, employee } = useContext(EmployeeContext);

  /**
   * INVITE EMPLOYEE AXIOS
   */
  const { loading: loadingInviteEmployee, request: inviteEmployee } = useAxios({
    url: `${API_PATH.INVITE_USER}/${userId}`,
    method: 'POST',
    onResponse: () => {
      snackbar.show({ message: 'Email sent', type: 'success' });
      updateEmployee({ ...employee, inviteStatus: 'PENDING' });
      closeInviteModal();
    },
    onError: (error) => {
      const errorMessage = parseAxiosError(error as AxiosError<any, any>) || '';
      snackbar.show({ message: errorMessage, type: 'error' });
    },
  });

  return (
    <div className={classNames(className, 'ne-invite-employee', `ne-invite-employee--${variant}`)}>
      <Button
        onClick={openInviteModal}
        variant={variant === 'primary' ? 'link' : 'text'}
        type='button'
      >
        {variant === 'primary' && <AddIcon />}
        {variant === 'primary' ? 'Invite' : 'Resend invite'}
      </Button>

      <Modal
        isOpen={inviteModalOpen}
        onRequestClose={closeInviteModal}
        className='ne-invite-employee__modal'
        hasCloseIcon
      >
        <div className='ne-invite-employee__modal__content'>
          <h2>Invite employee</h2>
          <p>
            Invite your newly created employee by sending them an email.
            <br />
            Email contains a link to the login page.
          </p>
          <Button loading={loadingInviteEmployee} onClick={() => inviteEmployee({})}>
            Invite employee
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default InviteEmployee;
