const MSG_EMAIL_INVALID: string = 'Pattern should be met (example@ncoded.solutions).';

const MSG_LETTERS_ONLY: string = 'Must contain letters only.';

const MSG_REQUIRED: string = 'Required';
const MSG_FILE_TOO_LARGE: string = '*File is too large';

const MSG_NAME_INVALID = 'Must start with capital letter, letters only, no capitals in the middle';

const MSG_ONLY_NUMBERS = 'Must contain numbers only';
const MSG_REQUIRED_FIELD: string = 'Required';

const MSG_PHONE_NUMBER = 'Example formats: +381665522312, 0665522312, no blank spaces';
const MSG_PHONE_NUMBER_DUPLICATE =
  'This phone number is already in use. Please enter a different number.';
const MSG_PERSON_ID = 'Example: 0101995123456, no blank spaces, only numbers';
const MSG_SUCCESSFULLY_UPDATED = 'You have successfully updated informations.';

export {
  MSG_EMAIL_INVALID,
  MSG_LETTERS_ONLY,
  MSG_ONLY_NUMBERS,
  MSG_REQUIRED,
  MSG_NAME_INVALID,
  MSG_FILE_TOO_LARGE,
  MSG_REQUIRED_FIELD,
  MSG_PHONE_NUMBER,
  MSG_PHONE_NUMBER_DUPLICATE,
  MSG_PERSON_ID,
  MSG_SUCCESSFULLY_UPDATED,
};
