import { UploadFile } from 'components/Dropzone/Dropzone.component';
import { useCallback, useEffect, useState } from 'react';
import { FileError, FileRejection } from 'react-dropzone';

import noImagePlaceholder from 'assets/No-Image-Placeholder.svg.png';

type FileUploadProps = {
  value: UploadFile[];
  onChange: (files: UploadFile[]) => void;
  isProfilePicture?: boolean;
  multiple?: boolean;
  onError?: () => void;
  onAccept?: () => void;
};

const useFileUpload = ({
  onChange,
  value,
  isProfilePicture,
  multiple,
  onError,
  onAccept,
}: FileUploadProps) => {
  const [errorMessages, setErrorMessages] = useState<FileError[]>([]);

  const handleAccept = useCallback(
    (files: File[]) => {
      if (files.length === 0) {
        onChange([]);
        onError?.();
      } else {
        onChange([
          ...(multiple ? value : []),
          ...files.map((file) => ({
            name: file.name,
            type: file.type,
            blobURL: URL.createObjectURL(file),
          })),
        ]);
        onAccept?.();
      }

      setErrorMessages([]);
    },
    [multiple, onChange, value],
  );
  const handleRemove = useCallback(
    (url: string) => {
      if (!isProfilePicture) {
        onChange(value.filter(({ blobURL }) => blobURL !== url));
      } else {
        onChange([
          {
            blobURL: noImagePlaceholder,
            name: 'missingImage',
            type: 'image/png',
          },
        ]);
      }
      URL.revokeObjectURL(url);
      setErrorMessages([]);
    },
    [isProfilePicture, onChange, value],
  );

  const handleReject = useCallback((rejectedFiles: FileRejection[]) => {
    const errors = rejectedFiles.reduce<FileError[]>((acc, { errors }) => acc.concat(errors), []);
    setErrorMessages(errors);
    onError?.();
  }, []);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return value.forEach(({ blobURL }) => URL.revokeObjectURL(blobURL));
  }, []);

  return {
    handleAccept,
    handleReject,
    handleRemove,
    errorMessages,
  };
};

export default useFileUpload;
