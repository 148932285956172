import { TableColumnItem } from 'components/Table/Table.component';
import { EmployeeDocument } from 'models/EmployeeDocument';
import EmployeeDocumentActions from 'pages/EmployeeProfilePage/components/EmploymentInformation/components/EmployeeDocumentActions';
import { FC } from 'react';
import DateService from 'services/Date.service';
import useCurrenciesStore from 'store/useCurrenciesStore';
import { formatNumber } from 'utils/formatNumber';

type SalaryDisplayProps = {
  defaultAmount?: number;
};

const SalaryDisplay: FC<SalaryDisplayProps> = ({ defaultAmount }) => {
  const { currencies } = useCurrenciesStore();
  const { defaultCurrency, secondaryCurrency, rate } = currencies || {};

  return defaultAmount !== undefined ? (
    <div className='nce-table__salary'>
      <span className='nce-table__salary--primary'>
        {formatNumber(defaultAmount, 2)} {defaultCurrency}
      </span>
      {secondaryCurrency && rate && (
        <span className='nce-table__salary--secondary'>
          {formatNumber(rate * defaultAmount, 2)} {secondaryCurrency}
        </span>
      )}
    </div>
  ) : (
    <span className='nce-table__salary--secondary'>No info.</span>
  );
};

const useEmploymentInfoTableColumns = (): TableColumnItem<EmployeeDocument>[] => [
  {
    columnLabel: 'Type',
    prepareCell: (data) => data.type || '',
  },
  {
    columnLabel: 'Start',
    prepareCell: (data) => DateService.formatDate(data.startDate),
  },
  {
    columnLabel: 'End',
    prepareCell: (data) => (data.endDate ? DateService.formatDate(data.endDate) : ''),
  },
  {
    columnLabel: 'Net salary',
    prepareCell: ({ netSalary }) => <SalaryDisplay defaultAmount={netSalary?.amount} />,
  },
  {
    columnLabel: 'Gross salary',
    prepareCell: ({ grossSalary }) => <SalaryDisplay defaultAmount={grossSalary?.amount} />,
  },

  {
    columnLabel: 'Actions',
    columnName: 'actions',
    prepareCell: (data) => {
      return <EmployeeDocumentActions document={data} />;
    },
  },
  {
    columnLabel: 'Note',
    columnName: 'note',
    prepareCell: (data) => data.note || '',
  },
];

export default useEmploymentInfoTableColumns;
