import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { Modal } from 'components/Modal';
import { API_PATH } from 'constant';
import { useAxios, useModalState } from 'hooks';
import { TGeneralVacation } from 'models/GeneralVacation';
import { ErrorType, PaginationType, SortingOrder } from 'models/types';
import { snackbar } from 'modules';
import { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import DateService from 'services/Date.service';
import { generateUrl } from 'utils/generateUrl';
import { MSG_REQUIRED_FIELD } from 'validation';
import * as Yup from 'yup';
import { RemoveVacationModal } from './components';
import { GeneralVacationForm } from './components/GeneralVacationForm';

import './GeneralVacationModal.styles.scss';

export type GeneralVacationValue = {
  startDate: Date | string;
  endDate: Date | string;
} & Omit<TGeneralVacation, 'id' | 'startDate' | 'endDate' | 'workingDays'>;

type CreateGeneralVacationPayload = Omit<TGeneralVacation, 'id' | 'workingDays'>;

type GeneralVacationModalProps = {
  isOpen?: boolean;
  onClose: () => void;
  onSuccess?: (vacation: TGeneralVacation) => void;
  generalVacation?: TGeneralVacation;
  isEditMode: boolean;
};

export type EmployeeSelectOption = {
  id: string;
  firstName: string;
  lastName: string;
};

const validation = () =>
  Yup.object().shape({
    description: Yup.string()
      .required(MSG_REQUIRED_FIELD)
      .max(100, 'Description must be at most 100 characters'),
    startDate: Yup.mixed<Date | string>()
      .required(MSG_REQUIRED_FIELD)
      .test('isStartDateValid', 'Start date cannot be today', (value) => {
        const selectedDate = new Date(value);
        const currentDate = new Date();

        return selectedDate > currentDate;
      })
      .test('isNotWeekend', 'Weekend days cannot be selected', (value) => {
        const selectedDate = new Date(value);
        const dayOfWeek = selectedDate.getDay(); // 0 for Sunday, 6 for Saturday

        return dayOfWeek !== 0 && dayOfWeek !== 6; // Return true if not Sunday or Saturday
      }),
    endDate: Yup.mixed<Date | string>()
      .required(MSG_REQUIRED_FIELD)
      .when('startDate', (startDate, schema) => {
        return schema
          .test(
            'is after or equal to start',
            'End date must be after or equal to start date',
            (value) => {
              const startDateValue = Array.isArray(startDate) ? startDate[0] : startDate;
              const startDateObject = new Date(startDateValue);
              const endDateObject = new Date(value);

              return endDateObject >= startDateObject;
            },
          )
          .test('isNotWeekend', 'Weekend days cannot be selected', (value) => {
            const selectedDate = new Date(value);
            const dayOfWeek = selectedDate.getDay(); // 0 for Sunday, 6 for Saturday

            return dayOfWeek !== 0 && dayOfWeek !== 6; // Return true if not Sunday or Saturday
          });
      }),
    type: Yup.mixed<'State holiday' | 'Religious holiday' | 'Collective vacation'>().required(
      MSG_REQUIRED_FIELD,
    ),
    userIds: Yup.mixed<string[]>()
      .optional()
      .when('type', (type, schema) => {
        return schema.test('at least 1', 'You must choose at least 1 employee', (ids) => {
          return (type[0] as string) !== 'Collective vacation' || (ids && ids?.length > 0);
        });
      }),
  });

const GeneralVacationModal: FC<GeneralVacationModalProps> = (props) => {
  const { isOpen = false, onClose, onSuccess, generalVacation, isEditMode } = props;

  const [employees, setEmployees] = useState<EmployeeSelectOption[]>([]);

  const {
    isOpen: isRemoveVacationModalOpen,
    openModal: openRemoveVacationModal,
    closeModal: closeRemoveVacationModal,
  } = useModalState();

  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(validation()),
    defaultValues: generalVacation
      ? {
          ...generalVacation,
          startDate: DateService.formatDate(generalVacation?.startDate, 'yyyy-MM-dd'),
          endDate: DateService.formatDate(generalVacation?.endDate, 'yyyy-MM-dd'),
        }
      : {
          type: 'State holiday',
          description: '',
          userIds: undefined,
        },
  });

  const { request: loadEmployees, loading: employeesLoading } = useAxios<
    PaginationType<EmployeeSelectOption>,
    ErrorType,
    any
  >({
    url: generateUrl(API_PATH.USERS, {
      limit: 100,
      projection: {
        firstName: SortingOrder.ASCENDING,
        lastName: SortingOrder.ASCENDING,
        id: SortingOrder.ASCENDING,
      },
      sort: {
        firstName: SortingOrder.ASCENDING,
        lastName: SortingOrder.ASCENDING,
      },
    }),
    method: 'GET',
    onResponse: (responseParam) => {
      setEmployees(responseParam.data.items);
    },
  });

  const handleGeneralVacationResponse = useCallback(
    (vacation: TGeneralVacation, message: string) => {
      onSuccess?.(vacation);
      onClose();
      snackbar.show({
        message,
        type: 'success',
      });
    },
    [onClose, onSuccess],
  );

  const handleGeneralVacationError = useCallback(
    (error: AxiosError<ErrorType, any>) => {
      error.message = error.response?.data.message || error.message;
      methods.setError('root', error);
      snackbar.show({ message: error.message, type: 'error' });
    },
    [methods],
  );

  const { request: createGeneralVacationRequest, loading: createGeneralVacationRequestLoading } =
    useAxios<TGeneralVacation, ErrorType, CreateGeneralVacationPayload>({
      url: generateUrl(API_PATH.GENERAL_VACATIONS),
      method: 'POST',
      onResponse: (response) =>
        handleGeneralVacationResponse(
          response.data,
          `${response.data.type} added. This information will be visible to all${response.data.type === 'Collective vacation' ? ' selected' : ''} employees.`,
        ),

      onError: handleGeneralVacationError,
    });

  const { request: updateGeneralVacationRequest, loading: updateGeneralVacationRequestLoading } =
    useAxios<TGeneralVacation, ErrorType, any>({
      url: `${API_PATH.GENERAL_VACATIONS}/${generalVacation?.id}`,
      method: 'PATCH',
      onResponse: (response) =>
        handleGeneralVacationResponse(
          response.data,
          `${response.data.type} edited. This information will be visible to all${response.data.type === 'Collective vacation' ? ' selected' : ''} employees.`,
        ),
      onError: handleGeneralVacationError,
    });

  const { request: removeGeneralVacationRequest, loading: removeGeneralVacationRequestLoading } =
    useAxios<TGeneralVacation, ErrorType, any>({
      url: `${API_PATH.GENERAL_VACATIONS}/${generalVacation?.id}`,
      method: 'DELETE',
      onResponse: (response) =>
        handleGeneralVacationResponse(
          response.data,
          `${response.data.type} removed. This information will be visible to all${response.data.type === 'Collective vacation' ? ' selected' : ''} employees.`,
        ),
      onError: handleGeneralVacationError,
    });

  const createGeneralVacation = useCallback(
    (generalVacationValue: GeneralVacationValue) => {
      createGeneralVacationRequest({
        payload: {
          ...generalVacationValue,
          startDate: DateService.formatDate(generalVacationValue.startDate, 'yyyy-MM-dd'),
          endDate: DateService.formatDate(generalVacationValue.endDate, 'yyyy-MM-dd'),
        },
      });
    },

    [createGeneralVacationRequest],
  );

  const updateGeneralVacation = useCallback(
    (generalVacationValue: GeneralVacationValue) => {
      updateGeneralVacationRequest({
        payload: {
          ...generalVacationValue,
          startDate: DateService.formatDate(generalVacationValue.startDate, 'yyyy-MM-dd'),
          endDate: DateService.formatDate(generalVacationValue.endDate, 'yyyy-MM-dd'),
        },
      });
    },
    [updateGeneralVacationRequest],
  );

  const removeGeneralVacation = useCallback(() => {
    removeGeneralVacationRequest({});
  }, [removeGeneralVacationRequest]);

  const submitVacation = generalVacation ? updateGeneralVacation : createGeneralVacation;
  const vacationRequestLoading = generalVacation
    ? updateGeneralVacationRequestLoading
    : createGeneralVacationRequestLoading;

  useEffect(() => {
    loadEmployees({});
  }, []);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className='general-vacation-modal' hasCloseIcon>
      <FormProvider {...methods}>
        <h2>{!isEditMode ? 'Add general vacation' : 'Edit general vacation'}</h2>
        <GeneralVacationForm
          onSubmit={submitVacation}
          methods={methods}
          employees={employees}
          employeesLoading={employeesLoading}
          submitLoading={vacationRequestLoading}
          generalVacation={generalVacation}
          onRemoveVacation={openRemoveVacationModal}
          onCancelChanges={onClose}
          isEditMode={isEditMode}
        />
        <RemoveVacationModal
          isOpen={isRemoveVacationModalOpen}
          onRequestClose={closeRemoveVacationModal}
          onRemove={removeGeneralVacation}
          isRemoveRequestLoading={removeGeneralVacationRequestLoading}
        />
      </FormProvider>
    </Modal>
  );
};

export default GeneralVacationModal;
