import React, { FC, ReactElement } from 'react';
import { LazyImage } from 'components/LazyImage';

import './AuthContainer.styles.scss';

type AuthContainerProps = {
  title: string;
  children: ReactElement;
};

const AuthContainer: FC<AuthContainerProps> = ({ title, children }) => {
  return (
    <div className='auth-container'>
      <div className='auth-container--wrapper'>
        <div className='auth-container__left'>
          <LazyImage src='images/nlogo.png' alt='logo' />
          <h1>NCODED</h1>
          <h2>EMPLOYEE</h2>
        </div>
        <div className='auth-container__right'>
          <h1 className='auth-container__title'>{title}</h1>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthContainer;
