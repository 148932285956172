import { useCallback, useState } from 'react';

const useCallbackRef = <T>(initialValue?: T) => {
  const [ref, setRef] = useState<T | undefined>(initialValue);
  const callbackRef = useCallback((element: T) => {
    if (element !== null) setRef(element);
  }, []);
  return [ref, callbackRef] as [T, (element: T) => void];
};

export { useCallbackRef };
