import { ArrowLeftIcon } from 'assets';
import classNames from 'classnames';
import { Button } from 'components/Button';
import { Pagination } from 'models/types';
import React from 'react';
import { DOTS, usePagination } from './hooks/usePagination';
import './TablePagination.styles.scss';

type TablePaginationProps = Pagination & {
  onPageChange: (pageNumber: number) => void;
  siblingCount?: number;
  pageSize?: number;
};

const TablePagination: React.FC<TablePaginationProps> = (props) => {
  const {
    onPageChange,
    totalItems,
    siblingCount = 1,
    currentPage,
    pageSize = 10,
    totalPages,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalItems,
    siblingCount,
    pageSize,
    totalPages,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange?.length - 1];

  return (
    <ul className={classNames('ne-table-pagination')}>
      {/* Left navigation arrow */}
      <li>
        <Button
          variant='link'
          className='arrow-left'
          disabled={currentPage === 1}
          onClick={onPrevious}
          aria-label='Go to previous page'
        >
          <ArrowLeftIcon />
        </Button>
      </li>
      {paginationRange.map((pageNumber, index) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return (
            <li className='pagination-item dots' key={pageNumber + '-' + index}>
              &#8230;
            </li>
          );
        }

        // Render our Page Pills
        return (
          <li key={pageNumber}>
            <Button
              variant='link'
              className={classNames({
                selected: pageNumber === currentPage,
              })}
              onClick={() => onPageChange(pageNumber as number)}
              aria-label={`Go to page ${pageNumber}`}
            >
              {pageNumber}
            </Button>
          </li>
        );
      })}
      {/*  Right Navigation arrow */}
      <li>
        <Button
          variant='link'
          className='arrow-right'
          disabled={currentPage === lastPage}
          onClick={onNext}
          aria-label='Go to next page'
        >
          <ArrowLeftIcon />
        </Button>
      </li>
    </ul>
  );
};

export default TablePagination;
