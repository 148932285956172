import { PageHeader } from 'components/layout/PageHeader';
import { PageLayout } from 'components/layout/PageLayout';
import { SectionBlock } from 'components/layout/SectionBlock';
import { AdminsTable } from './components/AdminsTable';
import { AddAdmin } from './components/AddAdmin';

import './RoleManagement.styles.scss';

const RoleManagementPage = () => {
  return (
    <PageLayout className='role-management'>
      <PageHeader title='Role management' />
      <SectionBlock title='Admins'>
        <div className='role-management__content'>
          <p className='role-management__description'>
            Admins are individuals who can manage employee documentation and have full access to
            employee information. They can be either internal team members or external associates.
            Important: Admins have the same permissions as account owners regarding employee and
            account management. Therefore, ensure that only trusted individuals are granted admin
            access. Please note that admins do not have the ability to invite new admins to the
            account, only employees.
          </p>

          <AddAdmin className='role-management__add-admin-btn' />
          <AdminsTable />
        </div>
      </SectionBlock>
    </PageLayout>
  );
};

export default RoleManagementPage;
