import React from 'react';
import { DownloadFile } from 'components/DownloadFile';
import { TableColumnItem } from 'components/Table/Table.component';
import { EmployeeDocument } from 'models/EmployeeDocument';
import DateService from 'services/Date.service';
import { formatNumber } from 'utils/formatNumber';

export const employeeDocumentTableColumns: TableColumnItem<EmployeeDocument>[] = [
  {
    columnLabel: 'Type',
    columnName: 'type',
    prepareCell: (data) => data.type || '',
  },
  {
    columnLabel: 'Start',
    columnName: 'start',
    prepareCell: (data) => DateService.formatDate(data.startDate),
  },
  {
    columnLabel: 'End',
    columnName: 'end',
    prepareCell: (data) => (data.endDate ? DateService.formatDate(data.endDate) : ''),
  },
  {
    columnLabel: 'Net salary',
    columnName: 'net-salary',
    prepareCell: (data) =>
      `${formatNumber(data.netSalary?.amount || 0, 2)} ${data.netSalary?.currency || ''}`,
  },

  {
    columnLabel: 'Download',
    columnName: 'download',
    prepareCell: (data) => <DownloadFile document={data} />,
  },
];
