import { Avatar, Button, Modal } from 'components';
import React, { FC } from 'react';

import './DeactivateEmployeeModal.styles.scss';

type DeactivateModalProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  firstName: string;
  lastName: string;
  profileImageUrl: string;
  loadingDeactivateRequest: boolean;
  deactivateEmployee: () => void;
  isEmployeeDeactivated: boolean;
};

const DeactivateEmployeeModal: FC<DeactivateModalProps> = ({
  isModalOpen,
  closeModal,
  firstName,
  lastName,
  profileImageUrl,
  loadingDeactivateRequest,
  deactivateEmployee,
  isEmployeeDeactivated,
}) => {
  const title = isEmployeeDeactivated ? 'Reactivate user' : 'Deactivate user';
  const description = isEmployeeDeactivated
    ? 'By reactivating the account the user will be granted access to the personal account. '
    : 'Are you sure you want to deactivate the user? By deactivating the account the user won’t be able to access the personal account. You can reactivate the user later.';
  const buttonText = isEmployeeDeactivated ? 'Reactivate' : 'Deactivate';

  return (
    <Modal isOpen={isModalOpen} onRequestClose={closeModal} hasCloseIcon>
      <div className='ne-personal-information__deactivate-modal'>
        <h2>{title}</h2>
        <p>{description}</p>
        <div className='ne-personal-information__deactivate-modal__employee-info'>
          <Avatar imageUrl={profileImageUrl} alt='employee-photo' /> {firstName} {lastName}
        </div>
        <div className='ne-personal-information__deactivate-modal__actions-buttons'>
          <Button onClick={closeModal} variant='outline' type='button'>
            Cancel
          </Button>
          <Button
            loading={loadingDeactivateRequest}
            type='button'
            onClick={() => deactivateEmployee()}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeactivateEmployeeModal;
