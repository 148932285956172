import classNames from 'classnames';
import React, { FC } from 'react';

import './DropzoneContent.styles.scss';

type DropzoneContentProps = {
  className?: string;
};

const DropzoneContent: FC<DropzoneContentProps> = (props) => {
  const { className } = props;
  const classes = classNames('dropzone-content', className);

  return (
    <div className={classes}>
      <label>
        <span className='dropzone-content__label'>Upload a file </span>
        or drag and drop
      </label>
    </div>
  );
};

export default DropzoneContent;
