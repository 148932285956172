// '/general-vacations'

import { User } from 'models/User';
import { PaginationType } from 'models/types';
import { httpClient } from 'modules';
import { TEmployeeVacationsParams } from 'pages/NonWorkingDaysPage/types/TEmployeeVacationsParams';
import { TGeneralVacationsParams } from 'pages/NonWorkingDaysPage/types/TGeneralVacationsParams';
import { parseQueryParams } from 'utils/parseQueryParams';

async function getVacations(params: TGeneralVacationsParams) {
  return httpClient.get('/general-vacations', {
    params: parseQueryParams(params),
  });
}

async function getEmployeesVacations(params: TEmployeeVacationsParams) {
  return httpClient.get<PaginationType<User>>('/users', { params: parseQueryParams(params) });
}

export default { getVacations, getEmployeesVacations };
