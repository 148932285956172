import { yupResolver } from '@hookform/resolvers/yup';
import { API_PATH } from 'constant';
import { useAxios, useModalState } from 'hooks';
import { Salary } from 'models/Salary';
import { ErrorType } from 'models/types';
import { User } from 'models/User';
import { snackbar } from 'modules';
import EmployeeContext from 'pages/EmployeeProfilePage/context/EmploeeContext';
import { FC, RefObject, useCallback, useContext, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import useGlobalMiltipliersStore from 'store/useGlobalMiltipliersStore';
import parseAxiosError from 'utils/parseAxiosError';
import { MSG_REQUIRED_FIELD } from 'validation';
import * as Yup from 'yup';
import { SalaryVariablesCard, SalaryVariablesEditModal } from './components';
import useCurrenciesStore from 'store/useCurrenciesStore';

import './EmployeeSalaryVariables.styles.scss';

export type UpdateSalaryInformationValue = {
  multiplierId: string;
  netSalaryAmount: string;
};

type UpdateSalaryInformationPayload = {
  multiplierId: string;
  netSalary: {
    amount: number;
    currency?: string;
  };
};

const validation = Yup.object().shape({
  multiplierId: Yup.string().required(MSG_REQUIRED_FIELD),
  netSalaryAmount: Yup.string().required(MSG_REQUIRED_FIELD),
});

type EmployeeSalaryVariablesProps = {
  containerRef: RefObject<HTMLDivElement>;
};

const EmployeeSalaryVariables: FC<EmployeeSalaryVariablesProps> = ({ containerRef }) => {
  const { employee, updateEmployee } = useContext(EmployeeContext);
  const { id: employeeId, multiplier, netSalary, grossSalary } = employee || {};

  const userSalaryVariables = useMemo(() => {
    if (!multiplier || !netSalary)
      return {
        multiplier: '',
        multiplierId: '',
      };
    return {
      multiplier: multiplier.name,
      multiplierId: multiplier.id,
      grossSalary: { amount: grossSalary, currency: netSalary.currency } as Salary,
      netSalary: netSalary,
    };
  }, [employee]);

  const { isOpen: isEditModalOpen, openModal, closeModal } = useModalState();

  const { multipliers } = useGlobalMiltipliersStore();
  const { currencies } = useCurrenciesStore();
  const { defaultCurrency } = currencies || {};

  const defaultValues = useMemo(
    () => ({
      multiplierId: userSalaryVariables.multiplierId,
      netSalaryAmount: netSalary?.amount.toString(),
    }),
    [employee],
  );

  const methods = useForm<UpdateSalaryInformationValue>({
    mode: 'onTouched',
    resolver: yupResolver(validation),
    defaultValues,
  });

  const { request: editUserSalaryInformation } = useAxios<
    User,
    ErrorType,
    UpdateSalaryInformationPayload
  >({
    url: `${API_PATH.USERS}/${employeeId}`,
    method: 'PATCH',
    onResponse: (response) => {
      const { data: user } = response;
      snackbar.show({
        message: 'Salary information successfully changed.',
        type: 'success',
      });

      updateEmployee(user);

      closeEditSalaryInformationModal();
    },
    onError: (error) => {
      const errorMessage = parseAxiosError(error) || '';
      methods.setError('root', error);
      snackbar.show({ message: errorMessage, type: 'error' });
    },
  });

  const changeSalaryInformation = useCallback(
    (salaryInformation: UpdateSalaryInformationValue) => {
      const { multiplierId, netSalaryAmount } = salaryInformation;

      return editUserSalaryInformation({
        payload: {
          multiplierId: multiplierId,
          netSalary: { amount: parseFloat(netSalaryAmount), currency: defaultCurrency },
        },
      });
    },
    [editUserSalaryInformation],
  );

  const closeEditSalaryInformationModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const openEditSalaryInformationModal = useCallback(() => {
    methods.reset(defaultValues);
    openModal();
  }, [defaultValues]);

  return (
    <>
      <SalaryVariablesCard
        grossSalary={userSalaryVariables.grossSalary}
        netSalary={netSalary}
        multiplierName={userSalaryVariables.multiplier}
        onEditButtonClicked={openEditSalaryInformationModal}
        containerRef={containerRef}
      />

      <SalaryVariablesEditModal
        isOpen={isEditModalOpen}
        onRequestClose={closeEditSalaryInformationModal}
        methods={methods}
        multipliers={multipliers}
        onSubmit={changeSalaryInformation}
        defaultCurrency={defaultCurrency}
      />
    </>
  );
};

export default EmployeeSalaryVariables;
