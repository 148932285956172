import {
  CloseIcon,
  DownloadIcon,
  EyeVisibillityOffIcon,
  EyeVisibillityOnIcon,
  PenIcon,
} from 'assets';
import classNames from 'classnames';
import { Button, Loader } from 'components';
import { useModalState } from 'hooks';
import { useDownloadFile } from 'hooks/useDownloadFile';
import { EmployeeDocument } from 'models/EmployeeDocument';
import { snackbar } from 'modules';
import EmployeeContext from 'pages/EmployeeProfilePage/context/EmploeeContext';
import useAdminEmploymentStore from 'pages/EmployeeProfilePage/store/useAdminEmploymentStore';
import { FC, useCallback, useContext } from 'react';
import useGlobalMiltipliersStore from 'store/useGlobalMiltipliersStore';
import { DocumentModal } from '../DocumentModal';
import RemoveDocumentModal from '../RemoveDocumentModal';
import { Tooltip } from 'react-tooltip';

import './EmployeeDocumentActions.styles.scss';

type EmployeeDocumentActionsProps = {
  className?: string;
  document: EmployeeDocument;
};

const EmployeeDocumentActions: FC<EmployeeDocumentActionsProps> = (props) => {
  const { className, document } = props;

  const { employee, updateEmployee } = useContext(EmployeeContext);

  const { lastContract, id: employeeId } = employee;

  const classes = classNames('nce-employee-document-actions', className);

  const { multipliers } = useGlobalMiltipliersStore();
  const {
    removeDocumentApi,
    isRemovingDocument,
    updateDocumentApi,
    changeDocumentVisibilityApi,
    isChangingVisibility,
    visibilityChangingDocId,
  } = useAdminEmploymentStore();

  const {
    isOpen: removeModalOpen,
    openModal: openRemoveModal,
    closeModal: closeRemoveModal,
  } = useModalState();

  const {
    isOpen: editModalOpen,
    openModal: openEditModal,
    closeModal: closeEditModal,
  } = useModalState();

  const onRemoveSuccess = useCallback(() => {
    snackbar.show({
      message: 'You have successfully deleted a document.',
      type: 'success',
    });
    closeRemoveModal();
  }, [closeRemoveModal]);

  const onRemoveError = useCallback((errorMessage: string) => {
    snackbar.show({ message: errorMessage, type: 'error' });
  }, []);

  const handleOnRemove = useCallback(() => {
    return removeDocumentApi(document?._id, employeeId, onRemoveSuccess, onRemoveError);
  }, [document, removeDocumentApi, onRemoveSuccess, onRemoveError, employeeId]);

  const onChangeVisibilitySuccess = () => {
    snackbar.show({
      message: 'You have successfully changed visibility of a document.',
      type: 'success',
    });
  };

  const onChangeVisibilityError = useCallback((errorMessage: string) => {
    snackbar.show({ message: errorMessage, type: 'error' });
  }, []);

  const handleOnChangeVisibility = useCallback(
    (visibility: boolean) => {
      return changeDocumentVisibilityApi(
        document?._id,
        visibility,
        onChangeVisibilitySuccess,
        onChangeVisibilityError,
      );
    },
    [changeDocumentVisibilityApi, onChangeVisibilitySuccess, onChangeVisibilityError],
  );

  const { download, ref: fileRef } = useDownloadFile({
    document,
    onError: () => {
      snackbar.show({
        message: 'An error occured during download, please try again later.',
        type: 'error',
      });
    },
  });

  const onDocumentUpdateSuccess = useCallback(
    (document: EmployeeDocument) => {
      if (employee.lastContract?.id === document._id) {
        const { multiplierId, grossSalary } = document;
        updateEmployee({
          lastContract: {
            ...employee.lastContract,
            grossSalary,
          },
          multiplier: multipliers.find((mul) => mul.id === multiplierId),
        });
      }
      closeEditModal();
      snackbar.show({
        message: 'Saved changes.',
        type: 'success',
      });
    },
    [closeEditModal, updateEmployee, multipliers, employee],
  );

  const onDocumentUpdateError = useCallback(
    (errorMessage: string) => {
      snackbar.show({
        message: errorMessage,
        type: 'error',
      });
    },
    [closeEditModal],
  );

  const handleDocumentEdit = useCallback(
    (formData: FormData) => {
      return updateDocumentApi(
        document._id,
        formData,
        onDocumentUpdateSuccess,
        onDocumentUpdateError,
      );
    },
    [document?._id, onDocumentUpdateSuccess, onDocumentUpdateError],
  );

  return (
    <div className={classes}>
      <a ref={fileRef} href='#' />
      <Button
        variant='icon'
        aria-label='Hide'
        data-tooltip-id='document-visibility-tooltip'
        onClick={() => handleOnChangeVisibility(!document.isVisible)}
      >
        {isChangingVisibility && visibilityChangingDocId === document._id ? (
          <Loader className='document-visibility-loading' />
        ) : !document.isVisible ? (
          <EyeVisibillityOffIcon className='action-show' />
        ) : (
          <EyeVisibillityOnIcon className='action-hide' />
        )}
        <Tooltip id='document-visibility-tooltip'>
          {!document.isVisible
            ? ' Click to show the document to the employee. Click again to hide it from them.'
            : 'Click to hide the document from the employee. Click again to show it for them.'}
        </Tooltip>
      </Button>

      <Button
        onClick={download}
        variant='icon'
        aria-label='Download'
        data-tooltip-id='document-download-tooltip'
      >
        <DownloadIcon className='action-download' />
        <Tooltip id='document-download-tooltip'>Download document.</Tooltip>
      </Button>
      <Button
        onClick={() => openEditModal()}
        data-tooltip-id='document-edit-tooltip'
        variant='icon'
        aria-label='Edit'
      >
        <PenIcon className='action-edit' />
        <Tooltip id='document-edit-tooltip'>Edit document.</Tooltip>
      </Button>
      <Button
        variant='icon'
        aria-label='Remove'
        data-tooltip-id='document-remove-tooltip'
        onClick={openRemoveModal}
        className='document-remove-button'
      >
        <CloseIcon className='action-remove' />
        <Tooltip id='document-remove-tooltip'>Remove document.</Tooltip>
      </Button>

      <RemoveDocumentModal
        isOpen={removeModalOpen}
        onRequestClose={closeRemoveModal}
        loading={isRemovingDocument}
        onSubmit={handleOnRemove}
        hasCloseIcon
      />
      {editModalOpen ? (
        <DocumentModal
          isEditMode
          isOpen={editModalOpen}
          onRequestClose={closeEditModal}
          userId={employeeId || ''}
          multipliers={multipliers}
          onSubmit={handleDocumentEdit}
          document={document}
        />
      ) : undefined}
    </div>
  );
};

export default EmployeeDocumentActions;
