import { CurrencySettings } from 'models/Currency';
import { httpClient } from 'modules';

const getCurrencies = async () => {
  return httpClient.get<CurrencySettings>(`/currency-exchanges`);
};

const updateCurrencies = async (currencies: CurrencySettings) => {
  return httpClient.post<CurrencySettings>(`/currency-exchanges`, currencies);
};

export default {
  getCurrencies,
  updateCurrencies,
};
