import { User } from 'models/User';
import React, { FC } from 'react';
import { RegisteredAdminActions } from './components/RemoveAdminModal';
import { UserRole } from 'enums';
import { PendingAdminActions } from './components/PendingAdminActions';

type AdminActionsProps = {
  admin: User;
};

const AdminActions: FC<AdminActionsProps> = ({ admin }) => {
  const { inviteStatus, role } = admin;

  if (role === UserRole.SUPER_ADMIN) return <></>;

  switch (inviteStatus) {
    case 'ACCEPTED':
      return <RegisteredAdminActions admin={admin} />;
    case 'PENDING':
      return <PendingAdminActions admin={admin} />;
    case 'NOT_INVITED':
      return <></>;
  }
};

export default AdminActions;
