import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FieldWrapper } from 'components/FieldWrapper';
import { DatePicker } from 'components/DatePicker';
import { Textarea } from 'components/Textarea';
import { TextareaProps } from 'components/Textarea/Textarea.components';
import { DatePickerProps } from 'components/DatePicker/DatePicker.component';
import { Button } from 'components/Button';
import { RequestVacationType } from 'components/RequestVacationModal/EmployeeRequestVacation.modal';

import './EmployeeRequestVacationForm.styles.scss';

type EmployeeRequestVacationFormProps = {
  methods: UseFormReturn<any, any, undefined>;
  onSubmit: (employeeRequestVacation: RequestVacationType) => void;
  submitLoading?: boolean;
};

const EmployeeRequestVacationForm: FC<EmployeeRequestVacationFormProps> = (props) => {
  const { methods, onSubmit, submitLoading } = props;

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} className={'ne-employee-request-vacation-form'}>
      <div className='document-form__row document-form__dates'>
        <FieldWrapper<DatePickerProps>
          name='startDate'
          label='Start Date'
          fieldComponent={DatePicker}
          asterix
        />
        <FieldWrapper<DatePickerProps>
          name='endDate'
          label='End Date'
          fieldComponent={DatePicker}
          asterix
        />
      </div>
      <FieldWrapper<TextareaProps>
        name='description'
        label='Description'
        placeholder='Enter description...'
        fieldComponent={Textarea}
        required
      />
      <Button
        className='ne-employee-request-vacation-form__button'
        type='submit'
        loading={submitLoading}
        disabled={submitLoading}
      >
        Send
      </Button>
    </form>
  );
};

export default EmployeeRequestVacationForm;
