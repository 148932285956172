import classNames from 'classnames';
import { Loader } from 'components/Loader';
import React, { FC, ReactElement } from 'react';

import './TableRowMessage.styles.scss';

type TableRowMessageProps = {
  isError: boolean;
  isLoading?: boolean;
  message?: string | ReactElement;
  className?: string;
  colSpan: number;
};

const TableRowMessage: FC<TableRowMessageProps> = ({
  isError,
  message,
  isLoading,
  className,
  colSpan,
}) => {
  const classes = classNames(
    'table-row-message',
    {
      'table-row-message--error': isError,
    },
    {
      'table-row-message--loading': isLoading,
    },
    className,
  );

  return (
    <tr className={classes}>
      <td className='table-row-message__td' colSpan={colSpan}>
        {isLoading ? <Loader /> : <p>{message}</p>}
      </td>
    </tr>
  );
};
export default TableRowMessage;
