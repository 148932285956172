// useInfiniteScrollWithAxios.ts

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IAxiosParams, useAxios } from './useAxios';
import { PaginationType, SortingOrder } from 'models/types';

type InfinitePaginationProps<TResponse> = {
  axiosConfig: IAxiosParams<PaginationType<TResponse>, any>;
  initialPage?: number;
  limit?: number;
  searchString?: string;
  sortByName?: number;
  isGridDisplay?: boolean;
};

const isGridContainerUnpopulated = (gridRef: React.MutableRefObject<HTMLDivElement | null>) => {
  if (gridRef.current) {
    const children = gridRef.current.children;
    const lastChild = children[children.length - 1];

    if (lastChild) {
      // Get the dimensions and position of the last child
      const { top, bottom, left, right, width } = lastChild.getBoundingClientRect();

      // Get window height
      const windowHeight = window.innerHeight;

      // Get the grid container's width
      const gridWidth = gridRef.current.getBoundingClientRect().width;

      // Check if the last child starts within the height of the window
      const isWithinWindowHeight = top < windowHeight;

      // Check if the last child fills the entire row
      const isFillingRow = right === gridWidth || gridWidth - right < width;
      return isFillingRow && isWithinWindowHeight;
    }
  }
  return false;
};

function useInfinitePagination<TResponse>(options: InfinitePaginationProps<TResponse>) {
  const { axiosConfig, initialPage = 1, limit, searchString, sortByName, isGridDisplay } = options;

  const listInnerRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<HTMLDivElement | null>(null);
  const gridContainerRef = useRef<HTMLDivElement | null>(null);
  const [currPage, setCurrPage] = useState(initialPage);
  const [dataList, setDataList] = useState<TResponse[]>([]);
  const [lastPage, setLastPage] = useState(false);
  const [resfreshKey, setRefreshKey] = useState(0);
  const [lastLoadedPage, setLastLoadedPage] = useState(0);

  const {
    request: axiosRequest,
    loading,
    response: axiosResponse,
  } = useAxios<PaginationType<TResponse>, any, any>({
    ...axiosConfig,
  });

  const fetchData = () => {
    const queryParams = {
      ...axiosConfig.params,
      page: currPage,
      search: searchString,
      limit,
      sort: {
        isDeleted: 1,
        ...(sortByName !== SortingOrder.UNSORTED && { firstName: sortByName }),
        _id: 1,
      },
    };
    axiosRequest({ queryParams });
  };

  useEffect(() => {
    const { data } = axiosResponse || {};

    if (!data) return;
    if (data.currentPage !== lastLoadedPage + 1) return;

    setLastLoadedPage(data.currentPage);
    if (currPage === 1) setDataList(data.items);
    else setDataList((prev) => [...prev, ...(data.items || {})]);

    if (data.totalPages <= currPage) {
      setLastPage(true);
    }
  }, [axiosResponse]);

  useEffect(() => {
    fetchData();
  }, [resfreshKey]);

  useEffect(() => {
    setCurrPage(1);
    setLastPage(false);
    setLastLoadedPage(0);
    if (currPage === 1) setRefreshKey((prev) => prev + 1);
  }, [sortByName, searchString]);

  useEffect(() => {
    setRefreshKey((prev) => prev + 1);
  }, [currPage, axiosConfig.params, limit]);

  const onScroll = useCallback(() => {
    if (listInnerRef.current) {
      if (!listRef.current) return;
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;

      if (
        scrollTop + 2.0 + clientHeight >= scrollHeight &&
        !lastPage &&
        currPage &&
        lastLoadedPage === currPage
      ) {
        setCurrPage(currPage + 1);
      }
    }
  }, [currPage, lastPage, lastLoadedPage]);

  useEffect(() => {
    if (dataList?.length === 0) return;
    // Get the height of the element
    const elementHeight = listInnerRef?.current?.offsetHeight; // or element.getBoundingClientRect().height;

    // Get the height of the window
    const windowHeight = window.innerHeight;

    if (isGridContainerUnpopulated(gridContainerRef) || (elementHeight || 0) < windowHeight) {
      loadMoreData();
    }
  }, [lastLoadedPage, isGridDisplay]);

  const loadMoreData = useCallback(() => {
    if (!lastPage && lastLoadedPage === currPage) setCurrPage((prevPage) => prevPage + 1);
  }, [lastPage, currPage, lastLoadedPage]);

  const setSearch = () => {
    setCurrPage(1);
    setLastPage(false);
  };

  return {
    listInnerRef,
    dataList,
    onScroll,
    setSearch,
    loading,
    loadMoreData,
    axiosResponse,
    currPage,
    listRef,
    gridContainerRef,
  };
}

export default useInfinitePagination;
