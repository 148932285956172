import React from 'react';
import { CalendarIcon } from 'assets';

import './DaysOffCard.styles.scss';

type DaysOff = {
  title: string;
  days: number;
};

function DaysOffCard({ days, title }: DaysOff) {
  return (
    <div className='days-off-card'>
      <span className='days-off-card__title'>
        <CalendarIcon />
        <span>{title}</span>
      </span>
      <span className='days-off-card__available-days'>{`${days} ${
        days === 1 ? 'day' : 'days'
      }`}</span>
    </div>
  );
}

export default DaysOffCard;
