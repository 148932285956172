import React, { useEffect, useMemo } from 'react';

import { PageLayout } from 'components/layout/PageLayout';
import { PageHeader } from 'components/layout/PageHeader';
import { SectionBlock } from 'components/layout/SectionBlock';
import Table from 'components/Table';
import TablePagination from 'components/TablePagination';
import { employeeDocumentTableColumns } from './utils';
import usePaginatedData from 'hooks/usePaginatedData';
import { EmployeeDocument } from 'models/EmployeeDocument';
import { YearPicker } from 'components';
import { getYear } from 'date-fns';
import { useAuth } from 'context';

import './DocumentsPage.styles.scss';
import { useSearchParams } from 'react-router-dom';

const pageParamName = 'page';

const DocumentsPage = () => {
  const {
    data: userDocuments,
    pagination,
    params,
    loading: loadingUserFiles,
    setParams,

    getData,
  } = usePaginatedData<EmployeeDocument>(
    {
      sort: { startDate: -1, createdAt: -1 },
      limit: 10,
      page: 1,
    },
    '/files',
  );

  const { user } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();

  const page = +(searchParams.get(pageParamName) || '') || 1;

  const handlePageChanged = (page: number) => {
    searchParams.set(pageParamName, page.toString());
    setSearchParams(searchParams);
  };

  useEffect(() => {
    getData({ page });
  }, [searchParams]);

  return (
    <PageLayout className='documents-page'>
      <PageHeader title='My documents' />
      <SectionBlock title='Employment information' loading={loadingUserFiles}>
        <div className='documents-page__table-container'>
          <Table
            data={userDocuments}
            prepareData={employeeDocumentTableColumns}
            noDataMessage="Welcome to Ncoded! 👋 Here you'll find your employment related documents once the admin adds them."
          />
        </div>

        {pagination && (
          <TablePagination
            totalPages={pagination?.totalPages}
            currentPage={pagination?.currentPage}
            totalItems={pagination?.totalItems}
            pageSize={pagination?.pageSize}
            onPageChange={handlePageChanged}
          />
        )}
      </SectionBlock>
    </PageLayout>
  );
};
export default DocumentsPage;
