import { DownloadIcon } from 'assets';
import { Button } from 'components/Button';
import { useDownloadFile } from 'hooks/useDownloadFile';
import { File } from 'models/File';
import { snackbar } from 'modules';
import { FC } from 'react';

import './DownloadFile.styles.scss';

type DownloadFileProps = {
  document?: File;
};

const DownloadFile: FC<DownloadFileProps> = ({ document }: DownloadFileProps) => {
  const { download, ref: fileRef } = useDownloadFile({
    document,
    onError: () => {
      snackbar.show({
        message: 'An error occured during download, please try again later.',
        type: 'error',
      });
    },
  });

  return (
    <>
      <a ref={fileRef} href='#' aria-label='Download file' />
      <Button
        className='download-file'
        onClick={download}
        disabled={!document}
        aria-label='Download file'
      >
        <DownloadIcon className='download-file__icon' />
      </Button>
    </>
  );
};

export default DownloadFile;
