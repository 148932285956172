import api from 'api';
import { AxiosError } from 'axios';
import { EmployeeDocument } from 'models/EmployeeDocument';
import { TParams } from 'models/TParams';
import { Pagination, SortingOrder } from 'models/types';
import parseAxiosError from 'utils/parseAxiosError';
import sortObjectsByStartDateDescending from 'utils/sortObjectsByStartDateDescending';
import { create } from 'zustand';

type Params = TParams;

interface EmploymentState {
  isLoading: boolean;
  isRemovingDocument: boolean;
  isUpdatingDocument: boolean;
  isChangingVisibility: boolean;
  visibilityChangingDocId: string;
  documents: EmployeeDocument[];
  pagination: Pagination;
  params: Params;
  error?: string;
  getDocuments: (employeeId: string, params?: Partial<Params>) => void;
  updateDocumentApi: (
    documentId: string,
    formData: FormData,
    onSuccess?: (document: EmployeeDocument) => void,
    onError?: (errorMessage: string) => void,
  ) => Promise<void>;
  addDocumentApi: (
    employeeId: string,
    formData: FormData,
    onSuccess?: (document: EmployeeDocument) => void,
    onError?: (errorMessage: string) => void,
  ) => Promise<void>;
  removeDocumentApi: (
    documentId: string,
    employeeId: string,
    onSuccess?: () => void,
    onError?: (errorMessage: string) => void,
  ) => Promise<void>;
  changeDocumentVisibilityApi: (
    documentId: string,
    visibility: boolean,
    onSuccess?: (document: EmployeeDocument) => void,
    onError?: (errorMessage: string) => void,
  ) => Promise<void>;
  updateDocumentCache: (documentId: string, document: Partial<EmployeeDocument>) => void;
}

const useAdminEmploymentStore = create<EmploymentState>()((set, get) => ({
  isLoading: false,
  isRemovingDocument: false,
  isUpdatingDocument: false,
  isChangingVisibility: false,
  visibilityChangingDocId: '',
  documents: [],
  params: {
    limit: 10,
    sort: { startDate: SortingOrder.DESCENDING, createdAt: -1 },
    page: 1,
  },
  pagination: {
    totalItems: 0,
    currentPage: 0,
    totalPages: 0,
    pageSize: 10,
  },
  getDocuments: async (employeeId: string, params?: Partial<Params>) => {
    try {
      set({ isLoading: true });
      let finalParams: Params;

      if (params) {
        finalParams = { ...get().params, ...params };
      } else {
        finalParams = get().params;
      }

      const { data } = await api.admin.employee.getEmployeeDocuments(employeeId, finalParams);
      const { items, ...pagination } = data;
      set({ isLoading: false, documents: items, pagination });
    } catch (err) {
      set({ isLoading: false });
    }
  },

  updateDocumentApi: async (
    documentId: string,
    formData: FormData,
    onSuccess?: (document: EmployeeDocument) => void,
    onError?: (errorMessage: string) => void,
  ) => {
    const currentState = get();

    try {
      set({ isUpdatingDocument: true });

      const { data: updatedDocument } = await api.admin.employee.updateEmployeeDocument(
        documentId,
        formData,
      );
      const updatedDocuments = currentState.documents.map((doc) =>
        doc._id === updatedDocument?._id ? updatedDocument : doc,
      );

      set({
        ...currentState,
        documents: sortObjectsByStartDateDescending(updatedDocuments),
        isUpdatingDocument: false,
      });

      onSuccess?.(updatedDocument);
    } catch (err) {
      set({ isUpdatingDocument: false });
      const errorMessage = parseAxiosError(err as AxiosError<any, any>);
      onError?.(errorMessage);
    }
  },
  changeDocumentVisibilityApi: async (documentId, visibility, onSuccess, onError) => {
    const currentState = get();

    try {
      set({ isChangingVisibility: true });
      set({ visibilityChangingDocId: documentId });

      const { data: updatedDocument } = await api.admin.employee.changeEmployeeDocumentVisibility(
        documentId,
        visibility,
      );
      const updatedDocuments = currentState.documents.map((doc) =>
        doc._id === updatedDocument?._id ? updatedDocument : doc,
      );

      set({
        ...currentState,
        documents: updatedDocuments,
        isChangingVisibility: false,
        visibilityChangingDocId: '',
      });

      onSuccess?.(updatedDocument);
    } catch (err) {
      set({ isChangingVisibility: false, visibilityChangingDocId: '' });
      const errorMessage = parseAxiosError(err as AxiosError<any, any>);
      onError?.(errorMessage);
    }
  },
  addDocumentApi: async (
    employeeId: string,
    formData: FormData,
    onSuccess?: (document: EmployeeDocument) => void,
    onError?: (errorMessage: string) => void,
  ) => {
    const currentState = get();
    try {
      const { data: newDocument } = await api.admin.employee.addEmployeeDocument(
        employeeId,
        formData,
      );
      if (currentState.pagination?.currentPage === 1) {
        set({
          documents: [newDocument, ...currentState.documents],
          pagination: {
            ...currentState.pagination,
            totalItems: (currentState.pagination.totalItems || 0) + 1,
          },
        });
      } else {
        get().getDocuments(employeeId, {
          ...currentState.params,
          page: 1,
        });
      }
      onSuccess?.(newDocument);
    } catch (err) {
      const errorMessage = parseAxiosError(err as AxiosError<any, any>);
      onError?.(errorMessage);
    }
  },
  removeDocumentApi: async (documentId: string, employeeId: string, onSuccess, onError) => {
    const currentState = get();
    try {
      set({ isRemovingDocument: true });
      await api.admin.employee.removeEmployeeDocument(documentId);

      const updatedDocuments = currentState.documents.filter((doc) => doc._id !== documentId);

      if (updatedDocuments.length === 0 && currentState.pagination?.currentPage > 0) {
        currentState.getDocuments(employeeId, {
          ...currentState.params,
          page: currentState.pagination?.currentPage - 1,
        });
      }
      set({
        ...currentState,
        documents: currentState.documents.filter((doc) => doc._id !== documentId),
        pagination: {
          ...currentState.pagination,
          totalPages: currentState.pagination?.totalPages || 1,
          currentPage: currentState.pagination?.currentPage || 1,
          totalItems: Math.max(0, (currentState.pagination?.totalItems || 0) - 1),
        },
        isRemovingDocument: false,
      });
      onSuccess?.();
    } catch (err) {
      set({ isRemovingDocument: false });
      const errorMessage = parseAxiosError(err as AxiosError<any, any>);
      onError?.(errorMessage);
    }
  },
  updateDocumentCache: (documentId: string, documentData: Partial<EmployeeDocument>) => {
    const state = get();
    set({
      ...state,
      documents: state.documents.map((doc) =>
        doc._id === documentId ? { ...doc, ...documentData } : doc,
      ),
    });
  },
}));

export default useAdminEmploymentStore;
