import { Modal } from 'components/Modal';
import NewEmployeeForm from '../NewEmployeeForm/NewEmployeeForm.component';
import { FC, useCallback } from 'react';
import { EmployeeFormValues } from 'pages/EmployeeProfilePage/components/EmploymentInformation/types/EmployeeFormValues';
import { snackbar } from 'modules';
import { useNavigate } from 'react-router-dom';
import api from 'api';
import parseAxiosError from 'utils/parseAxiosError';
import { AxiosError } from 'axios';
import './NewEmployeeModal.styles.scss';

type NewEmployeeModalProps = {
  onCloseModal: () => void;
  isModalOpen: boolean;
};

const NewEmployeeModal: FC<NewEmployeeModalProps> = ({ onCloseModal, isModalOpen }) => {
  const navigate = useNavigate();

  const onAddSuccess = useCallback((employee: EmployeeFormValues) => {
    snackbar.show({
      message: 'New employee added',
      type: 'success',
    });
    onCloseModal();
    navigate(`/employees/manage-employees/${employee.id}`);
  }, []);

  const onAddError = useCallback((errorMessage: string) => {
    snackbar.show({ message: errorMessage, type: 'error' });
  }, []);

  const addEmployeeApi = async (
    formData: FormData,
    onSuccess?: (employee: EmployeeFormValues) => void,
    onError?: (errorMessage: string) => void,
  ) => {
    try {
      const { data: employee } = await api.admin.employee.addEmployee(formData);
      onSuccess?.(employee);
    } catch (err) {
      const errorMessage = parseAxiosError(err as AxiosError<any, any>);
      onError?.(errorMessage);
    }
  };
  const handleAddEmployee = useCallback(
    (formData: FormData) => {
      return addEmployeeApi(formData, onAddSuccess, onAddError);
    },
    [onAddSuccess, onAddError],
  );
  return (
    <Modal
      onRequestClose={onCloseModal}
      isOpen={isModalOpen}
      hasCloseIcon
      className='new-employee-modal'
    >
      <NewEmployeeForm handleAddEmployee={handleAddEmployee} />
    </Modal>
  );
};

export default NewEmployeeModal;
