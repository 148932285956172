import { CloseIcon, File, PenIcon } from 'assets';
import classNames from 'classnames';
import { Button, Dropzone } from 'components';
import { UploadFile } from 'components/Dropzone';
import { OpenFileBrowser } from 'components/Dropzone/Dropzone.component';
import { useFileUpload } from 'hooks';
import React, { forwardRef, ForwardRefRenderFunction, useCallback, useRef } from 'react';
import { FileRejection } from 'react-dropzone';
import { calculateBytes, FileSizeUnit } from 'utils/calculateBytes';
import { truncateFileName } from 'utils/truncateFileName';
import { DropzoneActiveContent, DropzoneContent } from './components';

import './DocumentDropzone.styles.scss';

export type DocumentDropzoneProps = {
  value: UploadFile[];
  onChange: (files: UploadFile[]) => void;
  errorMessage?: string;
  className?: string;
  onBlur?: () => void;
};

const DocumentDropzone: ForwardRefRenderFunction<OpenFileBrowser, DocumentDropzoneProps> = (
  props,
  _ref,
) => {
  const { value = [], onChange, className, errorMessage, onBlur } = props;

  const ref = useRef<OpenFileBrowser>(null);

  const classes = classNames('document-dropzone', className);

  const { handleAccept, handleReject, handleRemove, errorMessages } = useFileUpload({
    multiple: false,
    onChange,
    value,
  });

  const handleDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      handleReject(fileRejections);
    },
    [handleReject],
  );

  return (
    <div className={classes}>
      <Dropzone
        ref={ref}
        value={value}
        onAccept={handleAccept}
        content={<DropzoneContent />}
        activeContent={<DropzoneActiveContent />}
        className='document-dropzone__dropzone'
        isVisible={!value.length}
        dropzoneOptions={{
          accept: { 'aplication/pdf': ['.pdf'] },
          maxSize: calculateBytes(5, FileSizeUnit.MB),
          maxFiles: 1,
          onDropRejected: handleDropRejected,
        }}
        onBlur={onBlur}
      />

      {value.map((file) => (
        <div key={file.blobURL} className='document-dropzone__file'>
          <div className='document-dropzone__filename'>
            <File />
            <label>{truncateFileName(file.name, 30)}</label>
          </div>
          <div className='document-dropzone__file-btns'>
            <Button
              type='button'
              className='document-dropzone__edit-file-btn'
              onClick={ref.current?.open}
            >
              <span>
                <PenIcon />
                Edit
              </span>
            </Button>

            <Button
              type='button'
              className='document-dropzone__remove-file-btn'
              onClick={() => {
                handleRemove(file.blobURL);
                onBlur?.();
              }}
            >
              <span>
                <CloseIcon />
                Remove
              </span>
            </Button>
          </div>
        </div>
      ))}
      {errorMessages.map((errorMessage, index) => (
        <small className='document-dropzone__error-message' key={index}>
          {errorMessage.code === 'file-too-large'
            ? 'File is larger than 5MB'
            : errorMessage.message}
        </small>
      ))}
      {errorMessage && <small className='document-dropzone__error-message'>{errorMessage}</small>}
    </div>
  );
};

export default forwardRef(DocumentDropzone);
