import { SortIcon, UnsortedIcon } from 'assets';
import { Button } from 'components';
import { TableColumnItem } from 'components/Table/Table.component';
import { EmployeeNonWorkingDays } from 'models/NonWorkingDays';
import DateService from 'services/Date.service';
import EditWorkingDays from '../components/EditWorkingDays';

const useWorkingDaysTableColumns = (
  handleSortByName: () => void,
  handleSortByStartDate: () => void,
  sortByName: number,
  sortByStartDate: number,
  navigateToEmployee: (userId: string) => void,
): TableColumnItem<EmployeeNonWorkingDays>[] => [
  {
    columnLabel: (
      <p className={`employee-sort-icon-${sortByName}`}>
        Employee
        <Button variant='icon' onClick={() => handleSortByName()} aria-label='Sort by name'>
          {sortByName === 0 ? <UnsortedIcon /> : <SortIcon />}
        </Button>
      </p>
    ),
    columnName: 'employee',
    prepareCell: (data) => (
      <span onClick={() => navigateToEmployee(data.employeeId)}>{data.employee}</span>
    ),
  },
  {
    columnLabel: (
      <p className={`employee-sort-icon-${sortByStartDate}`}>
        Start date
        <Button
          variant='icon'
          onClick={() => handleSortByStartDate()}
          aria-label='Sort by first contract start date'
        >
          {sortByStartDate === 0 ? <UnsortedIcon /> : <SortIcon />}
        </Button>
      </p>
    ),
    columnName: 'start-date',
    prepareCell: (data) => (data.startDate ? DateService.formatDate(data.startDate) : '-'),
  },
  {
    columnLabel: 'End',
    prepareCell: (data) =>
      data.endDate ? DateService.formatDate(data.endDate) : !data.startDate ? '-' : 'N/A',
  },
  {
    columnLabel: 'No of days',
    prepareCell: (data) =>
      data.nonWorkingDays.vacationDaysCurrentYear + data.nonWorkingDays.vacationDaysPreviousYear,
  },

  {
    columnLabel: 'Used days',
    columnName: 'used-days',
    prepareCell: (data) =>
      data.nonWorkingDays.usedVacationDaysCurrentYear +
      data.nonWorkingDays.usedVacationDaysPreviousYear,
  },
  {
    columnLabel: 'Remaining days',
    columnName: 'remaining-days',
    prepareCell: (data) => data.nonWorkingDays.totalVacationDaysLeft,
  },
  {
    columnLabel: 'Edit',
    columnName: 'edit',
    prepareCell: (data) => <EditWorkingDays data={data} />,
  },
];

export default useWorkingDaysTableColumns;
