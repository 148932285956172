import { User } from 'models/User';
import React from 'react';

export type TEmployeeContext = {
  employee: User;
  updateEmployee: (employee: Partial<User>) => void;
  refreshEmployeeData: () => void;
};

const EmployeeContext = React.createContext<TEmployeeContext>({} as TEmployeeContext);

export default EmployeeContext;
