import React from 'react';
import { Hourglass } from 'assets';
import { LazyImage } from 'components/LazyImage';

import './Empty.styles.scss';

const EmptyPage = () => {
  return (
    <div className='empty-page'>
      <LazyImage src='/images/nlogo.png' alt='logo' className='empty-page__image' />
      <div className='empty-page__description'>
        <h1>Please be patient</h1>
        <h2>The page content will be added soon...</h2>
        <div className='hourglass'>
          <Hourglass />
        </div>
      </div>
    </div>
  );
};

export default EmptyPage;
