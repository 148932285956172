export enum FileSizeUnit {
  'B' = 0,
  'KB',
  'MB',
  'GB',
  'TB',
  'PB',
  'EB',
  'ZB',
  'YB',
}

export const calculateBytes = (size: number, unit: FileSizeUnit): number =>
  size * Math.pow(1024, unit);
