import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FieldWrapper } from 'components';
import SelectV2, { SelectV2Props } from 'components/SelectV2/SelectV2.component';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Input, { InputProps } from 'components/Input/Input.component';
import { MSG_REQUIRED_FIELD } from 'validation';
import * as Yup from 'yup';
import { handleKeyDown } from 'pages/SettingsPage/components/MulitpliersSettings/components/MultiplierForm/MultiplierForm.component';

import './ExchangeRateForm.styles.scss';

export type ExchangeFormValue = {
  defaultCurrencyRate: string;
  secondaryCurrency: string;
  secondaryCurrencyRate: string;
};

type ExchangeRateFormProps = {
  defaultCurrency: string;
  defaultValues: Partial<ExchangeFormValue>;
  secondaryCurrencyOptions: { value: string; label: string | number }[];
  onSubmit: (values: ExchangeFormValue) => void;
  isSubmitInProgress: boolean;
  onCancel?: () => void;
};

const ExchangeRateForm: FC<ExchangeRateFormProps> = ({
  defaultCurrency,
  defaultValues,
  secondaryCurrencyOptions,
  isSubmitInProgress,
  onSubmit,
  onCancel,
}) => {
  const validation = Yup.object().shape({
    defaultCurrencyRate: Yup.string()
      .test('is-not-zero', 'Default currency rate has to be greater or equal to zero.', (value) => {
        const parsedValue = parseFloat(value || '');
        return parsedValue > 0;
      })
      .required(MSG_REQUIRED_FIELD),
    secondaryCurrency: Yup.string().required(MSG_REQUIRED_FIELD),
    secondaryCurrencyRate: Yup.string()
      .test('is-not-zero', 'Default currency rate has to be greater or equal to zero.', (value) => {
        const parsedValue = parseFloat(value || '');
        return parsedValue > 0;
      })

      .required(MSG_REQUIRED_FIELD),
  });

  const methods = useForm<ExchangeFormValue>({
    mode: 'onTouched',
    resolver: yupResolver(validation),
    defaultValues: defaultValues,
  });

  return (
    <FormProvider {...methods}>
      <form
        className='exchange-rate-form'
        onSubmit={methods.handleSubmit(onSubmit)}
        name='formDefaultCurrency'
      >
        <div className='exchange-rate-form__form-fields'>
          <div className='exchange-rate-form__default-currency'>
            <label>Default</label>
            <div className='exchange-rate-form__field'>{defaultCurrency}</div>
            <FieldWrapper<InputProps>
              name='defaultCurrencyRate'
              fieldComponent={Input}
              onKeyDown={handleKeyDown}
              required
              className={'exchange-rate-form__input-field'}
            />
          </div>
          <div className='exchange-rate-form__secondary-currency'>
            <label>Secondary</label>
            <FieldWrapper<SelectV2Props>
              name='secondaryCurrency'
              fieldComponent={SelectV2}
              required
              options={secondaryCurrencyOptions}
              placeholder='Select currency'
              className={'exchange-rate-form__input-field'}
            />
            <FieldWrapper<InputProps>
              name='secondaryCurrencyRate'
              fieldComponent={Input}
              onKeyDown={handleKeyDown}
              required
              placeholder='/'
              className={'exchange-rate-form__input-field'}
            />
          </div>
        </div>
        <div className='exchange-rate-form__btns-container'>
          <Button type='button' variant='outline' onClick={onCancel}>
            Cancel
          </Button>
          <Button disabled={!methods.formState.isDirty} loading={isSubmitInProgress} type='submit'>
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default ExchangeRateForm;
