import classNames from 'classnames';
import React, { FC } from 'react';
import './Loader.styles.scss';

type LoaderProps = {
  className?: string;
};

const Loader: FC<LoaderProps> = ({ className }: LoaderProps) => {
  const classes = classNames('spinner', className);
  return <div className={classes} />;
};

export default Loader;
