import { SortIcon, UnsortedIcon } from 'assets';
import { Avatar, Button } from 'components';
import { TableColumnItem } from 'components/Table/Table.component';
import { User } from 'models/User';
import { Link } from 'react-router-dom';

const useEmployeesTableColumns = (
  sortByName: number,
  handleSortByName: () => void,
): TableColumnItem<User>[] => [
  {
    columnLabel: (
      <p className={`sort-icon-${sortByName} employees__table-container__name-header`}>
        Name
        <Button
          type='button'
          variant='icon'
          onClick={() => handleSortByName()}
          aria-label='Sort by name'
        >
          {sortByName === 0 && <UnsortedIcon />}
          {sortByName !== 0 && <SortIcon />}
        </Button>
      </p>
    ),
    columnName: 'name',
    prepareCell: (data) => {
      return (
        <div className='employees__table-container__name'>
          <div className='employees__table-container__avatar'>
            <Avatar
              imageUrl={data.profileImageUrl || 'images/userDefault.png'}
              alt={'Profile Image'}
              size='md'
            />
          </div>
          <p>
            {data.firstName} {data.lastName}
          </p>
        </div>
      );
    },
  },
  {
    columnLabel: 'Role',
    columnName: 'role',
    prepareCell: () => 'Ncoded employee',
  },
  {
    columnLabel: 'Email',
    columnName: 'email',
    prepareCell: (data) => {
      return (
        <Link to='#' className='employees__table-container__email'>
          {data.email}
        </Link>
      );
    },
  },
  {
    columnLabel: 'Phone',
    columnName: 'phone',
    prepareCell: (data) =>
      data.phoneNumber ? data.phoneNumber.refferenceNumber + ' ' + data.phoneNumber.number : '',
  },
];

export default useEmployeesTableColumns;
