import React, { forwardRef, useCallback, useState } from 'react';

import { Input } from 'components';

import { EyeVisibillityOffIcon, EyeVisibillityOnIcon } from 'assets';
import { InputProps } from 'components/Input/Input.component';

import './PasswordField.styles.scss';

const PasswordField: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  props,
  _ref,
) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  return (
    <div className='password-field'>
      <Input
        {...props}
        type={showPassword ? 'text' : 'password'}
        suffixNode={
          showPassword ? (
            <EyeVisibillityOnIcon onClick={togglePassword} className={'password-field__icon'} />
          ) : (
            <EyeVisibillityOffIcon onClick={togglePassword} className={'password-field__icon'} />
          )
        }
      />
    </div>
  );
};

export default forwardRef(PasswordField);
