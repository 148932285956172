import { DropzoneOptions, FileRejection } from 'react-dropzone';
import { UploadFile } from './Dropzone.component';

export const validateFiles = (
  files: File[],
  previousFiles: UploadFile[],
  dropzoneOptions?: DropzoneOptions,
): { acceptedFiles: File[]; rejectedFiles: FileRejection[] } => {
  const rejections: FileRejection[] = [];
  let accepted: File[] = files;

  const prevFilesNames = previousFiles.map((file) => file.name);

  files.forEach((file) => {
    if (prevFilesNames.find((fileName) => file.name === fileName)) {
      rejections.push({
        file,
        errors: [{ code: 'duplicate', message: 'Found a duplicate' }],
      });
    }
  }, false);

  accepted = accepted.filter(
    (file) => !rejections.find((rejectedFile) => rejectedFile.file.name === file.name),
  );

  if (!dropzoneOptions) {
    return {
      acceptedFiles: accepted,
      rejectedFiles: rejections,
    };
  }
  const totalLength = accepted.length + previousFiles.length;
  if (dropzoneOptions?.maxFiles && dropzoneOptions.maxFiles < totalLength) {
    accepted.forEach((file) =>
      rejections.push({
        file,
        errors: [
          {
            code: 'Files number exceeded',
            message: 'The number of total files has been exceeded',
          },
        ],
      }),
    );
    accepted = [];
  }

  return {
    acceptedFiles: accepted,
    rejectedFiles: rejections,
  };
};
