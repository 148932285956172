import { Button } from 'components/Button';
import { FieldWrapper } from 'components/FieldWrapper';
import { Modal } from 'components/Modal';
import { ModalProps } from 'components/Modal/Modal.component';
import { UpdateSalaryInformationValue } from 'pages/EmployeeProfilePage/components/EmployeeSalaryVariables/EmployeeSalaryVariables.component';
import { Multiplier } from 'models/Multiplier';
import React, { FC } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import SelectV2 from 'components/SelectV2';
import { SelectV2Props } from 'components/SelectV2/SelectV2.component';
import { Currency } from 'models/Currency';
import FormattedNumberInput, {
  FormattedInputProps,
} from 'components/FormattedNumberInput/FormattedNumberInput.component';

import './SalaryVariablesEditModal.styles.scss';

type SalaryVariablesEditModalProps = {
  methods: UseFormReturn<UpdateSalaryInformationValue, any, undefined>;
  multipliers: Multiplier[];
  multipliersLoading?: boolean;
  onSubmit: (value: UpdateSalaryInformationValue) => Promise<void>;
  defaultCurrency?: Currency;
} & Pick<ModalProps, 'isOpen' | 'onRequestClose'>;

const SalaryVariablesEditModal: FC<SalaryVariablesEditModalProps> = (props) => {
  const { isOpen, onRequestClose, multipliers, methods, onSubmit, defaultCurrency } = props;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className='salary-variables-edit-modal'
      hasCloseIcon
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <h2 className='salary-variables-edit-modal__title'>Change salary information</h2>
          <div className='salary-variables-edit-modal__fields-wrapper'>
            <FieldWrapper<FormattedInputProps>
              name='netSalaryAmount'
              className='net-salary-edit-modal__net-salary'
              fieldComponent={FormattedNumberInput}
              label='Net salary'
              suffixNode={
                <span className='net-salary-edit-modal__salary-sufix'>{defaultCurrency}</span>
              }
            />
            <FieldWrapper<SelectV2Props>
              name='multiplierId'
              label={'Multiplier'}
              fieldComponent={SelectV2}
              options={multipliers.map((multiplier) => ({
                label: multiplier.name,
                value: multiplier.id,
              }))}
              placeholder='Choose multiplier'
            />
          </div>
          {methods.formState.errors.root && (
            <small className='salary-variables-edit-modal__error-msg'>
              {methods.formState.errors.root?.message}
            </small>
          )}

          <div className='salary-variables-edit-modal__actions'>
            <Button variant='outline' onClick={onRequestClose}>
              Cancel
            </Button>
            <Button
              type='submit'
              loading={methods.formState.isSubmitting}
              disabled={!methods.formState.isDirty}
            >
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default SalaryVariablesEditModal;
