import { TParams } from 'models/TParams';

export function parseQueryParams<T extends TParams>(params: T) {
  const finalParams = { ...params } as Record<string, any>;
  if (params.filter) {
    finalParams.filter = JSON.stringify(params.filter);
  }

  if (params.sort) {
    finalParams.sort = JSON.stringify(params.sort);
  }
  return finalParams;
}
